import {
  ActiveRouteDto,
  VoyageDto,
} from "@sofarocean/wayfinder-typescript-client";
import { RouteExportFormat } from "components/modals/RouteExportForm";
import { exportRoutes } from "components/routes/Export/route-export-helpers";
import {
  useCurrentActiveRoute,
  useCurrentRoutesToCompare,
  useCurrentSuggestedRoute,
  useCurrentVessel,
} from "components/WayfinderApp/CurrentSession/contexts";
import AnalyticsContext, { AnalyticsEvent } from "contexts/Analytics";
import useAppSetting from "contexts/AppSettingsContext";
import { ErrorContext } from "contexts/ErrorContext";
import { RouteEditorContext } from "contexts/RouteEditorContext";
import { getRouteEditorConfiguration } from "contexts/RouteEditorContext/helpers";
import { useContextualRoute } from "contexts/RouteStoreContext/ContextualRouteContext";
import { UseManyRoutesResult } from "contexts/RouteStoreContext/use-many-routes";
import useRoute from "contexts/RouteStoreContext/use-route";
import UIContext from "contexts/UIContext";
import { sortByActive } from "helpers/routes";
import { RouteSummaryData } from "helpers/routeSummary";
import { isNil, sortBy } from "lodash";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useRouteSuggestionResponse } from "shared-hooks/data-fetch-hooks/use-route-suggestion-response";
import useVoyage from "shared-hooks/data-fetch-hooks/use-voyage";
import { useActiveRoute } from "shared-hooks/use-active-route";
import {
  ALL_ROUTES_PATH,
  useWayfinderUrl,
} from "shared-hooks/use-wayfinder-url";
import { Route, SimulatedRoute } from "shared-types/RouteTypes";
import { useRouteStyles } from "styles/use-route-styles";
import { useEditedRouteUuids } from "contexts/RouteEditorContext/hooks";
import {
  requestIsOpen,
  useRouteRequestAnalytics,
} from "contexts/RouteExplorerContext/use-route-request-tracking";
import { AuthenticationContext } from "contexts/AuthenticationContext";
import { usePastRouteTypes } from "shared-hooks/use-past-route-types";
import { useCurrentUrl } from "helpers/navigation";
import { useRouteImport } from "../../routes/Import/use-route-import";
import { RouteSummaryTag } from "../RouteSummary";
import { RouteSummaryPopoverContentProps } from "../RouteSummary/RouteSummaryPopoverContent";
import { useRouteSummaryPopoverProps } from "../use-route-summary-popover-props";

const useVoyageMenuState = (): VoyageMenuStateContextType => {
  const {
    voyageUuid,
    setRoutesToCompare,
    setUrl,
    vesselUuid: vesselUuidFromUrl,
  } = useWayfinderUrl<{
    routeUuid: string;
  }>();

  const currentUrl = useCurrentUrl();
  const parameterRouteUuid = currentUrl.getDetailRouteUuid();

  const simulatedHistoricalDateTime = currentUrl.getSimulatedHistoricalDateTime();

  const { editRoute, draftRouteUuid } = useContext(RouteEditorContext);

  const { trackAnalyticsEvent } = useContext(AnalyticsContext);
  const { setRouteActive, pendingActiveRoute } = useActiveRoute(voyageUuid);
  const { voyage } = useVoyage(voyageUuid);
  const { name: voyageName } = voyage ?? {};
  const contextRoute = useContextualRoute(true);

  const { setError } = useContext(ErrorContext);

  const { suggestedRouteUuid } = useCurrentSuggestedRoute();
  const { routesToCompare, routeUuidsToCompare } = useCurrentRoutesToCompare();
  const {
    importedRouteUuids,
    followImportedRoute,
    deleteImportedRoute,
  } = useRouteImport(voyageUuid);
  const editedRouteUuids = useEditedRouteUuids();

  const {
    simulatedRoute: parameterSimulatedRoute,
    routeSummaryData: parameterSummaryData,
  } = useRoute(parameterRouteUuid, true);

  const {
    route: draftRoute,
    simulatedRoute: simulatedDraftRoute,
    routeSummaryData: draftSummaryData,
  } = useRoute(draftRouteUuid, true);

  const {
    routeStoreObject: { routeSummaryData: activeRouteSummaryData },
    activeRouteUuid,
  } = useCurrentActiveRoute();

  const { vessel } = useCurrentVessel();

  const { canCreateOrEditVoyage, canUpdateConstraints } = useMemo(() => {
    if (!vessel?.timeCharterVesselOptions) {
      // if there is no timeCharterVesselOptions, that means this is not a duplicate vessel,
      // all the features are enabled
      return {
        canCreateOrEditVoyage: true,
        canUpdateConstraints: true,
      };
    } else {
      return {
        // If voyage creation is not allowed, don't allow voyage edit
        canCreateOrEditVoyage: vessel.timeCharterVesselOptions.canCreateVoyage,
        canUpdateConstraints:
          vessel.timeCharterVesselOptions.canUpdateConstraints,
      };
    }
  }, [vessel?.timeCharterVesselOptions]);

  const {
    showRouteRejectionFeedbackForm,
    resetRouteRejectionFeedbackForm,
    routeRejectionFeedbackFormResult,
    summaryPopoverRouteUuid,
    closeInfoPopover,
  } = useContext(UIContext);

  const {
    acceptSuggestionAndUpdateActiveRoute,
    createRouteSuggestion,
  } = useRouteSuggestionResponse(voyageUuid);

  type OperationType = "delete" | "follow";
  const [
    routeOperationInProgress,
    setRouteOperationInProgress,
  ] = useState<OperationType>();
  const [manipulatedRouteUuid, setManipulatedRouteUuid] = useState<string>();

  useEffect(() => {
    if (routeRejectionFeedbackFormResult) {
      switch (routeOperationInProgress) {
        case undefined:
          return;
        case "delete":
          if (routeRejectionFeedbackFormResult === "submit") {
            // only delete the route from the comparison if the form was submitted
            setRoutesToCompare(
              routeUuidsToCompare.filter(
                (uuid) => uuid !== manipulatedRouteUuid
              )
            );
          }

          break;
        case "follow":
          switch (routeRejectionFeedbackFormResult) {
            case "submit":
              setRoutesToCompare([manipulatedRouteUuid!]);
              break;
            case "go-back":
              setRoutesToCompare([manipulatedRouteUuid!, suggestedRouteUuid!]);
              break;
          }
          break;
      }
      resetRouteRejectionFeedbackForm();
    }
  }, [
    manipulatedRouteUuid,
    resetRouteRejectionFeedbackForm,
    routeOperationInProgress,
    routeRejectionFeedbackFormResult,
    routeUuidsToCompare,
    setRoutesToCompare,
    suggestedRouteUuid,
  ]);

  /**
   *  When a route is deleted/declined
   * */
  const onDeleteRoute = useCallback(
    async (routeUuid: string) => {
      if (routeUuid === suggestedRouteUuid) {
        // suggested route
        setRouteOperationInProgress("delete");
        setManipulatedRouteUuid(routeUuid);
        showRouteRejectionFeedbackForm();
      } else if (importedRouteUuids.includes(routeUuid)) {
        // imported route
        deleteImportedRoute(routeUuid);
        setRoutesToCompare(
          routeUuidsToCompare.filter((uuid) => uuid !== routeUuid)
        );
        trackAnalyticsEvent(AnalyticsEvent.DeletedImportedRoute, {
          routeUuid: routeUuid,
          voyageUuid: voyageUuid,
        });
      } else {
        setRoutesToCompare(
          routeUuidsToCompare.filter((uuid) => uuid !== routeUuid)
        );
      }
    },
    [
      voyageUuid,
      trackAnalyticsEvent,
      deleteImportedRoute,
      importedRouteUuids,
      routeUuidsToCompare,
      setRoutesToCompare,
      showRouteRejectionFeedbackForm,
      suggestedRouteUuid,
    ]
  );

  const {
    trackRouteRequestEvent,
    currentRouteRequest,
  } = useRouteRequestAnalytics();
  const routeRequestVoyageUuid = currentRouteRequest?.voyageUuid;

  const onEditWaypoints = useCallback(
    async (route: Route) => {
      if (voyage) {
        const configuration = getRouteEditorConfiguration(voyage, route);
        editRoute(route, configuration);
        if (
          // if we are editing a route for the voyage that was linked to
          // in a route request notification, report some analytics about that
          !!routeRequestVoyageUuid &&
          routeRequestVoyageUuid === voyage.uuid
        ) {
          trackRouteRequestEvent(AnalyticsEvent.RouteRequestEditedInVoyage, {
            routeUuid: route.extensions?.uuid,
            name: route.routeInfo.routeName,
            voyageUuid: voyage.uuid,
          });
        }
      } else {
        setError({
          error: new Error(
            "Cannot edit a route that has no associated voyage."
          ),
          description: "Error editing route",
        });
      }
    },
    [
      editRoute,
      routeRequestVoyageUuid,
      setError,
      trackRouteRequestEvent,
      voyage,
    ]
  );

  const onSuggestRoute = useCallback(
    async (routeUuid: string) => {
      if (voyageUuid) await createRouteSuggestion(routeUuid, voyageUuid);
    },
    [createRouteSuggestion, voyageUuid]
  );
  /**
   * When a suggested route is followed
   * */
  const onFollowSuggestedRoute = useCallback(async () => {
    const result = await acceptSuggestionAndUpdateActiveRoute();
    const updatedActiveRoute = result?.updated?.find(
      (dto): dto is ActiveRouteDto =>
        (dto as { __type: string }).__type === "ActiveRoute"
    );

    // If the active route was not updated then don't filter out the activeRouteUuid in the url
    if (isNil(updatedActiveRoute)) return;

    // Keep the previously shown alternate routes, plus the new active route
    const newRouteUuids = routeUuidsToCompare.filter(
      (uuid: string) => uuid !== activeRouteUuid
    );
    setRoutesToCompare(newRouteUuids);
  }, [
    acceptSuggestionAndUpdateActiveRoute,
    activeRouteUuid,
    routeUuidsToCompare,
    setRoutesToCompare,
  ]);

  /**
   * When a imported route is followed
   * */
  const onFollowImportedRoute = useCallback(
    async (routeUuid: string) => {
      await followImportedRoute(routeUuid);

      if (!suggestedRouteUuid) {
        // We didn't have a suggested route. Show only the the selected route and previously shown alternate routes.
        setRoutesToCompare(
          routeUuidsToCompare.filter((id: string) => id !== activeRouteUuid)
        ); // remove the previous active route
      } else {
        setRouteOperationInProgress("follow");
        setManipulatedRouteUuid(routeUuid);
        // we had a suggested route. Ask the user why they didn't want the suggested route.
        showRouteRejectionFeedbackForm();
      }
    },
    [
      activeRouteUuid,
      followImportedRoute,
      routeUuidsToCompare,
      setRoutesToCompare,
      showRouteRejectionFeedbackForm,
      suggestedRouteUuid,
    ]
  );

  /**
   * When an alternate route is followed
   * */
  const onFollowAlternateRoute = useCallback(
    async (routeUuid: string) => {
      await setRouteActive({
        routeUuid: routeUuid,
      });

      if (!suggestedRouteUuid) {
        // We didn't have a suggested route. Show only the the selected route.
        setRoutesToCompare([routeUuid]);
      } else {
        setRouteOperationInProgress("follow");
        setManipulatedRouteUuid(routeUuid);
        // we had a suggested route. Ask the user why they didn't want the suggested route.
        showRouteRejectionFeedbackForm();
      }
    },
    [
      setRouteActive,
      setRoutesToCompare,
      showRouteRejectionFeedbackForm,
      suggestedRouteUuid,
    ]
  );

  const onAllVoyages = useCallback(() => {
    trackAnalyticsEvent(AnalyticsEvent.ViewedAllVoyages);
    setUrl("all-voyages");
  }, [setUrl, trackAnalyticsEvent]);

  const onChangeLeg = useCallback(
    (voyage: VoyageDto) => {
      trackAnalyticsEvent(AnalyticsEvent.SwitchedVoyageLegRoutesPanel, {
        fromVoyageUuid: voyageUuid,
        toVoyageUuid: voyage.uuid,
      });
      const { routeUuid } = voyage.activeRoute ?? {};
      setUrl("voyage", {
        params: {
          voyageUuid: voyage.uuid,
          vesselUuid: vesselUuidFromUrl,
          routesToCompare: !isNil(routeUuid) ? [routeUuid] : [],
        },
      });
    },
    [setUrl, trackAnalyticsEvent, vesselUuidFromUrl, voyageUuid]
  );

  const { user } = useContext(AuthenticationContext);

  /**
   * Triage when a route is followed.
   */
  const onFollowRoute = useCallback(
    async (routeUuid: string) => {
      currentUrl
        .withPath(ALL_ROUTES_PATH)
        .withSimulatedHistoricalTime(null)
        .withVoyagePlanUuidQueryParam(null)
        .withRoutesToCompare([])
        .redirect();
      if (routeUuid === suggestedRouteUuid) {
        // suggested route
        onFollowSuggestedRoute();
      } else if (importedRouteUuids.includes(routeUuid)) {
        onFollowImportedRoute(routeUuid);
      } else if (editedRouteUuids.includes(routeUuid)) {
        trackAnalyticsEvent(AnalyticsEvent.FollowedEditedRoute, {
          routeUuid,
          voyageUuid,
        });
        onFollowImportedRoute(routeUuid);
      } else {
        // alternate route
        onFollowAlternateRoute(routeUuid);
      }

      if (
        // if we are activating a route for the voyage that was linked to
        // in a route request notification, report some analytics about that
        !!routeRequestVoyageUuid &&
        routeRequestVoyageUuid === voyageUuid
      ) {
        trackRouteRequestEvent(AnalyticsEvent.RouteRequestActivatedForVoyage, {
          routeUuid,
          voyageUuid,
        });
        // after the user activates a route, the request can be cleared
      } else if (requestIsOpen(voyage?.routeRequest?.status)) {
        trackAnalyticsEvent(AnalyticsEvent.RouteRequestActivatedForVoyage, {
          // use the route request uuid as distinct id for EFR tracking
          // trackRouteRequestEvent does this for free, but only for the request belonging to the voyage in a deep link
          distinct_id: voyage?.routeRequest?.uuid,
          routeUuid,
          voyageUuid,
          routeRequestUuid: voyage?.routeRequest?.uuid,
          // unfortunately, the org is not human readable
          // but include it anyway, since I think we can use mappings in mixpanel to decode it
          vesselOrganizationId: vessel?.organizationId,
          // by including these properties, we can use mixpanel to break down the conversion times
          // in a human readable way by user, vessel, and voyage
          userEmail: user?.email,
          userName: user?.name,
          vesselName: vessel?.name,
          voyageName: voyage?.name,
        });
      }
    },
    [
      currentUrl,
      suggestedRouteUuid,
      importedRouteUuids,
      editedRouteUuids,
      routeRequestVoyageUuid,
      voyageUuid,
      voyage?.routeRequest?.status,
      voyage?.routeRequest?.uuid,
      voyage?.name,
      onFollowSuggestedRoute,
      onFollowImportedRoute,
      trackAnalyticsEvent,
      onFollowAlternateRoute,
      trackRouteRequestEvent,
      vessel?.organizationId,
      vessel?.name,
      user?.email,
      user?.name,
    ]
  );

  const { oldestPastActiveRoute, newestPastSuggestion } = usePastRouteTypes();

  /**
   * get the tag depending if a route is active/suggested/imported
   */
  const getTag = useCallback(
    (routeUuid: string | undefined): RouteSummaryTag | undefined => {
      if (!routeUuid) {
        return undefined;
      }
      if (routeUuid === activeRouteUuid) return "active";
      if (routeUuid === suggestedRouteUuid) return "suggested";
      if (routeUuid === oldestPastActiveRoute?.uuid) return "past-active";
      if (routeUuid === newestPastSuggestion?.uuid) return "past-suggested";
      if (routeUuid && importedRouteUuids.includes(routeUuid))
        return "imported";
      if (routeUuid && editedRouteUuids.includes(routeUuid)) return "edited";
      return undefined;
    },
    [
      activeRouteUuid,
      oldestPastActiveRoute?.uuid,
      suggestedRouteUuid,
      newestPastSuggestion?.uuid,
      importedRouteUuids,
      editedRouteUuids,
    ]
  );

  const { simulatedRoutes, routeSummaries } = useMemo(() => {
    // todo fix this mess of types
    type RouteResult = {
      simulatedRoute?: SimulatedRoute | undefined;
      metadata?: UseManyRoutesResult["metadata"] | undefined;
    };
    const routesToUse = (routesToCompare && routesToCompare.length
      ? routesToCompare
      : [contextRoute]) as RouteResult[];
    const results = routesToUse.filter(
      (
        r: RouteResult
      ): r is UseManyRoutesResult & {
        simulatedRoute: { extensions: { uuid: string } }; // the filter guarantees a uuid in the extensions
      } => !!r.simulatedRoute?.extensions?.uuid
    );
    const sortedResults = sortBy(results, (r) =>
      sortByActive(activeRouteUuid)(r.simulatedRoute.extensions.uuid)
    );
    return {
      simulatedRoutes: sortedResults.map((r) => r.simulatedRoute),
      routeSummaries: sortedResults.map((r) => r.routeSummaryData),
      isSimulating: sortedResults
        .map((r) => r.metadata?.isSimulating)
        .includes(true),
    };
  }, [activeRouteUuid, contextRoute, routesToCompare]);

  const detailSimulatedRoute =
    parameterRouteUuid && routeUuidsToCompare.includes(parameterRouteUuid)
      ? simulatedRoutes.find((r) => r?.extensions?.uuid === parameterRouteUuid)
      : parameterSimulatedRoute;
  const detailSummaryData =
    parameterRouteUuid && routeUuidsToCompare.includes(parameterRouteUuid)
      ? routeSummaries.find((r) => r?.routeUuid === parameterRouteUuid)
      : parameterSummaryData;

  const routeTitles = useMemo(
    () =>
      Object.fromEntries(
        simulatedRoutes.map((r, index) => [
          r.extensions.uuid,
          r.routeInfo.routeName,
        ])
      ),
    [simulatedRoutes]
  );

  // for now, component-level state is sufficient for managing the export form
  const [exportFormVisible, setExportFormVisible] = useState(false);
  const [noonReportFormVisible, setNoonReportFormVisible] = useState(false);

  const setViewToDefault = useCallback(() => {
    setUrl("voyage");
  }, [setUrl]);

  const onClickImportRoute = useCallback(() => {
    setUrl("route-import");
  }, [setUrl]);
  const onClickCreateVoyage = useCallback(() => {
    setUrl("new-voyage");
  }, [setUrl]);
  const onClickExportRoutes = useCallback(() => {
    setExportFormVisible(true);
  }, []);
  const hideExportForm = useCallback(() => {
    setExportFormVisible(false);
  }, []);

  const onClickNoonReport = useCallback(() => {
    setNoonReportFormVisible(true);
  }, []);
  const hideNoonReportForm = useCallback(() => {
    setNoonReportFormVisible(false);
  }, []);

  const onClickEditVoyage = useCallback(() => {
    setUrl("edit-voyage");
  }, [setUrl]);

  const [exportError, setExportError] = useState<string | undefined>();

  const { value: includeCalculatedScheduleInRtzExports } = useAppSetting(
    "includeCalculatedScheduleInRtzExports"
  );

  const { value: enableLegacyRtzXmlFormatting } = useAppSetting(
    "enableLegacyRtzXmlFormatting"
  );

  const downloadRoutesWithFormats = useCallback(
    (
      routeUuids: string[],
      formats: RouteExportFormat[],
      shouldDownload = true
    ) => {
      const routes = routeUuids
        .map((uuid) =>
          simulatedRoutes.find((sr) => sr.extensions.uuid === uuid)
        )
        .filter((r: Route | undefined) => Boolean(r))
        .map((r): Route => r!);

      const { files, errors } = exportRoutes({
        routes,
        formats,
        getRoutename: (route: Route) =>
          `${voyageName}_${route.routeInfo.routeName}`,
        options: {
          shouldDownload,
          includeCalculatedScheduleInRtzExports,
          enableLegacyRtzXmlFormatting,
        },
      });

      if (errors) {
        setExportError(errors);
        return [];
      }

      hideExportForm();
      routeUuids.forEach((routeUuid) => {
        trackAnalyticsEvent(AnalyticsEvent.ExportRouteSuccess, {
          routeUuid,
          voyageUuid: voyageUuid,
        });
      });
      return files;
    },
    [
      hideExportForm,
      simulatedRoutes,
      voyageName,
      includeCalculatedScheduleInRtzExports,
      enableLegacyRtzXmlFormatting,
      trackAnalyticsEvent,
      voyageUuid,
    ]
  );

  const { value: enableManageArbitraryRoutes } = useAppSetting(
    "enableManageArbitraryRoutes"
  );

  const shouldShowManagementButtons = useCallback(
    (routeUuid: string) => {
      if (enableManageArbitraryRoutes) {
        return routeUuid !== activeRouteUuid;
      }
      if (routeUuid === activeRouteUuid) return false;
      return (
        routeUuid === suggestedRouteUuid ||
        (importedRouteUuids.includes(routeUuid) &&
          routeUuid !== activeRouteUuid) ||
        (editedRouteUuids.includes(routeUuid) && routeUuid !== activeRouteUuid)
      );
    },
    [
      enableManageArbitraryRoutes,
      activeRouteUuid,
      suggestedRouteUuid,
      importedRouteUuids,
      editedRouteUuids,
    ]
  );

  const { value: useFirstRouteAsComparisonBasis } = useAppSetting(
    "useFirstRouteAsComparisonBasis"
  );

  useEffect(() => {
    if (
      useFirstRouteAsComparisonBasis &&
      !(activeRouteUuid && routeUuidsToCompare.includes(activeRouteUuid))
    ) {
      const time =
        routesToCompare?.[0]?.simulatedRoute?.schedules?.schedules?.[0]
          .calculated?.scheduleElements?.[0].etd;
      if (
        time &&
        new Date(time).getTime() !== simulatedHistoricalDateTime?.valueOf()
      ) {
        currentUrl.withSimulatedHistoricalTime(time).redirect();
      }
    }
  }, [
    activeRouteUuid,
    currentUrl,
    routeUuidsToCompare,
    routesToCompare,
    simulatedHistoricalDateTime,
    useFirstRouteAsComparisonBasis,
  ]);

  const comparisonBasisSummaryData = useMemo(() => {
    const hasCurrentActiveRoute =
      activeRouteUuid &&
      routesToCompare.map((r) => r.uuid).includes(activeRouteUuid);

    const hasActiveRoute = hasCurrentActiveRoute || !!oldestPastActiveRoute;

    const firstRouteIsComparisonBasis =
      useFirstRouteAsComparisonBasis && // feature flag for using first route when active route is not in comparison
      routesToCompare?.length &&
      !hasActiveRoute;

    const firstRouteSummaryData = routesToCompare.at(0)?.routeSummaryData;

    if (firstRouteIsComparisonBasis) {
      return firstRouteSummaryData;
    }
    return (
      // if there is a former active route in the comparison, use that
      // otherwise fall back to the current active route
      oldestPastActiveRoute?.routeSummaryData ?? activeRouteSummaryData
    );
  }, [
    activeRouteSummaryData,
    activeRouteUuid,
    oldestPastActiveRoute,
    routesToCompare,
    useFirstRouteAsComparisonBasis,
  ]);

  const comparisonBasisRouteUuid = comparisonBasisSummaryData?.routeUuid;

  const routeStyles = useRouteStyles();
  const alternateRouteSummaryData = routesToCompare?.find(
    (r) => r.uuid === summaryPopoverRouteUuid
  )?.routeSummaryData;

  const alternateStyle = summaryPopoverRouteUuid
    ? routeStyles[summaryPopoverRouteUuid]
    : undefined;

  const comparisonBasisStyle = comparisonBasisRouteUuid
    ? routeStyles[comparisonBasisRouteUuid]
    : undefined;

  const routeSummaryPopoverProps:
    | RouteSummaryPopoverContentProps
    | undefined = useRouteSummaryPopoverProps(
    alternateRouteSummaryData,
    comparisonBasisSummaryData,
    alternateStyle,
    comparisonBasisStyle
  );

  // We need a routesToCompare list to make the colors and hierarchical nav in sidebar work
  useEffect(() => {
    if (parameterRouteUuid && !routesToCompare.length) {
      setRoutesToCompare([parameterRouteUuid]);
    }
  }, [parameterRouteUuid, routesToCompare.length, setRoutesToCompare]);

  return useMemo(
    () => ({
      onFollowRoute,
      onDeleteRoute,
      onSuggestRoute,
      getTag,
      simulatedRoutes,
      detailSimulatedRoute,
      routeTitles,
      pendingActiveRoute,
      activeRouteUuid,
      suggestedRouteUuid,
      exportFormVisible,
      exportError,
      onClickCreateVoyage,
      onClickImportRoute,
      onClickExportRoutes,
      hideExportForm,
      downloadRoutesWithFormats,
      voyage,
      voyageUuid,
      shouldShowManagementButtons,
      comparisonBasisSummaryData,
      comparisonBasisRouteUuid,
      closeInfoPopover,
      routeSummaryPopoverProps,
      routeUuidsToCompare,
      onAllVoyages,
      onClickEditVoyage,
      onChangeLeg,
      vesselUuid: vesselUuidFromUrl,
      setViewToDefault,
      onEditWaypoints,
      draftRoute,
      simulatedDraftRoute,
      draftSummaryData,
      detailSummaryData,
      noonReportFormVisible,
      onClickNoonReport,
      hideNoonReportForm,
      canUpdateConstraints,
      canCreateOrEditVoyage,
    }),
    [
      onFollowRoute,
      onDeleteRoute,
      onSuggestRoute,
      getTag,
      simulatedRoutes,
      detailSimulatedRoute,
      routeTitles,
      pendingActiveRoute,
      activeRouteUuid,
      suggestedRouteUuid,
      exportFormVisible,
      exportError,
      onClickCreateVoyage,
      onClickImportRoute,
      onClickExportRoutes,
      hideExportForm,
      downloadRoutesWithFormats,
      voyage,
      voyageUuid,
      shouldShowManagementButtons,
      comparisonBasisSummaryData,
      comparisonBasisRouteUuid,
      closeInfoPopover,
      routeSummaryPopoverProps,
      routeUuidsToCompare,
      onAllVoyages,
      onClickEditVoyage,
      onChangeLeg,
      vesselUuidFromUrl,
      setViewToDefault,
      onEditWaypoints,
      draftRoute,
      simulatedDraftRoute,
      draftSummaryData,
      detailSummaryData,
      noonReportFormVisible,
      onClickNoonReport,
      hideNoonReportForm,
      canUpdateConstraints,
      canCreateOrEditVoyage,
    ]
  );
};

type VoyageMenuStateContextType = {
  onFollowRoute: (routeUuid: string) => Promise<void>;
  onDeleteRoute: (routeUuid: string) => Promise<void>;
  onSuggestRoute: (routeUuid: string) => Promise<void>;
  getTag: (routeUuid: string | undefined) => RouteSummaryTag | undefined;
  simulatedRoutes: (Route & {
    extensions: {
      isSimulated: true;
      uuid: string;
    };
  } & {
    extensions: {
      uuid: string;
    };
  })[];
  detailSimulatedRoute: SimulatedRoute | undefined;
  routeTitles: {
    [k: string]: string;
  };
  pendingActiveRoute: string | undefined;
  activeRouteUuid: string | undefined;
  suggestedRouteUuid: string | undefined;
  exportFormVisible: boolean;
  exportError: string | undefined;
  onClickCreateVoyage: () => void;
  onClickImportRoute: () => void;
  onClickExportRoutes: () => void;
  hideExportForm: () => void;
  downloadRoutesWithFormats: (
    routeUuids: string[],
    formats: RouteExportFormat[],
    shouldDownload?: any
  ) => {
    format: RouteExportFormat;
    content: string;
    name: string;
  }[];
  voyage: VoyageDto | undefined;
  voyageUuid: string | undefined;
  shouldShowManagementButtons: (routeUuid: string) => boolean;
  comparisonBasisSummaryData: RouteSummaryData | undefined;
  comparisonBasisRouteUuid: string | undefined;
  closeInfoPopover: () => void;
  routeSummaryPopoverProps: RouteSummaryPopoverContentProps | undefined;
  routeUuidsToCompare: string[];
  onAllVoyages: () => void;
  onClickEditVoyage: () => void;
  onChangeLeg: (voyage: VoyageDto) => void;
  vesselUuid: string | null | undefined;
  setViewToDefault: () => void;
  onEditWaypoints: (route: Route) => Promise<void>;
  draftRoute: Route | undefined;
  simulatedDraftRoute: SimulatedRoute | undefined;
  draftSummaryData: RouteSummaryData | undefined;
  detailSummaryData: RouteSummaryData | undefined;
  noonReportFormVisible: boolean;
  onClickNoonReport: () => void;
  hideNoonReportForm: () => void;
  canCreateOrEditVoyage: boolean;
  canUpdateConstraints: boolean;
};

export const VoyageMenuStateContextDefaults = {
  onFollowRoute: (routeUuid: string) => Promise.resolve(),
  onDeleteRoute: (routeUuid: string) => Promise.resolve(),
  onSuggestRoute: (routeUuid: string) => Promise.resolve(),
  getTag: (routeUuid: string | undefined) => undefined,
  simulatedRoutes: [],
  detailSimulatedRoute: undefined,
  routeTitles: {},
  pendingActiveRoute: undefined,
  activeRouteUuid: undefined,
  suggestedRouteUuid: undefined,
  exportFormVisible: false,
  exportError: undefined,
  onClickCreateVoyage: () => undefined,
  onClickImportRoute: () => undefined,
  onClickExportRoutes: () => undefined,
  hideExportForm: () => undefined,
  downloadRoutesWithFormats: (
    routeUuids: string[],
    formats: RouteExportFormat[],
    shouldDownload?: any
  ) => [],
  voyage: undefined,
  voyageUuid: "",
  shouldShowManagementButtons: (routeUuid: string) => false,
  comparisonBasisSummaryData: undefined,
  comparisonBasisRouteUuid: undefined,
  closeInfoPopover: () => undefined,
  routeSummaryPopoverProps: undefined,
  routeUuidsToCompare: [],
  onAllVoyages: () => undefined,
  onClickEditVoyage: () => undefined,
  onChangeLeg: (voyage: VoyageDto) => undefined,
  vesselUuid: undefined,
  setViewToDefault: () => undefined,
  onEditWaypoints: (route: Route) => Promise.resolve(),
  draftRoute: undefined,
  simulatedDraftRoute: undefined,
  draftSummaryData: undefined,
  detailSummaryData: undefined,
  noonReportFormVisible: false,
  onClickNoonReport: () => undefined,
  hideNoonReportForm: () => undefined,
  canCreateOrEditVoyage: false,
  canUpdateConstraints: false,
};

export const VoyageMenuStateContext = createContext<VoyageMenuStateContextType>(
  VoyageMenuStateContextDefaults
);

export const VoyageMenuStateContextProvider: React.FC<{}> = ({ children }) => {
  const state = useVoyageMenuState();
  return (
    <VoyageMenuStateContext.Provider value={state}>
      {children}
    </VoyageMenuStateContext.Provider>
  );
};
