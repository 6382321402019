import {
  CreateMultiLegVoyageDto,
  MultiLegVoyageDto,
  RouteSuggestionDto,
  VesselDto,
  VesselFieldUpdatesDto,
  VoyageDto,
} from "@sofarocean/wayfinder-typescript-client";
import useManyRoutes from "contexts/RouteStoreContext/use-many-routes";
import useRoute from "contexts/RouteStoreContext/use-route";
import { Draft } from "immer";
import { Dispatch, useEffect } from "react";
import { useMultiLegVoyage } from "shared-hooks/data-fetch-hooks/use-multi-leg-voyage";
import { useRouteSuggestion } from "shared-hooks/data-fetch-hooks/use-route-suggestion";
import { useVessel } from "shared-hooks/data-fetch-hooks/use-vessel";
import useVoyage from "shared-hooks/data-fetch-hooks/use-voyage";
import {
  useWayfinderUrl,
  useWayfinderUrlUuids,
} from "shared-hooks/use-wayfinder-url";
import { WayfinderReducer } from "shared-types/reducers";
import { Route } from "shared-types/RouteTypes";
import { useImmerReducer } from "use-immer";
import {
  CurrentSessionActionForTypeString,
  CurrentSessionActionTypeString,
  CurrentSessionReducerAction,
  currentSessionReducers,
} from "./reducers";

// This is how the consumers will know about the state of the data
type AsyncApiContextData<T> = {
  data: T;
  isLoading: boolean;
  isError?: boolean;
  error?: Error;
};

export type CurrentSessionData = {
  voyageLegUuid: string | undefined;
  vesselUuid: string | undefined;
  multilegVoyageUuid: string | undefined;
  activeRouteUuid: string | undefined;
  suggestedRouteUuid: string | undefined;
  routeUuidsToCompare: string[];
  currentSessionIsLoading: boolean;
  drafts: {
    // we could start keeping drafts here rather than in their own context...
    multilegVoyage?: CreateMultiLegVoyageDto;
    route?: Route;
  };
  dataRecords: {
    voyageLeg: AsyncApiContextData<VoyageDto | undefined>;
    vessel: AsyncApiContextData<VesselDto | undefined>;
    vesselUpdates: AsyncApiContextData<VesselFieldUpdatesDto | undefined>;
    multilegVoyage: AsyncApiContextData<MultiLegVoyageDto | undefined>;
    activeRoute: ReturnType<typeof useRoute>;
    routeSuggestion: AsyncApiContextData<RouteSuggestionDto | null | undefined>;
    suggestedRoute: ReturnType<typeof useRoute>;
    routesToCompare: ReturnType<typeof useManyRoutes>;
  };
};

const routeDataDefaults = {
  updateWaypointPosition: () => {},
  updateWaypointSpeed: () => {},
  updateWaypointTimestamp: () => {},
  updateWaypointDrifting: () => {},
  addWaypoint: () => {},
  removeWaypoint: () => {},
  updateWaypointGeometryType: () => {},
  ContextualRouteProvider: () => null,
  route: undefined,
  simulatedRoute: undefined,
  routeSummaryData: undefined,
  metadata: undefined,
  lookup: undefined,
  guidanceParameters: undefined,
};
export const currentSessionDataDefaults: CurrentSessionData = {
  vesselUuid: undefined,
  multilegVoyageUuid: undefined,
  voyageLegUuid: undefined,
  activeRouteUuid: undefined,
  suggestedRouteUuid: undefined,
  routeUuidsToCompare: [],
  drafts: {},
  currentSessionIsLoading: true,
  dataRecords: {
    voyageLeg: { data: undefined, isLoading: true },
    vessel: { data: undefined, isLoading: true },
    vesselUpdates: { data: undefined, isLoading: true },
    multilegVoyage: { data: undefined, isLoading: true },
    activeRoute: { ...routeDataDefaults },
    routeSuggestion: { data: undefined, isLoading: true },
    suggestedRoute: { ...routeDataDefaults },
    routesToCompare: [],
  },
};

const currentSessionReducer = <T extends CurrentSessionActionTypeString>(
  draftState: Draft<CurrentSessionData>,
  action: CurrentSessionActionForTypeString<T>
) => {
  const reducer: WayfinderReducer<
    CurrentSessionData,
    CurrentSessionActionForTypeString<T>
  > = currentSessionReducers[action.type];
  reducer(draftState, action);
};

/**
 * **This hoook should only be called in the CurrentSessionProvider**
 *
 * Computes the current session state based on the current URL and the data fetched from the API.
 * Shared through the CurrentSessionContext and in smaller slices with the CurrentSessionProvider.
 * Do not call this hook directly, call useCurrentSession() instead.
 */
export const useCurrentSessionContextValue = (): {
  currentSession: CurrentSessionData;
  currentSessionDispatch: Dispatch<CurrentSessionReducerAction>;
} => {
  const [currentSession, currentSessionDispatch] = useImmerReducer<
    CurrentSessionData,
    CurrentSessionReducerAction
  >(currentSessionReducer, currentSessionDataDefaults);

  const {
    voyageLegUuid,
    routeUuidsToCompare,
    multilegVoyageUuid,
    vesselUuid,
  } = currentSession;

  // drive state updates from url changes
  const {
    voyageUuid: voyageLegUuidFromUrl,
    vesselUuid: vesselUuidFromUrl,
  } = useWayfinderUrlUuids();

  useEffect(() => {
    currentSessionDispatch({
      type: "updateVoyageLegUuidWithNewUrlStructure",
      payload: { voyageLegUuid: voyageLegUuidFromUrl },
    });
  }, [currentSessionDispatch, voyageLegUuidFromUrl]);
  useEffect(() => {
    currentSessionDispatch({
      type: "updateVesselUuid",
      payload: { vesselUuid: vesselUuidFromUrl },
    });
  }, [currentSessionDispatch, vesselUuidFromUrl]);

  const { routeUuidsToCompare: routeUuidsToCompareFromUrl } = useWayfinderUrl();
  useEffect(() => {
    currentSessionDispatch({
      type: "updateRouteUuidsToCompare",
      payload: { routeUuidsToCompare: routeUuidsToCompareFromUrl },
    });
  }, [currentSessionDispatch, routeUuidsToCompareFromUrl]);

  // vessel
  const vesselQuery = useVessel(vesselUuidFromUrl ?? vesselUuid);

  // voyage
  const voyageLegQuery = useVoyage(voyageLegUuid);
  useEffect(() => {
    currentSessionDispatch({
      type: "updateActiveRouteUuid",
      payload: {
        activeRouteUuid:
          voyageLegQuery.voyage?.activeRoute?.routeUuid ?? undefined,
      },
    });
    currentSessionDispatch({
      type: "updateMultilegVoyageUuid",
      payload: {
        multilegVoyageUuid:
          voyageLegQuery.voyage?.multiLegVoyageUuid ?? undefined,
      },
    });
    currentSessionDispatch({
      type: "updateVesselUuid",
      payload: {
        vesselUuid: vesselUuidFromUrl ?? undefined,
      },
    });
    // Run this effect only for voyage stage change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voyageLegQuery.voyage, currentSessionDispatch]);

  // active route
  const activeRoute = useRoute(
    voyageLegQuery.voyage?.activeRoute?.routeUuid ?? undefined,
    true
  );
  // suggestion
  const suggestedRouteQuery = useRouteSuggestion(voyageLegUuid);
  const {
    suggestedRouteUuid: suggestedRouteUuidFromApi,
    suggestedRoute: routeSuggestion,
  } = suggestedRouteQuery;
  useEffect(
    () =>
      currentSessionDispatch({
        type: "updateSuggestedRouteUuid",
        payload: {
          suggestedRouteUuid: suggestedRouteUuidFromApi,
        },
      }),
    [suggestedRouteUuidFromApi, currentSessionDispatch]
  );
  const suggestedRoute = useRoute(suggestedRouteUuidFromApi, true);
  // multileg voyage
  const multilegVoyageQuery = useMultiLegVoyage(multilegVoyageUuid);
  // routes to compare
  const routesToCompare = useManyRoutes(routeUuidsToCompare, true);

  // loading if any resource is still loading
  const isLoading = Boolean(
    voyageLegQuery.voyageIsLoading ||
      vesselQuery.isLoading ||
      suggestedRouteQuery.suggestedRouteIsLoading ||
      multilegVoyageQuery.isLoading
  );
  useEffect(() => {
    currentSessionDispatch({
      type: "updateLoadingState",
      payload: {
        isLoading,
      },
    });
  }, [currentSessionDispatch, isLoading]);

  //activeRoute
  useEffect(
    function updateActiveRoute() {
      if (!isLoading) {
        currentSessionDispatch({
          type: "updateDataRecords",
          payload: {
            activeRoute,
          },
        });
      }
    },
    [activeRoute, currentSessionDispatch, isLoading]
  );

  //multilegVoyageQuery
  useEffect(
    function updateMultilegVoyage() {
      if (!isLoading) {
        currentSessionDispatch({
          type: "updateDataRecords",
          payload: {
            multilegVoyage: { data: multilegVoyageQuery.data, isLoading },
          },
        });
      }
    },
    [currentSessionDispatch, isLoading, multilegVoyageQuery.data]
  );

  //suggestedRoute
  useEffect(
    function updateSuggestedRoute() {
      if (!isLoading) {
        currentSessionDispatch({
          type: "updateDataRecords",
          payload: {
            suggestedRoute: suggestedRoute,
            routeSuggestion: { data: routeSuggestion, isLoading },
          },
        });
      }
    },
    [currentSessionDispatch, isLoading, routeSuggestion, suggestedRoute]
  );

  //routesToCompare
  useEffect(
    function updateRoutesToCompare() {
      currentSessionDispatch({
        type: "updateDataRecords",
        payload: {
          routesToCompare,
        },
      });
    },
    [currentSessionDispatch, isLoading, routesToCompare]
  );

  //vesselQuery
  useEffect(
    function updateVessel() {
      if (!isLoading) {
        currentSessionDispatch({
          type: "updateDataRecords",
          payload: {
            vessel: { data: vesselQuery.vessel, isLoading },
            vesselUpdates: {
              data: vesselQuery.vesselUpdates,
              isLoading: vesselQuery.vesselUpdatesIsLoading,
            },
          },
        });
      }
    },
    [
      currentSessionDispatch,
      isLoading,
      vesselQuery.vessel,
      vesselQuery.vesselUpdates,
      vesselQuery.vesselUpdatesIsLoading,
    ]
  );

  //voyageLegQuery
  useEffect(
    function updateVoyageLeg() {
      if (!isLoading) {
        currentSessionDispatch({
          type: "updateDataRecords",
          payload: {
            voyageLeg: { data: voyageLegQuery.voyage, isLoading },
          },
        });
      }
    },
    [currentSessionDispatch, isLoading, voyageLegQuery.voyage]
  );

  return { currentSession, currentSessionDispatch };
};
