import {
  Box,
  Popover,
  List,
  ListItemButton,
  ListItemText,
  Divider,
  SxProps,
} from "@mui/material";
import { VoyageDto } from "@sofarocean/wayfinder-typescript-client";
import { AnalyticsEvent } from "contexts/Analytics";
import { getRouteEditorConfiguration } from "contexts/RouteEditorContext/helpers";
import { RouteEditorConfiguration } from "contexts/RouteStoreContext/state-types";
import { WayfinderButton } from "DLS";
import { WayfinderUrl } from "helpers/navigation";
import React, { useState } from "react";
import { EDIT_ROUTE_PATH } from "shared-hooks/use-wayfinder-url";
import { corePalette } from "styles/theme";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import { ReactComponent as MoreActionsIcon } from "bundle-data/images/icon-more-actions.svg";
import { Route } from "shared-types/RouteTypes";
import { circularButtonStyles } from "./RouteNameTableCell";

const iconStyle: SxProps = {
  width: "16px",
  height: "16px",
  mr: 2,
  verticalAlign: "middle",
};

export const MoreActionsMenu: React.FC<{
  isPlanInEditMode: boolean;
  isActiveRoute: boolean;
  isSuggestedRoute: boolean;
  allowRouteSuggestion: boolean;
  trackAnalyticsEvent: (
    event: AnalyticsEvent,
    data?: Record<string, any>
  ) => void;
  editRoute: (route: Route, configuration: RouteEditorConfiguration) => void;
  route: Route | undefined;
  voyage: VoyageDto | undefined;
  currentUrl: WayfinderUrl;
  routeUuid: string;
  onRemoveRoute: (routeUuid: string) => void;
  planUuid: string | null;
  onFollowRoute: (routeUuid: string) => void;
  onSuggestRoute: (routeUuid: string) => void;
  onClickExportRoutes: () => void;
}> = ({
  isPlanInEditMode,
  isActiveRoute,
  isSuggestedRoute,
  allowRouteSuggestion,
  trackAnalyticsEvent,
  editRoute,
  route,
  voyage,
  currentUrl,
  routeUuid,
  onRemoveRoute,
  planUuid,
  onFollowRoute,
  onSuggestRoute,
  onClickExportRoutes,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const onClickEditRoute = () => {
    if (route && voyage) {
      const configuration = getRouteEditorConfiguration(voyage, route);
      editRoute(route, configuration);
      trackAnalyticsEvent(AnalyticsEvent.VoyagePlanEditRoute, { routeUuid });
      currentUrl.withPath(EDIT_ROUTE_PATH).withDetailRouteUuid(routeUuid).go();
    }
  };

  const onClickRemoveRoute = () => onRemoveRoute(routeUuid);

  const onClickDownloadRoute = () => {
    onClickExportRoutes();
    trackAnalyticsEvent(AnalyticsEvent.VoyagePlanEditRoute, {
      routeUuid,
      planUuid,
    });
    setAnchorEl(null);
  };

  const onClickSuggestRoute = () => {
    onSuggestRoute(routeUuid);
    trackAnalyticsEvent(AnalyticsEvent.VoyagePlanSuggestedRoute, {
      routeUuid,
      planUuid,
    });
    setAnchorEl(null);
  };

  const onClickFollowRoute = () => {
    onFollowRoute(routeUuid);
    trackAnalyticsEvent(AnalyticsEvent.VoyagePlanFollowedRoute, {
      routeUuid,
      planUuid,
    });
  };

  const menuOpen = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickMoreActions = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <Box sx={circularButtonStyles}>
      <WayfinderButton
        variant="tertiary"
        onClick={onClickMoreActions}
        sx={{
          padding: 0,
        }}
      >
        <MoreActionsIcon />
      </WayfinderButton>

      <Popover
        open={menuOpen}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 5, horizontal: 40 }}
      >
        <Box sx={{ px: 4 }}>
          <List sx={{ color: corePalette.blue500, width: "210px" }}>
            {isPlanInEditMode && (
              <ListItemButton data-cy="edit-route" onClick={onClickEditRoute}>
                <ListItemText>
                  <EditIcon sx={iconStyle} />
                  Edit
                </ListItemText>
              </ListItemButton>
            )}
            <ListItemButton
              data-cy="download-route"
              onClick={onClickDownloadRoute}
            >
              <ListItemText>
                <DownloadIcon sx={iconStyle} />
                Download
              </ListItemText>
            </ListItemButton>
            {isPlanInEditMode && (
              <ListItemButton
                data-cy="remove-route"
                onClick={onClickRemoveRoute}
              >
                <ListItemText>
                  <DeleteOutlineIcon sx={iconStyle} />
                  Remove
                </ListItemText>
              </ListItemButton>
            )}
          </List>
          {!isActiveRoute ? <Divider /> : null}
          {!isActiveRoute && (
            <WayfinderButton
              sx={{ width: "80px", m: 2 }}
              onClick={onClickFollowRoute}
            >
              Follow
            </WayfinderButton>
          )}
          {!isSuggestedRoute && !isActiveRoute && allowRouteSuggestion && (
            <WayfinderButton
              sx={{ width: "80px", m: 2 }}
              onClick={onClickSuggestRoute}
            >
              Suggest
            </WayfinderButton>
          )}
        </Box>
      </Popover>
    </Box>
  );
};
