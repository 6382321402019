import { Button, ButtonProps, Theme } from "@mui/material";
import React, { useMemo } from "react";
import { BUTTON_STYLES } from "styles/component-variables";
import { match } from "ts-pattern";
import { typographyStyles, extendedPalette } from "../../styles/theme";

export type WayfinderButtonProps = Omit<ButtonProps, "variant"> & {
  variant?: "primary" | "secondary" | "tertiary";
  forwardRef?: ButtonProps["ref"];
};

const variantMap = new Map<
  "primary" | "secondary" | "tertiary",
  "text" | "outlined" | "contained"
>([
  ["primary", "contained"],
  ["secondary", "outlined"],
  ["tertiary", "text"],
]);

export const WayfinderButton: React.FC<WayfinderButtonProps> = ({
  variant = "primary",
  children,
  onClick,
  forwardRef,
  ...rest
}) => {
  const buttonClasses = useMemo(() => {
    return rest.classes;
  }, [rest.classes]);

  const variantStyles = useMemo(
    () =>
      match(variant)
        .with("primary", () => ({
          ...typographyStyles.header,
          lineHeight: 1,
          color: "#ffff",
          backgroundColor: extendedPalette.primary,
          boxShadow: "inherit",
          "&.Mui-disabled": {
            color: "#fff",
            backgroundColor: extendedPalette.neutralMedium,
          },

          "&:hover": {
            boxShadow: "inherit",
            backgroundColor: extendedPalette.primary,
            filter: "drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.25))",
          },
          "&:focus-visible, &:active": {
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          },
          "& .MuiTouchRipple-root span": {
            backgroundColor: "#0050A0",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            opacity: 0.3,
          },
        }))
        .with("secondary", () => ({
          ...typographyStyles.header,
          lineHeight: 1,
          color: "#000",
          boxShadow: "inherit",
          border: "2px solid #111111",
        }))
        .with("tertiary", () => ({
          ...typographyStyles.title,
          color: extendedPalette.primary,
          borderRadius: 0,

          "&:hover": {
            backgroundColor: extendedPalette.seaLight,
          },
          "&.Mui-disabled": {
            color: extendedPalette.neutralDark,
          },
        }))
        .exhaustive(),
    [variant]
  );

  return (
    <Button
      {...rest}
      classes={buttonClasses}
      variant={variantMap.get(variant)}
      onClick={onClick}
      sx={{
        height: "32px",
        borderRadius: "21px",
        textTransform: "initial",
        padding: `${BUTTON_STYLES.BUTTON_PADDING_Y}px ${BUTTON_STYLES.BUTTON_LABEL_PADDING_X}px`,
        ...variantStyles,
        ...rest.sx,
      }}
      ref={forwardRef}
    >
      {children}
    </Button>
  );
};
