import { SxProps, TableCell, Grid, Box, Chip, Tooltip } from "@mui/material";
import { useCurrentVoyageLeg } from "components/WayfinderApp/CurrentSession/contexts";
import AnalyticsContext from "contexts/Analytics";
import { AuthenticationContext } from "contexts/AuthenticationContext";
import { RouteEditorContext } from "contexts/RouteEditorContext";
import useRoute from "contexts/RouteStoreContext/use-route";
import { WayfinderButton } from "DLS";
import IndicatorDot from "DLS/IndicatorDot";
import { WayfinderTextField } from "DLS/wayfinder-text-field/wayfinder-text-field";
import { useCurrentUrl } from "helpers/navigation";
import React, { useContext, useState } from "react";
import { useRouteNameMutation } from "shared-hooks/data-fetch-hooks/use-route-name-mutation";
import { extendedPalette } from "styles/theme";
import BalanceIcon from "@mui/icons-material/Balance";
import { VoyageMenuStateContext } from "../Voyage/use-voyage-menu-state";
import { commonTableCellStyle } from "./RouteExplorerTable";
import { MoreActionsMenu } from "./MoreActionsMenu";

export const circularButtonStyles: SxProps = {
  flexShrink: "0",
  width: 32,
  height: 32,
  borderRadius: "50%",
  overflow: "hidden",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginLeft: 1,
};

export const RouteNameTableCell: React.FC<{
  routeColor: string;
  routeName: string;
  routeUuid: string;
  onRemoveRoute: (routeUuid: string) => void;
  onToggleComparisonBasis: (routeUuid: string) => void;
  showComparisonButton: boolean;
}> = ({
  routeColor,
  routeName,
  routeUuid,
  onRemoveRoute,
  onToggleComparisonBasis,
  showComparisonButton,
}) => {
  const {
    onClickExportRoutes,
    activeRouteUuid,
    suggestedRouteUuid,
    onSuggestRoute,
    onFollowRoute,
  } = useContext(VoyageMenuStateContext);
  const { trackAnalyticsEvent } = useContext(AnalyticsContext);
  const isActiveRoute = routeUuid === activeRouteUuid;
  const isSuggestedRoute = routeUuid === suggestedRouteUuid;
  const { editRoute } = useContext(RouteEditorContext);
  const { route } = useRoute(routeUuid);
  const { voyage } = useCurrentVoyageLeg();
  const currentUrl = useCurrentUrl();
  const { featureIsAllowed } = useContext(AuthenticationContext);
  const allowRouteSuggestion = featureIsAllowed("allowRouteSuggestion");

  // the plan is in edit mode if there is no plan param in the url
  // after it is published, that param will be there, and the plan should not be changed
  const planUuid = currentUrl.getVoyagePlanUuidQueryParam();
  const isPlanInEditMode = !planUuid;

  const [editorRouteName, setEditorRouteName] = useState(routeName);

  const { updateRouteName, updateRouteIsLoading } = useRouteNameMutation(
    routeUuid
  );

  const onClickComparisonBasis = () => onToggleComparisonBasis(routeUuid);

  return (
    <TableCell sx={{ ...commonTableCellStyle, minWidth: "360px" }}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        display="flex"
        flexDirection="row"
      >
        <Grid
          item
          minWidth="0"
          flex="1"
          whiteSpace="nowrap"
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {!isActiveRoute && !isSuggestedRoute ? (
            <Box display="flex" flexDirection="row" alignItems="center">
              <IndicatorDot color={routeColor} />
            </Box>
          ) : null}
          {isActiveRoute ? (
            <Chip
              label="Active"
              sx={{
                backgroundColor: routeColor ?? "#000",
                color: "#fff",
                marginRight: "0.5rem",
              }}
            />
          ) : null}
          {isSuggestedRoute && !isActiveRoute ? (
            <Chip
              label="Suggested"
              sx={{
                backgroundColor: routeColor ?? "#000",
                color: "#fff",
                marginRight: "0.5rem",
              }}
            />
          ) : null}

          {isPlanInEditMode ? (
            <WayfinderTextField
              variant="primary"
              value={editorRouteName}
              onChange={(event) => {
                setEditorRouteName(event.target.value);
              }}
              onKeyDown={({ key }) => {
                if (key === "Enter") {
                  updateRouteName(editorRouteName);
                }
              }}
              onBlur={() => {
                setEditorRouteName(editorRouteName);
              }}
              disabled={updateRouteIsLoading}
              containerStyles={{ minWidth: 0, flex: 1 }}
              sx={{ width: "100%" }}
              textAlign="left"
            />
          ) : (
            <Box
              component="span"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              title={routeName}
            >
              {routeName}
            </Box>
          )}
          <Box>
            <Box
              className="comparison-button"
              sx={{
                ...circularButtonStyles,
                visibility: showComparisonButton ? "visible" : "hidden",
              }}
            >
              <WayfinderButton
                variant="tertiary"
                onClick={onClickComparisonBasis}
                sx={{ "&:hover": { backgroundColor: "transparent" } }}
              >
                <Tooltip
                  title="Compare others against this route"
                  arrow
                  aria-label="compare-others"
                  disableTouchListener
                  placement="top"
                  enterNextDelay={0}
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [0, -7],
                        },
                      },
                    ],
                  }}
                  slotProps={{
                    popper: {
                      sx: {
                        pointerEvents: "none", // popper is interfering with the button hover
                      },
                    },
                    tooltip: {
                      sx: {
                        backgroundColor: extendedPalette.navy,
                        pointerEvents: "none",
                      },
                    },
                    arrow: {
                      sx: {
                        color: extendedPalette.navy,
                        pointerEvents: "none",
                      },
                    },
                  }}
                >
                  <BalanceIcon sx={{ width: 16, height: 16 }} />
                </Tooltip>
              </WayfinderButton>
            </Box>
          </Box>
        </Grid>
        <MoreActionsMenu
          isPlanInEditMode={isPlanInEditMode}
          isActiveRoute={isActiveRoute}
          isSuggestedRoute={isSuggestedRoute}
          allowRouteSuggestion={allowRouteSuggestion}
          trackAnalyticsEvent={trackAnalyticsEvent}
          editRoute={editRoute}
          route={route}
          voyage={voyage}
          currentUrl={currentUrl}
          routeUuid={routeUuid}
          onRemoveRoute={onRemoveRoute}
          planUuid={planUuid}
          onFollowRoute={onFollowRoute}
          onSuggestRoute={onSuggestRoute}
          onClickExportRoutes={onClickExportRoutes}
        />
      </Grid>
    </TableCell>
  );
};
