import { Skeleton, Table, TableBody, TableCell, TableRow } from "@mui/material";
import React from "react";
import { isNil, orderBy } from "lodash";
import { DateTime } from "luxon";
import { corePalette, typographyStyles } from "styles/theme";
import { mapTopRouteUuidAtom } from "atoms/map-top-route-uuid";
import { useSetAtom } from "jotai";
import { RouteExplorerTableHead } from "./RouteExplorerTableHead";
import { RouteExplorerTableCell } from "./RouteExplorerTableCell";
import { RouteNameTableCell } from "./RouteNameTableCell";

export type FinancialImpact = "gain" | "loss" | "none";
export type RouteRowData = {
  uuid: string;
  routeName: string | null;
  routeColor: string;
  eta: string;

  // absolute figures
  formattedDistanceNm: string;
  formattedTime: string;
  formattedTimeCostDollar: string;
  formattedFuelCostDollar: string;
  formattedTotalCostDollar: string;
  formattedEmissionsCostDollar: string;
  formattedFuelMT: string;
  formattedEmissionsMT: string;
  formattedAvgSpeedKts: string;

  //relative figures
  relative?: {
    formattedRelativeTime: string;
    relativeTimeImpact: FinancialImpact;
    formattedRelativeTimeCostDollar: string;
    relativeTimeCostDollarImpact: FinancialImpact;
    formattedRelativeFuelCostDollar: string;
    relativeFuelCostDollarImpact: FinancialImpact;
    formattedRelativeTotalCostDollar: string;
    relativeTotalCostDollarImpact: FinancialImpact;
    formattedRelativeEmissionsCostDollar: string;
    relativeEmissionsCostDollarImpact: FinancialImpact;
    formattedRelativeFuelMT: string;
    relativeFuelMTImpact: FinancialImpact;
    formattedRelativeEmissionsMT: string;
    relativeEmissionsMTImpact: FinancialImpact;
    formattedRelativeAvgSpeedKts: string;
    relativeAvgSpeedKtsImpact: FinancialImpact;
    formattedRelativeDistanceNm: string;
    relativeDistanceNmImpact: FinancialImpact;
  };
};

export type RouteExplorerTableProps = {
  routeList: Array<RouteRowData | null>;
  onRemoveRoute: (routeUuid: string) => void;
  onToggleComparisonBasis: (routeUuid: string) => void;
  comparisonBasisRouteUuid: string | undefined;
};

export const EXPLORER_TABLE_ROW_HEIGHT = 48;

type Order = "asc" | "desc";

export const commonTableCellStyle = {
  minWidth: "100px",
  borderRight: `0.025rem solid ${corePalette.gray200}`,
  whiteSpace: "nowrap",
  padding: "0 16px",
  height: EXPLORER_TABLE_ROW_HEIGHT,
  ...typographyStyles.title,
  fontweight: 500,
};

export const RouteExplorerTable: React.FC<RouteExplorerTableProps> = React.memo(
  ({
    routeList,
    onRemoveRoute,
    onToggleComparisonBasis,
    comparisonBasisRouteUuid,
  }) => {
    const [order, setOrder] = React.useState<Order>("asc");
    const [orderKey, setOrderKey] = React.useState<
      keyof Omit<RouteRowData, "relative">
    >("eta");

    const sortedRouteData = orderBy(
      routeList,
      (row) => {
        const value = row?.[orderKey];
        if (!isNil(value)) {
          // `parseFloat` causes the items to sort based on numerical value rather than alphabetical.
          // `replaceAll` eliminates the characters that break the float conversion.
          return parseFloat(value.replaceAll(/\D/g, ""));
        }
      },
      order
    );

    const handleRequestSort = (
      event: React.MouseEvent<unknown>,
      property: any
    ) => {
      const isAsc = orderKey === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderKey(property);
    };

    const setTopRouteUuid = useSetAtom(mapTopRouteUuidAtom);

    const isComparison = routeList.some((r) => r?.relative);

    return (
      <Table sx={{ border: `0.025rem solid ${corePalette.gray200}` }}>
        <RouteExplorerTableHead
          order={order}
          orderKey={orderKey}
          handleRequestSort={handleRequestSort}
        />
        <TableBody>
          {sortedRouteData?.map((r, i) =>
            isNil(r) ? (
              <SkeletonRow key={`skeleton-${i}`} />
            ) : (
              <TableRow
                key={r.uuid}
                hover={true}
                onClick={() => setTopRouteUuid(r.uuid)}
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                    "& .comparison-button": {
                      visibility: "visible",
                    },
                  },
                }}
              >
                {r.routeName && (
                  <RouteNameTableCell
                    routeName={r.routeName}
                    routeColor={r.routeColor}
                    routeUuid={r.uuid}
                    onRemoveRoute={onRemoveRoute}
                    onToggleComparisonBasis={onToggleComparisonBasis}
                    showComparisonButton={comparisonBasisRouteUuid === r.uuid}
                  />
                )}

                <TableCell
                  sx={{ ...commonTableCellStyle, minWidth: "150px" }}
                  align="right"
                >
                  {DateTime.fromISO(r.eta).toUTC().toFormat("LLL dd HH:mm 'Z'")}
                  /{r.formattedTime}
                </TableCell>

                <RouteExplorerTableCell
                  formattedValue={r.formattedDistanceNm}
                  relativeValue={r.relative?.formattedRelativeDistanceNm}
                  financialImpact={r.relative?.relativeDistanceNmImpact}
                  showComparison={isComparison}
                />

                <RouteExplorerTableCell
                  formattedValue={r.formattedTotalCostDollar}
                  relativeValue={r.relative?.formattedRelativeTotalCostDollar}
                  financialImpact={r.relative?.relativeTotalCostDollarImpact}
                  showComparison={isComparison}
                />

                <RouteExplorerTableCell
                  formattedValue={r.formattedFuelCostDollar}
                  relativeValue={r.relative?.formattedRelativeFuelCostDollar}
                  financialImpact={r.relative?.relativeFuelCostDollarImpact}
                  showComparison={isComparison}
                />

                <RouteExplorerTableCell
                  formattedValue={r.formattedTimeCostDollar}
                  relativeValue={r.relative?.formattedRelativeTimeCostDollar}
                  financialImpact={r.relative?.relativeTimeCostDollarImpact}
                  showComparison={isComparison}
                />

                <RouteExplorerTableCell
                  formattedValue={r.formattedEmissionsCostDollar}
                  relativeValue={
                    r.relative?.formattedRelativeEmissionsCostDollar
                  }
                  financialImpact={
                    r.relative?.relativeEmissionsCostDollarImpact
                  }
                  showComparison={isComparison}
                />

                <RouteExplorerTableCell
                  formattedValue={r.formattedFuelMT}
                  relativeValue={r.relative?.formattedRelativeFuelMT}
                  financialImpact={r.relative?.relativeFuelMTImpact}
                  showComparison={isComparison}
                />

                <RouteExplorerTableCell
                  formattedValue={r.formattedEmissionsMT}
                  relativeValue={r.relative?.formattedRelativeEmissionsMT}
                  financialImpact={r.relative?.relativeEmissionsMTImpact}
                  showComparison={isComparison}
                />

                <RouteExplorerTableCell
                  formattedValue={r.formattedAvgSpeedKts}
                  relativeValue={r.relative?.formattedRelativeAvgSpeedKts}
                  financialImpact={r.relative?.relativeAvgSpeedKtsImpact}
                  showComparison={isComparison}
                />
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    );
  }
);

const skeletonRowStyle = { width: "100%" };

/**
 * A skeleton row to be displayed while the data is loading
 */
const SkeletonRow: React.FC = () => {
  return (
    <TableRow>
      <TableCell
        sx={{ ...commonTableCellStyle, minWidth: "250px" }}
        align="right"
      >
        <Skeleton animation="wave" sx={skeletonRowStyle} />
      </TableCell>
      <TableCell
        sx={{ ...commonTableCellStyle, minWidth: "150px" }}
        align="right"
      >
        <Skeleton animation="wave" sx={skeletonRowStyle} />
      </TableCell>

      {Array.from({ length: 8 }).map((_, i) => (
        <TableCell align="right" sx={commonTableCellStyle} key={i}>
          <Skeleton animation="wave" sx={skeletonRowStyle} />
        </TableCell>
      ))}
    </TableRow>
  );
};
