import { AnalyticsEvent } from "contexts/Analytics";
import { FormFieldChangeAnalyticsEvent } from "contexts/Analytics/use-analytics-state";
import _ from "lodash";

export type AnalyticsEventKeyPair<T> = {
  event: AnalyticsEvent;
  key: keyof T;
};

/**
 * @param oldForm original values
 * @param newForm new values
 * @param keyPairs object fields and corresponding Analytics events
 * @returns Analytics object with event name, whether it has change, and data
 * corresponding to the "new values" object field
 */
export function createSimpleFormChangeAnalyticsEventsList<T>(
  oldForm: T,
  newForm: T,
  keyPairs: AnalyticsEventKeyPair<T>[]
): FormFieldChangeAnalyticsEvent[] {
  return keyPairs.map(({ event, key }) => ({
    eventName: event,
    hasChange: !_.isEqual(oldForm[key], newForm[key]),
    data: { key: newForm[key] },
  }));
}

/**
 * @param updatedFields updated values
 * @param keyPairs object fields and corresponding Analytics events
 * @returns Analytics object with event name, whether it has change, and data
 * corresponding to the "new values" object field
 */
export function createUpdatedFormFieldsAnalyticsEventsList<T>(
  updatedFields: Partial<T>,
  keyPairs: AnalyticsEventKeyPair<T>[]
): FormFieldChangeAnalyticsEvent[] {
  return keyPairs.map(({ event, key }) => ({
    eventName: event,
    hasChange: updatedFields.hasOwnProperty(key),
    data: { key: updatedFields[key] },
  }));
}
