import { useCurrentRoutesToCompare } from "components/WayfinderApp/CurrentSession/contexts";
import { useCurrentSession } from "components/WayfinderApp/CurrentSession";
import { useCurrentUrl } from "helpers/navigation";
import { isNil, orderBy } from "lodash";
import { DateTime } from "luxon";
import { useMemo, useRef } from "react";
import { ROUTES_TO_COMPARE_QUERY_PARAM } from "./use-wayfinder-url";

export function usePastRouteTypes() {
  const {
    currentSession: {
      activeRouteUuid,
      suggestedRouteUuid,
      currentSessionIsLoading,
    },
  } = useCurrentSession();

  const { routesToCompare } = useCurrentRoutesToCompare();

  const pastActiveRoutes = useMemo(
    () =>
      routesToCompare
        .filter((r) => r.uuid !== activeRouteUuid)
        .filter((r) => r.uuid !== suggestedRouteUuid)
        .filter((r) => !!r.activeRoute),
    [activeRouteUuid, routesToCompare, suggestedRouteUuid]
  );

  const oldestPastActiveRoute = orderBy(
    pastActiveRoutes,
    (r) => r.activeRoute?.sequenceNumber,
    "asc"
  ).at(0);

  const newestPastSuggestion = useMemo(() => {
    const pastSuggestions = routesToCompare
      .filter((r) => r.uuid !== activeRouteUuid)
      .filter((r) => r.uuid !== suggestedRouteUuid)
      // if the suggestion has not received a response, then we do not consider it a past suggestion
      .filter((r) => !!r.routeSuggestion?.respondedAt);
    return orderBy(
      pastSuggestions,
      (r) => r.routeSuggestion?.sequenceNumber,
      "desc"
    ).at(0);
  }, [activeRouteUuid, routesToCompare, suggestedRouteUuid]);

  const hasCurrentActiveRoute = routesToCompare.some(
    (r) => r.uuid === activeRouteUuid
  );

  const suggestionBaseComparisonRouteUuid =
    newestPastSuggestion?.routeSuggestion?.baseComparisonRouteUuid;

  const comparisonMatches =
    // if there is no basis, this cannot be a past suggestion comparison
    !!suggestionBaseComparisonRouteUuid &&
    // if there is, then it must match the active or oldest active route uuid
    (hasCurrentActiveRoute
      ? activeRouteUuid === suggestionBaseComparisonRouteUuid
      : oldestPastActiveRoute?.uuid === suggestionBaseComparisonRouteUuid);

  const isPastSuggestionComparison = Boolean(
    routesToCompare.length === 2 && comparisonMatches
  );

  const suggestionCreatedAt = newestPastSuggestion?.routeSuggestion?.createdAt;
  const suggestionDateTime = useMemo(
    () =>
      suggestionCreatedAt ? DateTime.fromISO(suggestionCreatedAt) : undefined,
    [suggestionCreatedAt]
  );

  const suggestionRespondedAt =
    newestPastSuggestion?.routeSuggestion?.respondedAt;
  const suggestionResponseDateTime = useMemo(
    () =>
      suggestionRespondedAt
        ? DateTime.fromISO(suggestionRespondedAt)
        : undefined,
    [suggestionRespondedAt]
  );

  const currentUrl = useCurrentUrl();

  const routesAreLoading =
    (currentUrl.search().has(ROUTES_TO_COMPARE_QUERY_PARAM) &&
      routesToCompare.length === 0) ||
    routesToCompare.some((r) => r.metadata?.isLoading || !r.route);

  const isLoadingPastRoutes = currentSessionIsLoading && routesAreLoading;

  // this ref stores a flag that will let us know if the user navigated to a past suggestion
  // it starts null, meaning that we are not sure yet
  const isPastSuggestionComparisonOnLoad = useRef<boolean | null>(null);

  // we need to wait for the resources to load before we know if the url goes to a past suggestion
  // once we are done loading, we set the flag once.
  if (!isLoadingPastRoutes && isNil(isPastSuggestionComparisonOnLoad.current)) {
    isPastSuggestionComparisonOnLoad.current = isPastSuggestionComparison;
  }

  return {
    oldestPastActiveRoute,
    newestPastSuggestion,
    isPastSuggestionComparison,
    suggestionDateTime,
    suggestionResponseDateTime,
    isLoadingPastRoutes,
    isPastSuggestionComparisonOnLoad: isPastSuggestionComparisonOnLoad.current,
  };
}
