import { TableCell, Box } from "@mui/material";
import { WayfinderTypography } from "DLS";
import React from "react";
import { extendedPalette } from "styles/theme";
import { match } from "ts-pattern";
import { FinancialImpact, commonTableCellStyle } from "./RouteExplorerTable";

// when the user selects a route to compare against, the relative values are shown
const getTableCellContentStyle = (relative: boolean) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: relative ? "space-between" : "flex-end",
});

export const RouteExplorerTableCell: React.FC<{
  formattedValue: string;
  relativeValue?: string;
  financialImpact?: FinancialImpact;
  showComparison: boolean;
}> = ({ relativeValue, formattedValue, financialImpact, showComparison }) => {
  return (
    <TableCell sx={commonTableCellStyle}>
      <Box sx={getTableCellContentStyle(showComparison)}>
        {formattedValue}
        <RelativeValueCallout
          value={relativeValue}
          financialImpact={financialImpact}
        />
      </Box>
    </TableCell>
  );
};

export const RelativeValueCallout: React.FC<{
  value?: string;
  financialImpact?: FinancialImpact;
}> = ({ value, financialImpact }) => {
  if (!value || !financialImpact) {
    return null;
  }
  const backgroundColor = match(financialImpact)
    .with("gain", () => "#D1FAE5") // green
    .with("loss", () => "#FCC1C9") // pink
    .with("none", () => extendedPalette.neutralLight) // gray
    .exhaustive();

  return (
    <WayfinderTypography
      variant="buttonSmall"
      component="span"
      sx={{
        backgroundColor,
        borderRadius: "0.5rem",
        padding: "0.25rem 0.5rem",
        marginLeft: "0.5rem",
      }}
    >
      {value}
    </WayfinderTypography>
  );
};
