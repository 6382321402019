import { Card } from "@mui/material";
import { extendedPalette } from "styles/theme";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  RouteExplorerTable,
  RouteRowData,
} from "components/sidebar/RouteExplorer/RouteExplorerTable";
import { useCurrentRoutesToCompare } from "components/WayfinderApp/CurrentSession/contexts";
import { useRouteStyles } from "styles/use-route-styles";
import { RouteStyle } from "components/WeatherAlongRoutePlot";
import { getRouteSchedule } from "helpers/getRouteSchedule";
import { RouteExportForm } from "components/modals/RouteExportForm";
import { VoyageMenuStateContext } from "components/sidebar/Voyage/use-voyage-menu-state";
import { compact, isNil } from "lodash";
import { useWayfinderUrl } from "shared-hooks/use-wayfinder-url";
import {
  computeRelativeRouteSummary,
  RouteSummaryData,
} from "helpers/routeSummary";
import {
  RouteScoreOptions,
  useRouteScoreOptions,
} from "components/sidebar/RouteSummary/use-route-score-options";
import { formatSpeed, FormattedSpeedUnits } from "helpers/routes";
import AnalyticsContext, { AnalyticsEvent } from "contexts/Analytics";
import useRoute from "contexts/RouteStoreContext/use-route";
import { Route } from "shared-types/RouteTypes";

export const COMPARISON_TABLE_HEIGHT = 400;

const MISSING_DATA_STRING = "--";

type TableRowRouteData = {
  routeSummaryData: RouteSummaryData;
  route: Route;
  uuid: string;
};

const isTableRowRouteData = (
  r: Partial<TableRowRouteData>
): r is TableRowRouteData => !!r.routeSummaryData && !!r.route && !!r.uuid;

const getFinancialImpact = (value: number | undefined) =>
  isNil(value) || value === 0 ? "none" : value < 0 ? "gain" : "loss";

const getRowData = ({
  route,
  comparisonBasisRoute,
  routeStylesList,
  routeScoreOptions,
}: {
  route: TableRowRouteData;
  comparisonBasisRoute?: TableRowRouteData;
  routeStylesList: Record<string, RouteStyle>;
  routeScoreOptions: RouteScoreOptions;
}): RouteRowData => {
  const { arrivalScheduleElement = undefined } = route.route
    ? getRouteSchedule(route.route)
    : {};
  const {
    routeSummaryData: { absoluteSummary },
  } = route;

  const comparisonBasisAbsoluteSummary =
    comparisonBasisRoute?.routeSummaryData.absoluteSummary;

  const relativeSummary =
    comparisonBasisAbsoluteSummary &&
    absoluteSummary &&
    comparisonBasisAbsoluteSummary?.routeUuid !== route.uuid
      ? computeRelativeRouteSummary({
          absoluteSummary,
          comparisonBasisAbsoluteSummary,
          options: routeScoreOptions,
        })
      : undefined;

  const averageSpeedDifferenceKts =
    comparisonBasisAbsoluteSummary &&
    absoluteSummary.avgSpeedRemaining.knots -
      comparisonBasisAbsoluteSummary.avgSpeedRemaining.knots;

  const formattedRelativeAvgSpeedKts =
    formatSpeed(averageSpeedDifferenceKts, FormattedSpeedUnits.Knots) ??
    MISSING_DATA_STRING;

  return {
    uuid: route.uuid,
    routeName: route.route?.routeInfo.routeName ?? null,
    routeColor: routeStylesList[route.uuid]?.color,
    eta: arrivalScheduleElement?.eta ?? MISSING_DATA_STRING,

    //absolute figures

    formattedTime: absoluteSummary.time.formattedTime ?? MISSING_DATA_STRING,
    formattedTimeCostDollar:
      absoluteSummary.time.formattedTimeExpense ?? MISSING_DATA_STRING,
    formattedFuelCostDollar:
      absoluteSummary.fuel.total.formattedFuelExpense ?? MISSING_DATA_STRING,
    formattedTotalCostDollar:
      absoluteSummary.voyage.formattedVoyageExpenses ?? MISSING_DATA_STRING,
    formattedEmissionsCostDollar:
      absoluteSummary.emissions.eua.formattedEmissionsExpense ??
      MISSING_DATA_STRING,
    formattedFuelMT:
      absoluteSummary.fuel.total.formattedFuel ?? MISSING_DATA_STRING,
    formattedEmissionsMT:
      absoluteSummary.emissions.formattedTotalEmissions ?? MISSING_DATA_STRING,
    formattedAvgSpeedKts:
      absoluteSummary.avgSpeedRemaining.formattedAvgSpeedRemaining ??
      MISSING_DATA_STRING,
    formattedDistanceNm:
      absoluteSummary.distance.formattedDistance ?? MISSING_DATA_STRING,

    // relative figures
    relative: isNil(relativeSummary)
      ? undefined
      : {
          formattedRelativeTime: relativeSummary.time.formattedTimeDifference,
          relativeTimeImpact: getFinancialImpact(
            -relativeSummary.time.timeDifferenceMs
          ),

          formattedRelativeTimeCostDollar:
            relativeSummary.time.formattedTimeExpenseLosses,
          relativeTimeCostDollarImpact: getFinancialImpact(
            relativeSummary.time.timeExpenseSavingsDollars &&
              -relativeSummary.time.timeExpenseSavingsDollars
          ),

          formattedRelativeFuelCostDollar:
            relativeSummary.fuel.total.formattedFuelExpenseLosses,
          relativeFuelCostDollarImpact: getFinancialImpact(
            relativeSummary.fuel.total.fuelExpenseSavingsDollars &&
              -relativeSummary.fuel.total.fuelExpenseSavingsDollars
          ),

          formattedRelativeTotalCostDollar:
            relativeSummary.voyage.formattedVoyageExpenseLosses,
          relativeTotalCostDollarImpact: getFinancialImpact(
            relativeSummary.voyage.voyageExpenseSavings &&
              -relativeSummary.voyage.voyageExpenseSavings
          ),

          formattedRelativeEmissionsCostDollar:
            relativeSummary.emissions.eua.formattedEmissionsExpenseLosses,
          relativeEmissionsCostDollarImpact: getFinancialImpact(
            relativeSummary.emissions.eua.emissionsExpenseSavingsDollars &&
              -relativeSummary.emissions.eua.emissionsExpenseSavingsDollars
          ),

          formattedRelativeFuelMT:
            relativeSummary.fuel.total.formattedFuelDifference,
          relativeFuelMTImpact: getFinancialImpact(
            relativeSummary.fuel.total.fuelDifferenceMT &&
              -relativeSummary.fuel.total.fuelDifferenceMT
          ),

          formattedRelativeEmissionsMT:
            relativeSummary.emissions.formattedEmissionsDifferenceCo2MT,
          relativeEmissionsMTImpact: getFinancialImpact(
            relativeSummary.emissions.emissionsDifferenceCo2MT &&
              -relativeSummary.emissions.emissionsDifferenceCo2MT
          ),

          formattedRelativeAvgSpeedKts,
          relativeAvgSpeedKtsImpact: "none",

          formattedRelativeDistanceNm:
            relativeSummary.distance.formattedDistanceDifference,
          relativeDistanceNmImpact: getFinancialImpact(
            -relativeSummary.distance.distanceDifferenceNM
          ),
        },
  };
};

export const ConnectedRouteExplorerTable: React.FC<{}> = () => {
  const {
    hideExportForm,
    exportFormVisible,
    exportError,
    downloadRoutesWithFormats,
  } = useContext(VoyageMenuStateContext);
  const { trackAnalyticsEvent } = useContext(AnalyticsContext);
  const { setRoutesToCompare } = useWayfinderUrl();
  const { routesToCompare, routeUuidsToCompare } = useCurrentRoutesToCompare();

  const routeStylesList = useRouteStyles();

  const routeScoreOptions = useRouteScoreOptions();

  const [comparisonBasisRouteUuid, setComparisonBasisRouteUuid] = useState<
    string | undefined
  >(undefined);
  const comparisonBasisRoute = useRoute(comparisonBasisRouteUuid);

  const routeList: (RouteRowData | null)[] = useMemo(() => {
    const rows = routesToCompare.map((route) =>
      isTableRowRouteData(route)
        ? getRowData({
            route,
            routeStylesList,
            routeScoreOptions,
            comparisonBasisRoute: isTableRowRouteData(comparisonBasisRoute)
              ? comparisonBasisRoute
              : undefined,
          })
        : null
    );
    if (rows.some((r) => r === null)) {
      return rows.fill(null);
    }
    return rows;
  }, [
    comparisonBasisRoute,
    routeScoreOptions,
    routeStylesList,
    routesToCompare,
  ]);

  const onRemoveRoute = useCallback(
    (routeUuid: string) => {
      const newRoutesToCompareUuids = routeUuidsToCompare.filter(
        (uuid) => uuid !== routeUuid
      );
      setRoutesToCompare(newRoutesToCompareUuids);
      trackAnalyticsEvent(AnalyticsEvent.VoyagePlanRemovedRoute, { routeUuid });
    },
    [routeUuidsToCompare, setRoutesToCompare, trackAnalyticsEvent]
  );

  const onToggleComparisonBasis = useCallback(
    (uuid: string) => {
      if (uuid === comparisonBasisRouteUuid) {
        setComparisonBasisRouteUuid(undefined);
      } else {
        setComparisonBasisRouteUuid(uuid);
      }
    },
    [comparisonBasisRouteUuid, setComparisonBasisRouteUuid]
  );

  useEffect(() => {
    // when the component unmounts, clear the comparison basis
    return () => {
      setComparisonBasisRouteUuid(undefined);
    };
  }, [setComparisonBasisRouteUuid]);

  return (
    <Card
      sx={{
        maxHeight: `${COMPARISON_TABLE_HEIGHT}px`,
        borderTop: `1px solid ${extendedPalette.neutral}`,
        overflow: "auto",
      }}
    >
      <RouteExplorerTable
        routeList={routeList}
        onRemoveRoute={onRemoveRoute}
        onToggleComparisonBasis={onToggleComparisonBasis}
        comparisonBasisRouteUuid={comparisonBasisRouteUuid}
      />
      <RouteExportForm
        show={exportFormVisible}
        close={hideExportForm}
        onSubmit={downloadRoutesWithFormats}
        routes={compact(routesToCompare.map((r) => r.route))}
        error={exportError}
      />
    </Card>
  );
};
