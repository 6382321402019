import { useCurrentVessel } from "components/WayfinderApp/CurrentSession/contexts";
import { FleetViewTab } from "contexts/FleetViewContext";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { matchPath, useHistory, useLocation } from "react-router-dom";
import {
  ALL_ROUTES_PATH,
  ALL_VOYAGES_PATH,
  ALL_ROUTE_EXPLORER_SIDEBAR_PATHS,
  AREA_CONSTRAINT_PATH,
  CREATE_VOYAGE_PATH,
  DASHBOARD_PATH,
  EDIT_ROUTE_PATH,
  EDIT_VOYAGE_PATH,
  FLEET_VIEW_PATH,
  FLEET_VIEW_TAB_QUERY_PARAM,
  MLV_REPORTS_PATH,
  OPTIMIZATION_HISTORY_PATH,
  OPTIMIZATION_PATH,
  ROUTES_TO_COMPARE_QUERY_PARAM,
  ROUTE_PATH,
  RPM_ADHERENCE_UUID_QUERY_PARAM,
  SEAKEEPING_PATH,
  SETTINGS_PATH,
  useWayfinderUrlUuids,
  VESSEL_DETAILS_BASE_PATH,
  VOYAGE_BASE_PATH,
  ROUTE_EXPLORER_ROUTE_COMPARISON_PATH,
  VESSEL_DETAILS_PATHS,
  SIMULATED_HISTORICAL_TIME_QUERY_PARAM,
  VOYAGE_PLAN_QUERY_PARAM,
  VOYAGE_PLAN_PATH,
} from "shared-hooks/use-wayfinder-url";

export class WayfinderUrl {
  private _origin: string;
  private _pathname: string;
  private _search: URLSearchParams;
  private _history: ReturnType<typeof useHistory>;

  constructor(init: {
    origin?: string;
    pathname?: string;
    search?: string | URLSearchParams;
    history: ReturnType<typeof useHistory>;
  }) {
    const {
      origin = window.location.origin,
      pathname = window.location.pathname,
      search = window.location.search,
      history,
    } = init ?? {};
    this._pathname = pathname;
    this._search = new URLSearchParams(search);
    this._history = history;
    this._origin = origin;
  }

  /**
   * Navigate the browser to this url and add to the history
   */
  public go() {
    this._history.push(this.toString());
  }

  /**
   * Navigate the browser to this url by replacing the current item in the history
   */
  public redirect() {
    this._history.replace(this.toString());
  }

  private _vesselUuid(): string | undefined {
    const { vesselUuid } =
      matchPath<{ vesselUuid: string }>(this._pathname, {
        path: VESSEL_DETAILS_BASE_PATH,
      })?.params ?? {};
    return vesselUuid;
  }

  private _voyageUuid(): string | undefined {
    const { voyageUuid } =
      matchPath<{ voyageUuid: string }>(this._pathname, {
        path: VOYAGE_BASE_PATH,
      })?.params ?? {};
    return voyageUuid;
  }

  private _detailRouteUuid(): string | undefined {
    const { routeUuid } =
      matchPath<{ routeUuid: string }>(this._pathname, {
        path: ROUTE_PATH,
      })?.params ?? {};
    return routeUuid;
  }

  private _voyagePlanUuid(): string | undefined {
    const { voyagePlanUuid } =
      matchPath<{ voyagePlanUuid: string }>(this._pathname, {
        path: VOYAGE_PLAN_PATH,
      })?.params ?? {};
    return voyagePlanUuid;
  }

  private _replaceQueryParam(
    paramName: string,
    newValue?: string | number | null
  ): WayfinderUrl {
    const newSearch = new URLSearchParams(this._search);
    if (newValue) {
      newSearch.set(paramName, `${newValue}`);
    } else {
      newSearch.delete(paramName);
    }
    return new WayfinderUrl({
      pathname: this._pathname,
      search: newSearch.toString(),
      history: this._history,
    });
  }

  private _getFleetTabParam(): string | undefined | null {
    return this._search.get(FLEET_VIEW_TAB_QUERY_PARAM);
  }

  /**
   * Replace the vessel uuid in the url if present
   * @param newVesselUuid
   */
  public withVesselUuid(newVesselUuid: string): WayfinderUrl {
    const vesselUuid = this._vesselUuid();
    if (!vesselUuid || !newVesselUuid) {
      return this;
    }
    return new WayfinderUrl({
      pathname: this._pathname.replace(vesselUuid, newVesselUuid),
      search: this._search,
      history: this._history,
    });
  }

  /**
   * Replace the voyage uuid in the url if present
   * @param newVoyageUuid
   */
  public withVoyageUuid(newVoyageUuid: string): WayfinderUrl {
    const voyageUuid = this._voyageUuid();
    let pathname;
    if (!voyageUuid || !newVoyageUuid) {
      return this;
    } else {
      pathname = this._pathname.replace(voyageUuid, newVoyageUuid);
    }
    return new WayfinderUrl({
      pathname,
      search: this._search,
      history: this._history,
    });
  }

  /**
   * Replace the detail route uuid in the url if present
   * @param newDetailRouteUuid
   */
  public withDetailRouteUuid(newDetailRouteUuid: string): WayfinderUrl {
    const detailRouteUuid = this._detailRouteUuid();
    let pathname;
    if (!detailRouteUuid || !newDetailRouteUuid) {
      return this;
    } else {
      pathname = this._pathname.replace(detailRouteUuid, newDetailRouteUuid);
    }
    return new WayfinderUrl({
      pathname,
      search: this._search,
      history: this._history,
    });
  }

  /**
   * Replace or delete the mlv uuid used to point to a specific
   * vessel report in the vessel reports tab of the VDP
   * @param newMlvUuid
   */
  public withVesselReport(newMlvUuid: string | null): WayfinderUrl {
    const { mlvUuid } =
      matchPath<{ mlvUuid: string }>(this._pathname, {
        path: MLV_REPORTS_PATH,
      })?.params ?? {};
    let pathname;
    if (!mlvUuid) {
      return this;
    } else {
      pathname = this._pathname.replace(mlvUuid, newMlvUuid ?? "");
    }
    return new WayfinderUrl({
      pathname,
      search: this._search,
      history: this._history,
    });
  }

  /**
   * Replace or delete the routesToCompare in the query params
   * @param newRoutesToCompare
   */
  public withRoutesToCompare(
    newRoutesToCompare: string[] | null
  ): WayfinderUrl {
    return this._replaceQueryParam(
      ROUTES_TO_COMPARE_QUERY_PARAM,
      newRoutesToCompare?.join()
    );
  }

  /**
   * Replace or delete the fleetViewTab in the query params
   * @param newFleetViewTab
   */
  public withFleetViewTab(newFleetViewTab: FleetViewTab | null): WayfinderUrl {
    return this._replaceQueryParam(FLEET_VIEW_TAB_QUERY_PARAM, newFleetViewTab);
  }

  /**
   * Replace or delete the rpmAdherenceUuid in the query params
   * @param newRpmAdherenceUuid
   */
  public withRpmAdherenceUuid(
    newRpmAdherenceUuid: string | null
  ): WayfinderUrl {
    return this._replaceQueryParam(
      RPM_ADHERENCE_UUID_QUERY_PARAM,
      newRpmAdherenceUuid
    );
  }

  /**
   * Replace or delete the simulatedHistoricalTime in the query params
   * @param iso
   */
  public withSimulatedHistoricalTime(iso: string | null): WayfinderUrl {
    return this._replaceQueryParam(SIMULATED_HISTORICAL_TIME_QUERY_PARAM, iso);
  }

  /**
   * Replace the voyage plan uuid in the url if present
   * @param newVoyagePlanUuid
   */
  public withVoyagePlanUuid(newVoyagePlanUuid: string): WayfinderUrl {
    const voyagePlanUuid = this._voyagePlanUuid();
    let pathname;
    if (!voyagePlanUuid || !newVoyagePlanUuid) {
      return this;
    } else {
      pathname = this._pathname.replace(voyagePlanUuid, newVoyagePlanUuid);
    }
    return new WayfinderUrl({
      pathname,
      search: this._search,
      history: this._history,
    });
  }

  /**
   * Replace or delete the voyage plan uuid in the query params
   * @param newVoyagePlanUuid
   */
  public withVoyagePlanUuidQueryParam(
    newVoyagePlanUuid: string | null
  ): WayfinderUrl {
    return this._replaceQueryParam(VOYAGE_PLAN_QUERY_PARAM, newVoyagePlanUuid);
  }

  /**
   * Get the voyage plan uuid out of the query params
   */
  public getVoyagePlanUuidQueryParam(): string | null {
    return this._search.get(VOYAGE_PLAN_QUERY_PARAM);
  }

  /**
   * Get the voyage plan uuid out of the path, not the query params
   */
  public getVoyagePlanUuidFromPath(): string | undefined {
    return this._voyagePlanUuid();
  }

  /**
   *  Get the string representing a simulated historical forecast time from the url
   */
  public getSimulatedHistoricalTime() {
    return this._search.get(SIMULATED_HISTORICAL_TIME_QUERY_PARAM);
  }

  /**
   *  Get a DateTime representing a simulated historical forecast time from the string in the url
   */
  public getSimulatedHistoricalDateTime() {
    const simulatedHistoricalTime = this.getSimulatedHistoricalTime();
    return simulatedHistoricalTime
      ? DateTime.fromISO(simulatedHistoricalTime)
      : null;
  }

  /**
   * Get the detail route uuid from the path
   */
  public getDetailRouteUuid(): string | undefined {
    return this._detailRouteUuid();
  }

  /**
   * Does the url go to any of the sidebars that show voyage info?
   */
  public isVoyage(): boolean {
    return !!matchPath(this._pathname, {
      path: VOYAGE_BASE_PATH,
    });
  }

  /**
   * Does the url include a voyage plan?
   */
  public isVoyagePlan(): boolean {
    return !!this._search.has(VOYAGE_PLAN_QUERY_PARAM);
  }

  /**
   * Does the url go to one of the vessel detail pages?
   */
  public isVesselDetails(): boolean {
    return !!matchPath(this._pathname, {
      path: VESSEL_DETAILS_PATHS,
      exact: true,
    });
  }

  /**
   * Does the url go to any configuration of the fleet view screen?
   * Checks the path only, ignoring the specific tab
   */
  public isFleetView(): boolean {
    return !!matchPath(this._pathname, {
      path: FLEET_VIEW_PATH,
    });
  }

  /**
   * Does the url go to the fleet table?
   */
  public isFleetTable(): boolean {
    const tabParam = this._getFleetTabParam();
    return this.isFleetView() && (tabParam === null || tabParam === "table");
  }

  /**
   * Does the url go to the CP analysis tab of fleet view?
   */
  public isFleetCpAnalysis(): boolean {
    return this.isFleetView() && this._getFleetTabParam() === "cp-analysis";
  }

  /**
   * Does the url go to the CII tab of fleet view?
   */
  public isFleetCii(): boolean {
    return this.isFleetView() && this._getFleetTabParam() === "cii";
  }

  /**
   * Does this url go to the settings page?
   */
  public isSettings(): boolean {
    return !!matchPath(this._pathname, {
      path: SETTINGS_PATH,
      exact: true,
    });
  }

  /**
   * Does this url go to the route comparison sidebar for a voyage?
   */
  public isVoyageRoutes(): boolean {
    return !!matchPath(this._pathname, {
      path: ALL_ROUTES_PATH,
      exact: true,
    });
  }

  /**
   * Does this url go to a single route sidebar?
   */
  public isRouteDetail(): boolean {
    return !!matchPath(this._pathname, {
      path: ROUTE_PATH,
      exact: true,
    });
  }

  /**
   * Does this url go to dashboard sidebar?
   */
  public isDashboard(): boolean {
    return !!matchPath(this._pathname, {
      path: DASHBOARD_PATH,
      exact: true,
    });
  }

  /**
   * Does this url go to a the optimization sidebar (sometimes called "Routing")?
   */
  public isOptimization(): boolean {
    return !!matchPath(this._pathname, {
      path: OPTIMIZATION_PATH,
      exact: true,
    });
  }

  /**
   * Does this url go to the constraints/instructions/intentions history sidebar?
   */
  public isOptimizationHistory(): boolean {
    return !!matchPath(this._pathname, {
      path: OPTIMIZATION_HISTORY_PATH,
      exact: true,
    });
  }

  /**
   * Does this url go to seakeeing sidebar?
   */
  public isSeakeeping(): boolean {
    return !!matchPath(this._pathname, {
      path: SEAKEEPING_PATH,
      exact: true,
    });
  }

  /**
   * Does this url go to area constraints sidebar?
   */
  public isAreaConstraints(): boolean {
    return !!matchPath(this._pathname, {
      path: AREA_CONSTRAINT_PATH,
      exact: true,
    });
  }

  /**
   * Does this url go to route explorer sidebar?
   */
  public isRouteExplorer(): boolean {
    return !!matchPath(this._pathname, {
      path: ALL_ROUTE_EXPLORER_SIDEBAR_PATHS,
      exact: true,
    });
  }

  /**
   * Does this url go to route explorer comparison view?
   */
  public isRouteExplorerRouteComparison(): boolean {
    return !!matchPath(this._pathname, {
      path: ROUTE_EXPLORER_ROUTE_COMPARISON_PATH,
      exact: true,
    });
  }

  /**
   * Does this url go to all-voyages sidebar?
   */
  public isAllVoyages(): boolean {
    return !!matchPath(this._pathname, {
      path: ALL_VOYAGES_PATH,
      exact: true,
    });
  }

  /**
   * Does this url go to new voyage sidebar?
   */
  public isNewVoyage(): boolean {
    return !!matchPath(this._pathname, {
      path: CREATE_VOYAGE_PATH,
      exact: true,
    });
  }

  /**
   * Does this url go to voyage editing sidebar?
   */
  public isEditVoyage(): boolean {
    return !!matchPath(this._pathname, {
      path: EDIT_VOYAGE_PATH,
      exact: true,
    });
  }

  /**
   * Does this url go to voyage editing sidebar?
   */
  public isEditRoute(): boolean {
    return !!matchPath(this._pathname, {
      path: EDIT_ROUTE_PATH,
      exact: true,
    });
  }

  /**
   * The path and query parameters as a string
   */
  public toString(): string {
    const searchString = this._search.toString();
    if (!searchString) {
      return this._pathname;
    }
    return `${this._pathname}?${searchString}`;
  }

  /**
   * Just the path part of the url
   */
  public pathname(): string {
    return this._pathname;
  }

  /**
   * Just the search part of the url
   */
  public search(): URLSearchParams {
    return this._search;
  }

  /**
   * A link representation, fit for the `to` prop of a ReactRouter Link component
   */
  public link(): { pathname: string; search: string } {
    return { pathname: this._pathname, search: `?${this._search.toString()}` };
  }

  /**
   * Get the whole url
   * @param includeQueryParam
   */
  public urlString(includeQueryParam: boolean): string {
    return (
      this._origin + (includeQueryParam ? this.toString() : this._pathname)
    );
  }

  /**
   * Replace just the path in a url
   * Keep the current vessel and voyage uuids if we have them
   * @param pathname the path to use instead
   */
  public withPath(pathname: string): WayfinderUrl {
    let newUrl = new WayfinderUrl({
      pathname,
      search: this._search,
      history: this._history,
    });
    const vesselUuid = this._vesselUuid();
    const voyageUuid = this._voyageUuid();
    if (vesselUuid) {
      newUrl = newUrl.withVesselUuid(vesselUuid);
    }
    if (voyageUuid) {
      newUrl = newUrl.withVoyageUuid(voyageUuid);
    }
    return newUrl;
  }

  /**
   * Replace just the query params in a url
   * @param search the query params to use instead
   */
  public withQueryParams(search: string): WayfinderUrl {
    return new WayfinderUrl({ search, history: this._history });
  }
}

/**
 * This hook provides a WayfinderUrl that is kept up to date with
 * the current path and query params as a user navigates or toggles features
 *
 * @returns
 */
export function useCurrentUrl() {
  const history = useHistory();
  const [currentUrl, setCurrentUrl] = useState(new WayfinderUrl({ history }));
  const updateTrigger = useLocation().key;

  useEffect(() => {
    // the new url will have all of the updated url info
    setCurrentUrl(
      new WayfinderUrl({
        history,
      })
    );
  }, [history, updateTrigger]);
  return currentUrl;
}

/**
 * This hook provides a WayfinderUrl pointing to the current vessel detail base.
 *
 * - It is kept up to date with current query params and filters out
 * any params that are not relevant at the voyage paths.
 *
 * - If no vessel uuid can be determined from the current url, the hook returns null.
 * @returns
 */
export function useCurrentVesselDetailBaseUrl() {
  const currentUrl = useCurrentUrl();
  const { vesselUuid } = useWayfinderUrlUuids();

  if (vesselUuid) {
    // set up our voyage and vessel links
    // create a base a url containing the voyage and vessel uuids
    return (
      currentUrl
        .withPath(VESSEL_DETAILS_BASE_PATH)
        .withVesselUuid(vesselUuid)
        // we never want to keep these query params when navigating to voyage path
        .withRoutesToCompare(null)
    );
  }
  return null;
}

/**
 * This hook provides a WayfinderUrl pointing to the current voyage.
 *
 * - It is kept up to date with current query params and filters out
 * any params that are not relevant at the voyage paths.
 *
 * - If there is no voyage uuid in the current url, the hook falls back to the
 * primary voyage of the current vessel.
 *
 * - If no voyage or vessel uuids can be determined from the current url or vessel, the hook returns null.
 * @returns
 */
export function useCurrentVoyageBaseUrl() {
  const currentUrl = useCurrentUrl();
  const { vesselUuid, voyageUuid } = useWayfinderUrlUuids();
  const { vessel } = useCurrentVessel();

  const voyageUuidForLinks = voyageUuid ?? vessel?.primaryVoyageUuid;

  if (vesselUuid && voyageUuidForLinks) {
    // set up our voyage and vessel links
    // create a base a url containing the voyage and vessel uuids
    return (
      currentUrl
        .withPath(VOYAGE_BASE_PATH)
        .withVesselUuid(vesselUuid)
        .withVoyageUuid(voyageUuidForLinks)
        // we never want to keep these query params when navigating to voyage path
        .withRpmAdherenceUuid(null)
        .withFleetViewTab(null)
    );
  }
  return null;
}
