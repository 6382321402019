import React, { MutableRefObject } from "react";
import { ServiceWorkerStatus } from "../../service-worker/register";
import { PanelContextDefaults, PanelContextType } from "../PanelContext";

export type RasterWeatherLayer =
  | "wind"
  | "windGust"
  | "combinedWaves"
  | "currents"
  | "barometricPressure"
  | "precipitation"
  | "visibility"
  | null;
export enum VectorWeatherLayer {
  Arrows = "arrows",
  WindBarbs = "wind-barbs",
}

export type WeatherMapLayers = {
  raster: RasterWeatherLayer;
  vector: VectorWeatherLayer;
  showPressure: boolean;
  showTropicalStorms: boolean;
  showNauticalCharts: boolean;
  showIceLayers: boolean;
};

export type VesselWaypointIds = {
  // vessel is either on a leg...
  legStartWaypointId: number | null;
  legEndWaypointId: number | null;
  // or sitting right on top of a waypoint
  exactWaypointId: number | null;
};

export type RouteSuggestionFeedbackFormResult = "submit" | "go-back";

export type UIContextType = {
  hideRouteRejectionFeedbackForm: (
    result: RouteSuggestionFeedbackFormResult
  ) => void;
  showRouteRejectionFeedbackForm: () => void;
  resetRouteRejectionFeedbackForm: () => void;
  routeRejectionFeedbackFormVisible: boolean;
  routeRejectionFeedbackFormResult:
    | RouteSuggestionFeedbackFormResult
    | undefined;

  setWeatherMapLayers: (newLayers: WeatherMapLayers) => void;
  weatherMapLayers: WeatherMapLayers;

  serviceWorkerStatus?: ServiceWorkerStatus;

  isLoading: boolean;
  setIsLoading: (loading: boolean) => void;
  sidebarPanelState: PanelContextType;
  timelinePanelState: PanelContextType;
  mapOverlayPanelState: PanelContextType;
  setPanelLayoutMounted: (state: boolean) => void;

  summaryPopoverRouteUuid: string | null;
  setSummaryPopoverRouteUuid: (uuid: string) => void;
  closeInfoPopover: () => void;
  sidePanelElement: MutableRefObject<HTMLDivElement | null>;

  showFleetView: boolean;

  allowMapZoomOnScroll: boolean;
  setAllowMapZoomOnScroll: (value: boolean) => void;
};

export const UIContextDefaults: UIContextType = {
  hideRouteRejectionFeedbackForm: () => {},
  showRouteRejectionFeedbackForm: () => {},
  resetRouteRejectionFeedbackForm: () => {},
  routeRejectionFeedbackFormVisible: false,
  routeRejectionFeedbackFormResult: undefined,

  setWeatherMapLayers: () => null,
  weatherMapLayers: {
    raster: "combinedWaves",
    showPressure: true,
    vector: VectorWeatherLayer.WindBarbs,
    showTropicalStorms: true,
    showNauticalCharts: false,
    showIceLayers: false,
  },

  serviceWorkerStatus: {
    hasCheckedForUpdate: false,
    isNewAppReady: false,
    isNewAppPublished: false,
    updateNow: () => null,
    autoRefreshOnUpdate: false,
    needsRefresh: false,
  },

  isLoading: false,
  setIsLoading: () => null,
  sidebarPanelState: PanelContextDefaults,
  timelinePanelState: PanelContextDefaults,
  mapOverlayPanelState: PanelContextDefaults,
  setPanelLayoutMounted: () => null,

  summaryPopoverRouteUuid: null,
  setSummaryPopoverRouteUuid: (uuid: string) => {},
  closeInfoPopover: () => {},
  sidePanelElement: { current: null },

  showFleetView: false,

  allowMapZoomOnScroll: true,
  setAllowMapZoomOnScroll: (value: boolean) => {},
};
const UIContext = React.createContext<UIContextType>(UIContextDefaults);

export default UIContext;
