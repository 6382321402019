import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Box,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import React from "react";
import { corePalette } from "styles/theme";
import { commonTableCellStyle, RouteRowData } from "./RouteExplorerTable";

const HEAD_CELLS: {
  id: keyof Omit<RouteRowData, "relative">;
  label: string;
}[] = [
  { id: "eta", label: "ETA" },
  { id: "formattedDistanceNm", label: "DISTANCE (NM)" },
  { id: "formattedTotalCostDollar", label: "TOTAL COST" },
  { id: "formattedFuelCostDollar", label: "FUEL COST" },
  { id: "formattedTimeCostDollar", label: "TIME COST" },
  { id: "formattedEmissionsCostDollar", label: "EMISSIONS COST" },
  { id: "formattedFuelMT", label: "M/E FUEL REQUIRED" },
  { id: "formattedEmissionsMT", label: "M/E CO2 EMISSIONS" },
  { id: "formattedAvgSpeedKts", label: "AVG SOG" },
];

const tableSortLabelStyle = {
  ":hover": {
    color: corePalette.blue500,
    ".MuiTableSortLabel-icon": {
      color: corePalette.blue500,
      opacity: 1,
    },
  },
};

export const RouteExplorerTableHead: React.FC<{
  orderKey: string;
  order: "asc" | "desc";
  handleRequestSort: (event: React.MouseEvent<unknown>, id: string) => void;
}> = ({ orderKey, order, handleRequestSort }) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell
          sx={{ borderRight: `0.025rem solid ${corePalette.gray200}` }}
        />
        {HEAD_CELLS.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            sortDirection={orderKey === headCell.id ? order : false}
            sx={{
              ...commonTableCellStyle,
              fontSize: "12px",
              fontweight: 600,
            }}
          >
            <TableSortLabel
              active={orderKey === headCell.id}
              direction={orderKey === headCell.id ? order : "asc"}
              onClick={(e) => handleRequestSort(e, headCell.id)}
              sx={tableSortLabelStyle}
            >
              {headCell.label}
              {orderKey === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
