import React, { Dispatch, useContext, useMemo } from "react";
import {
  CurrentActiveRouteContext,
  CurrentActiveRouteContextType,
  CurrentMultiLegVoyageContext,
  CurrentRoutesToCompareContext,
  CurrentRoutesToCompareContextType,
  CurrentSuggestedRouteContext,
  CurrentSuggestedRouteContextType,
  CurrentVesselContext,
  CurrentVesselUpdatesContext,
  CurrentVoyageLegContext,
} from "./contexts";
import { CurrentSessionReducerAction } from "./reducers";
import {
  CurrentSessionData,
  currentSessionDataDefaults,
  useCurrentSessionContextValue,
} from "./use-current-session";

export type CurrentSessionContextType = {
  currentSession: CurrentSessionData;
  currentSessionDispatch: Dispatch<CurrentSessionReducerAction>;
};

export const CurrentSessionContextDefaults: CurrentSessionContextType = {
  currentSession: currentSessionDataDefaults,
  currentSessionDispatch: () => null,
};

export const CurrentSessionContext = React.createContext<CurrentSessionContextType>(
  CurrentSessionContextDefaults
);

// TODO: consider using a state management library like Jotai or Zustand instead of these contexts

/**
 * The CurrentSessionProvider provides the current voyage, vessel, vessel updates, active route, suggested route, and routes to compare
 * to the rest of the app. It is the top level provider for the current session.
 *
 * The data is provided atomically for values that are interdependent.
 *
 * In practice, this means when the url's vesselUuid and voyageUuid change simultaneously, the vessel, voyage, multi-leg voyage, and suggested route
 * are updated together only after all have been fetched from the API.
 */
export const CurrentSessionProvider: React.FC<{}> = ({ children }) => {
  const {
    currentSession,
    currentSessionDispatch,
  } = useCurrentSessionContextValue();

  // provide more than just the data record for suggested routes
  const currentSuggestedRouteContextState = useMemo<CurrentSuggestedRouteContextType>(
    () => ({
      currentSessionIsLoading: currentSession.currentSessionIsLoading,
      suggestedRouteUuid: currentSession.suggestedRouteUuid,
      routeStoreObject: currentSession.dataRecords.suggestedRoute,
      routeSuggestion: currentSession.dataRecords.routeSuggestion,
    }),
    [
      currentSession.currentSessionIsLoading,
      currentSession.suggestedRouteUuid,
      currentSession.dataRecords.suggestedRoute,
      currentSession.dataRecords.routeSuggestion,
    ]
  );

  // provide more than just the data record for active routes
  const currentActiveRouteContextState = useMemo<CurrentActiveRouteContextType>(
    () => ({
      currentSessionIsLoading: currentSession.currentSessionIsLoading,
      activeRouteUuid: currentSession.activeRouteUuid,
      routeStoreObject: currentSession.dataRecords.activeRoute,
    }),
    [
      currentSession.activeRouteUuid,
      currentSession.currentSessionIsLoading,
      currentSession.dataRecords.activeRoute,
    ]
  );

  // provide more than just the data record for routes to compare
  const currentRoutesToCompareContextState = useMemo<CurrentRoutesToCompareContextType>(
    () => ({
      routesToCompare: currentSession.dataRecords.routesToCompare,
      // use string array so that changes in the route store do not cause this ref to change
      routeUuidsToCompare: currentSession.routeUuidsToCompare,
    }),
    [
      currentSession.routeUuidsToCompare,
      currentSession.dataRecords.routesToCompare,
    ]
  );

  const currenSessionValue = useMemo<CurrentSessionContextType>(
    () => ({
      currentSession,
      currentSessionDispatch,
    }),
    [currentSession, currentSessionDispatch]
  );

  return (
    <>
      {/* In some cases it is most convenient to access the entire session */}
      <CurrentSessionContext.Provider value={currenSessionValue}>
        {/* In other cases it is most convenient to access just a slice of it */}
        <CurrentVoyageLegContext.Provider
          value={currentSession.dataRecords.voyageLeg}
        >
          <CurrentMultiLegVoyageContext.Provider
            value={currentSession.dataRecords.multilegVoyage}
          >
            <CurrentVesselContext.Provider
              value={currentSession.dataRecords.vessel}
            >
              <CurrentVesselUpdatesContext.Provider
                value={currentSession.dataRecords.vesselUpdates}
              >
                <CurrentActiveRouteContext.Provider
                  value={currentActiveRouteContextState}
                >
                  <CurrentSuggestedRouteContext.Provider
                    value={currentSuggestedRouteContextState}
                  >
                    <CurrentRoutesToCompareContext.Provider
                      value={currentRoutesToCompareContextState}
                    >
                      {children}
                    </CurrentRoutesToCompareContext.Provider>
                  </CurrentSuggestedRouteContext.Provider>
                </CurrentActiveRouteContext.Provider>
              </CurrentVesselUpdatesContext.Provider>
            </CurrentVesselContext.Provider>
          </CurrentMultiLegVoyageContext.Provider>
        </CurrentVoyageLegContext.Provider>
      </CurrentSessionContext.Provider>
    </>
  );
};

export const useCurrentSession = (): CurrentSessionContextType => {
  return useContext(CurrentSessionContext);
};
