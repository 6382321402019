import { AuthenticationContext } from "contexts/AuthenticationContext";
import { isNil } from "lodash";
import { useContext, useMemo } from "react";

const BOOLEAN_FEATURE_FLAGS = [
  "allowEditCharterType",
  "allowSpeedEditing",
  "charterPartyDailySummary",
  "fullScreenNauticalCharts",
  "showCharterType",
  "showCiiTable",
  "showVpmErrorMetricsInPerformanceTab",
  "noonReportIngestionForm",
  "ignoreHoursSlrForNoonRepoMissingDataCalculation",
  "showNoonReportVisualizations",
  "showVesselPerformancePage",
  "showGuidancePage",
  "showCpReports",
  "allowSessionReplayRecording",
  "showSeakeepingInputAlert",
  "showVesselAlerts",
  "enableDrift",
  "showRouteExplorer",
  "allowSpeedControlMcrEditing",
] as const;

type BooleanFeatureFlagsType = typeof BOOLEAN_FEATURE_FLAGS[number];

export const useLDFlags = (): Record<BooleanFeatureFlagsType, boolean> => {
  const { wayfinderApiCurrentUser } = useContext(AuthenticationContext);

  const booleanFeatureFlags: Record<
    BooleanFeatureFlagsType,
    boolean
  > = useMemo(() => {
    const { featureFlags } = wayfinderApiCurrentUser ?? {};
    const allFeatureFlags = featureFlags as
      | Record<string, string | boolean>
      | undefined;
    const entries = BOOLEAN_FEATURE_FLAGS.map((flag) => {
      if (isNil(allFeatureFlags)) return [flag, false];
      const hasKey = flag in allFeatureFlags;
      const isBool = typeof allFeatureFlags[flag] === "boolean";
      const value = hasKey && isBool ? Boolean(allFeatureFlags[flag]) : false;
      if (!hasKey) {
        console.warn(`Feature flag "${flag}" does not exist in user object`);
      } else if (!isBool) {
        console.warn(`Feature flag "${flag}" is not a boolean value`);
      }
      return [flag, value];
    });
    return Object.fromEntries(entries) as Record<
      BooleanFeatureFlagsType,
      boolean
    >;
  }, [wayfinderApiCurrentUser]);

  return booleanFeatureFlags;
};
