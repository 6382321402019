import { useContext, useEffect, useRef } from "react";
import { SIMULATED_HISTORICAL_TIME_QUERY_PARAM } from "shared-hooks/use-wayfinder-url";
import { usePastRouteTypes } from "shared-hooks/use-past-route-types";
import { useCurrentUrl } from "helpers/navigation";
import AnalyticsContext from "contexts/Analytics";
import { DateTime } from "luxon";

import { useVoyagePlan } from "./use-voyage-plan";

// TODO fold this into the centralized redirects
// TODO add integration tests
// it redirects a past suggestion with no sim time to its time on load
// it redirects a non-past suggestion with a sim time to current forecast if there was no sim time on load
// it keeps a sim time on load
export function useHistoricalTimeRedirectEffect() {
  const {
    isPastSuggestionComparison,
    suggestionDateTime,
    isLoadingPastRoutes,
    isPastSuggestionComparisonOnLoad,
  } = usePastRouteTypes();

  const currentUrl = useCurrentUrl();

  const simulatedHistoricalTime = currentUrl.getSimulatedHistoricalTime();

  // the plan id in the params is the canonical representation
  // of a plan in the url, so that is where we get it from
  const voyagePlanUuid = currentUrl.getVoyagePlanUuidQueryParam();

  const isPublishedVoyagePlan = !!voyagePlanUuid;

  const isPlanWithHistoricalTime =
    isPublishedVoyagePlan && simulatedHistoricalTime;

  const { voyagePlan } = useVoyagePlan(voyagePlanUuid);
  const voyagePlanHistoricalTime = voyagePlan?.forecastInitTime;

  // if the url starts with a simulated historic time and is not a historic suggestion, keep the time for good
  // this enables demo and reporting workflows where out team needs to specify the time directly in the url at load time
  const { deepLink } = useContext(AnalyticsContext);
  const hasHistoricalTimeOnLoad = deepLink.urlSearchParams.has(
    SIMULATED_HISTORICAL_TIME_QUERY_PARAM
  );
  const hasPermanentHistoricalTime =
    hasHistoricalTimeOnLoad && isPastSuggestionComparisonOnLoad === false;

  // when the plan time changes, we want to set the simulated historical time in the url
  // put the non - triggering dep in a ref so the hook will not fire any time the url changes
  const currentUrlRef = useRef(currentUrl);
  currentUrlRef.current = currentUrl;
  useEffect(() => {
    const currentUrl = currentUrlRef.current;
    const simulatedHistoricalTime = currentUrl.getSimulatedHistoricalTime();
    if (voyagePlanHistoricalTime && !simulatedHistoricalTime) {
      currentUrl
        .withSimulatedHistoricalTime(voyagePlanHistoricalTime)
        .redirect();
      return;
    }
  }, [voyagePlanHistoricalTime]);

  useEffect(() => {
    const suggestionTimeMatchesForecast =
      simulatedHistoricalTime &&
      suggestionDateTime?.valueOf() ===
        DateTime.fromISO(simulatedHistoricalTime).valueOf();

    // check if we should leave the url alone
    if (
      isLoadingPastRoutes ||
      hasPermanentHistoricalTime ||
      suggestionTimeMatchesForecast ||
      isPublishedVoyagePlan
    ) {
      return;
    }

    // otherwise, set and unset the time when the url changes.

    // suggestions

    // if this is a historic suggestion, add the time param
    if (isPastSuggestionComparison && suggestionDateTime) {
      currentUrl
        .withSimulatedHistoricalTime(suggestionDateTime?.toUTC().toISO())
        .redirect();
      return;
    }

    // if this is not a historic suggestion, but there is still a time in the url, remove it
    if (simulatedHistoricalTime) {
      // otherwise remove it
      currentUrl.withSimulatedHistoricalTime(null).redirect();
      return;
    }
  }, [
    simulatedHistoricalTime,
    currentUrl,
    hasPermanentHistoricalTime,
    isLoadingPastRoutes,
    isPastSuggestionComparison,
    isPlanWithHistoricalTime,
    isPublishedVoyagePlan,
    suggestionDateTime,
    voyagePlan?.forecastInitTime,
  ]);
}
