import { Route as RouteToolbox, RtzJson } from "@sofarocean/route-toolbox";
import { WaypointLookup } from "contexts/RouteStoreContext/state-types";
import { isNil, keyBy, last, sum, sumBy } from "lodash";
import { DateTime, Duration, Interval } from "luxon";
import { SafetyWarningTypes } from "../components/routes/RoutePath";
import { RouteScoreOptions } from "../components/sidebar/RouteSummary/use-route-score-options";
import config, { fuelCarbonMultiplier, WeatherQuantities } from "../config";
import {
  CalculatedScheduleElement,
  CourseType,
  Route,
  SafetyWarningLevel,
  ScheduleElement,
  SimulatedRoute,
  SpeedType,
  Waypoint,
} from "../shared-types/RouteTypes";
import { consoleAndSentryError } from "./error-logging";
import {
  FormattedSpeedUnits,
  calculateNauticalMilesBetweenWaypoints,
  describeWaypointNearestTime,
  formatCost,
  formatDistanceNM,
  formatFuelTonnageMT,
  formatSpeed,
  formatTimeDuration,
  getLmtTimezone,
  getRoutingGuidanceScheduleElements,
  roundTimeDurationMinutes,
} from "./routes";
import { BaseOptionalNumericValue, WeatherValue } from "./simulation";
import {
  frequencyToPeriod,
  isNumber,
  kgToMetricTonnes,
  metersPerSecondToKnots,
} from "./units";
import {
  accumulateRouteAppealScores,
  normalizeRouteAppealScores,
} from "./weather";

/**
 *  These types represent the time, distance, and expenses that can be incurred on a voyage
 *
 *  Although Polaris computes these figures for Sofar routes, there is a possibility that a route could be
 *  added to the comparison view in the future without the expenses that Polaris computes. It is also possible
 *  that Polaris could unintentionally include bad or missing data in the schedule for these values.
 *
 *  Quantities that are essential to showing a comprehensible route summary in the comparison view are required.
 *  Other quantities that may or may not be present in the schedule are allowed to be undefined
 *  Formatted values are always required, and they contain a string that represents a missing value if the
 *  quantity they represent is undefined
 *
 */

export type RouteScoreMetrics = {
  adverseCurrents: number | undefined;
  favorableCurrents: number | undefined;
  maxWaveHeights: number | undefined;
  maxWindSpeeds: number | undefined;
  favorableWaveHeights: number | undefined;
  favorableWindSpeeds: number | undefined;
};

export type TimedVoyageGuidance = {
  rpm: number;
  pitch?: number;
  speedKts: number;
  fuelMt: number;
  durationMs?: number;
  durationHrs?: number;
  isDrifting?: boolean;
  driftStart?: DateTime;
  driftEnd?: DateTime;
  driftDuration?: Duration;
};

export type DailyVoyageGuidance = Record<
  string, // represents timestamp of the guidance within the day
  TimedVoyageGuidance | undefined
>;

export type WeatherStatistics = {
  interval: Interval;
  windSpeedKts: SpeedType | undefined;
  windDirection: CourseType | undefined;
  waveHeight: WeatherValue;
  waveDirection: WeatherValue;
  currentFactorAverage?: number;
};
export type ForecastDetails = {
  date: Date;
  rpm: number;
  speedKts: number;
  windSpeedKts: SpeedType | undefined;
  windDirection: CourseType | undefined;
  waveHeight: WeatherValue;
  waveDirection: WeatherValue;
};

export type NearFutureSafetyWarningItem = {
  startTime: Date;
  endTime: Date;
  type: SafetyWarningTypes;
  level: SafetyWarningLevel;
  significantWaveHeight: WeatherValue;
  windSpeed: WeatherValue;
  significantWaveDirection?: WeatherValue;
  wavePeriod?: WeatherValue;
  waveLength?: WeatherValue;
  waveSpeed?: WeatherValue;
  encounterPeriod?: WeatherValue;
};
export type NearFutureSafetyWarnings = {
  // current and future warnings
  current: NearFutureSafetyWarningItem[];
  next48: NearFutureSafetyWarningItem[];
};

type VoyageGuidance = {
  sendOutTime: DateTime;
  guidance: (DailyVoyageGuidance | undefined)[];
  waypoints: Waypoint[] | undefined;
  scheduleElements: (ScheduleElement | undefined)[] | undefined;
};

export type NearFutureMetrics = {
  safetyWarnings: NearFutureSafetyWarnings | undefined;
  dailyVoyageGuidance: (DailyVoyageGuidance | undefined)[]; // the guidance for the days in the time window
  weatherStatistics: WeatherStatistics[][];
  waypoints: Waypoint[] | undefined;
  scheduleElements: (ScheduleElement | undefined)[] | undefined;
  timezone: string;
  weatherIsComplete: boolean;
};
export type AbsoluteRouteScoreMetrics = RouteScoreMetrics;
export type RelativeRouteScoreMetrics = {
  differences: RouteScoreMetrics | undefined;
  ratios: RouteScoreMetrics | undefined;
  significantScores: Partial<RouteScoreMetrics> | undefined;
  routeAppealPhrase?: string | undefined;
};
export type SummaryMetrics = {
  nauticalMiles: number;
  ecaNauticalMiles: number | undefined;
  economicCost: number | undefined;
  fuelEconomicCost: number | undefined;
  ecaFuelEconomicCost: number | undefined;
  emissionsEconomicCost: number | undefined;
  opportunityEconomicCost: number | undefined; // this is actually not used, because we round the time and compute based on unit cost from route.routeInfo.extensions
  fuelMetricTonnage: number | undefined;
  ecaFuelMetricTonnage: number | undefined;
  emissionsCo2Mt: number | undefined;
  durationMs: number;
  ecaDurationMs: number | undefined;
  // route appeal
  scores: AbsoluteRouteScoreMetrics | undefined;
  // dashboard and email
  nearFuture: NearFutureMetrics | undefined;
};

type FuelSchema = {
  fuelMt: number | undefined;
  formattedFuel: string;
  fuelExpenseDollars: number | undefined;
  formattedFuelExpense: string;
  formattedFuelUnitCost: string;
};

type EmissionsSchema = {
  emissionsCo2Mt: number | undefined;
  formattedEmissions: string;
  emissionsExpenseDollars: number | undefined;
  formattedEmissionsExpense: string;
  formattedEmissionsUnitCost: string;
};

type FuelComparisonSchema = {
  fuelDifferenceMT: number | undefined;
  formattedFuelDifference: string;
  fuelExpenseSavingsDollars: number | undefined;
  formattedFuelExpenseSavings: string;
  formattedFuelExpenseLosses: string;
  formattedFuelUnitCost: string;
};

type EmissionsComparisonSchema = {
  emissionsDifferenceCo2MT: number | undefined;
  formattedEmissionsDifference: string;
  emissionsExpenseSavingsDollars: number | undefined;
  formattedEmissionsExpenseSavings: string;
  formattedEmissionsExpenseLosses: string;
  formattedEmissionsUnitCost: string;
};

export type AbsoluteSummary = {
  routeUuid: string;
  time: {
    // required because no route should have undefined eta
    eta: Date;
    etd?: Date;
    durationMs: number;
    formattedTime: string;
    timeExpenseDollars: number | undefined; // could be undefined if route is missing expense data
    formattedTimeExpense: string;
    formattedTimeUnitCost: string;
    ecaDurationMs: number | undefined; // could be undefined if route is missing timeInEcaMinutes
    formattedEcaTime: string;
  };
  distance: {
    // required because no route should have undefined distance
    nauticalMiles: number;
    formattedDistance: string;
    ecaNauticalMiles: number | undefined;
    formattedEcaDistance: string;
  };
  fuel: {
    standard: FuelSchema;
    eca: FuelSchema;
    isEcaFuelInUse: boolean | undefined;
    // if isEcaFuelInUse undefined or false, total would be the same as standard
    // otherwise it accounts both standard and eca in each field
    total: FuelSchema;
  };
  emissions: {
    eua: EmissionsSchema;
    euEtsStatus?: "none" | "half" | "full";
    formattedTotalEmissions: string;
    totalEmissionsCo2Mt: number | undefined;
  };
  voyage: {
    formattedVoyageExpenses: string;
    nearFuture?: NearFutureMetrics | undefined;
  };
  avgSpeedRemaining: {
    knots: number;
    formattedAvgSpeedRemaining: string;
  };
  scores: AbsoluteRouteScoreMetrics | undefined;
  routeStartTime: DateTime | undefined;
  routeFirstWaypoint: Waypoint | undefined;
};

export type RelativeSummary = {
  routeUuid: string;
  comparisonBasisRouteUuid: string;
  time: {
    timeDifferenceMs: number;
    formattedTimeDifference: string;
    ecaTimeDifferenceMs: number;
    formattedEcaTimeDifference: string;
    timeExpenseSavingsDollars: number | undefined;
    formattedTimeExpenseSavings: string;
    formattedTimeExpenseLosses: string;
    formattedTimeUnitCost: string;
  };
  distance: {
    distanceDifferenceNM: number;
    formattedDistanceDifference: string;
    ecaDistanceDifferenceNM: number;
    formattedEcaDistanceDifference: string;
  };
  fuel: {
    standard: FuelComparisonSchema;
    eca: FuelComparisonSchema;
    total: FuelComparisonSchema;
  };
  emissions: {
    eua: EmissionsComparisonSchema;
    emissionsDifferenceCo2MT: number | undefined;
    formattedEmissionsDifferenceCo2MT: string;
  };
  voyage: {
    voyageExpenseSavings: number | undefined;
    formattedVoyageExpenseSavings: string;
    formattedVoyageExpenseLosses: string;
  };
  scores: RelativeRouteScoreMetrics | undefined;
  routeStartTimeDifference: Duration | undefined;
  routeStartWaypointDifferenceNm: number | undefined;
};

export type RouteSummaryData = {
  routeUuid: string;
  routeName: string;
  routeDescription: string;
  absoluteSummary: AbsoluteSummary;
  nearFuture: NearFutureMetrics | undefined;
  routeEtdTimezone?: string | null;
  routeEtaTimezone?: string | null;
};

const roundToPointFive = (value: number) => Math.round(2 * value) / 2;

const ACCUMULTED_SCORE_REPORTING_THRESHOLD = 0.001;
const LABELS: Record<keyof AbsoluteRouteScoreMetrics, string> = {
  adverseCurrents: "adverse currents",
  favorableCurrents: "favorable currents",
  maxWaveHeights: "high waves",
  maxWindSpeeds: "high winds",
  favorableWaveHeights: "favorable waves",
  favorableWindSpeeds: "favorable winds",
};

export const hasWarning = (
  scheduleElement: CalculatedScheduleElement,
  type: SafetyWarningTypes,
  level: SafetyWarningLevel
) =>
  Boolean(
    scheduleElement?.extensions?.[type] &&
      scheduleElement?.extensions?.[type] === level
  );

/**
 * A time range when a safety warning is set along a route
 * After computation, should have two items, the start and end of the range
 * During computation, an open range can have only the first item defined
 */
export type SafteyWarningTimeRangeBound = {
  timestamp: Date;
  scheduleElement: CalculatedScheduleElement; // used for weather data details
  type: SafetyWarningTypes;
  level: SafetyWarningLevel;
};
export type SafetyWarningTimeRange =
  | [SafteyWarningTimeRangeBound, SafteyWarningTimeRangeBound] // closed range
  | [SafteyWarningTimeRangeBound]; // open range

/**
 * Given a set of schedule elements, find the start and end (ranges) of the safty warnings
 * @param scheduleElements
 * @returns
 */
export const getSafetyWarningTimeRanges = (
  scheduleElements: CalculatedScheduleElement[],
  type: SafetyWarningTypes,
  level: SafetyWarningLevel
) => {
  return scheduleElements?.reduce(
    (
      ranges: SafetyWarningTimeRange[],
      scheduleElement: CalculatedScheduleElement,
      currentIndex: number,
      scheduleElementsArray: typeof scheduleElements
    ) => {
      const lastRange =
        ranges.length > 0 ? ranges[ranges.length - 1] : undefined;
      let openRange = lastRange?.length === 1 ? lastRange : undefined;
      const elementHasWarning = hasWarning(scheduleElement, type, level);
      const time = scheduleElement.eta ?? scheduleElement.etd;
      if (!time) {
        consoleAndSentryError(
          `No time found while computing current safety warnings for schedule element ${scheduleElement.waypointId}`
        );
      } else {
        // if the element has a warning, add to start of range if there is no open range
        if (elementHasWarning) {
          if (!openRange) {
            openRange = [
              { timestamp: new Date(time), scheduleElement, type, level },
            ];
            ranges.push(openRange);
          }
        }
        // if there is an open range, and there is no warning on the next element, or there is no next element
        // end the current open range with this schedule element
        const nextScheduleElement = scheduleElementsArray[currentIndex + 1];
        if (
          openRange &&
          (!nextScheduleElement ||
            !hasWarning(nextScheduleElement, type, level))
        ) {
          openRange.push({
            timestamp: new Date(time),
            scheduleElement,
            type,
            level,
          });
        }
      }
      return ranges;
    },
    []
  );
};

export const getNearFutureSafetyWarnings = (
  safetyWarningTimeRanges: SafetyWarningTimeRange[],
  time: Date,
  durationMs = config.nearFutureSafetyWarningsTimeWindowDurationMs
) =>
  safetyWarningTimeRanges
    .sort((a, b) => a[0].timestamp.getTime() - b[0].timestamp.getTime())
    .reduce(
      (
        warnings: NearFutureSafetyWarnings,
        range: typeof safetyWarningTimeRanges[number]
      ) => {
        const [rangeStart, rangeEnd] = range;
        // because ranges can start and end on the same element, we extend our warning by step size
        const endTime = DateTime.fromJSDate(rangeEnd?.timestamp!)
          .plus(config.simulatorStepSizeMs)
          .toJSDate();
        // if the current time falls within the range, add a warning to the current set
        if (time >= rangeStart.timestamp && time <= endTime) {
          warnings.current.push({
            startTime: rangeStart.timestamp,
            endTime,
            type: rangeStart.type,
            level: rangeStart.level,
            significantWaveHeight:
              rangeStart.scheduleElement.extensions?.significantWaveHeight,
            windSpeed: rangeStart.scheduleElement.windSpeed,
            significantWaveDirection:
              rangeStart.scheduleElement.extensions?.meanDirection,
            wavePeriod: rangeStart.scheduleElement.extensions?.peakWaveFrequency
              ? frequencyToPeriod(
                  rangeStart.scheduleElement.extensions.peakWaveFrequency
                )
              : undefined,
            waveLength: rangeStart.scheduleElement.extensions?.waveLength,
            waveSpeed: rangeStart.scheduleElement.extensions?.waveSpeed,
            encounterPeriod:
              rangeStart.scheduleElement.extensions?.waveEncounterPeriod,
          });
        }
        // if the start of the range is 0-48 hours after the current time, then add it to the next 48
        if (
          time < rangeStart.timestamp &&
          rangeStart.timestamp.getTime() < time.getTime() + durationMs
        ) {
          warnings.next48.push({
            startTime: rangeStart.timestamp,
            endTime,
            type: rangeStart.type,
            level: rangeStart.level,
            significantWaveHeight:
              rangeStart.scheduleElement.extensions?.significantWaveHeight,
            windSpeed: rangeStart.scheduleElement.windSpeed,
            significantWaveDirection:
              rangeStart.scheduleElement.extensions?.meanDirection,
            wavePeriod: rangeStart.scheduleElement.extensions?.peakWaveFrequency
              ? frequencyToPeriod(
                  rangeStart.scheduleElement.extensions.peakWaveFrequency
                )
              : undefined,
            waveLength: rangeStart.scheduleElement.extensions?.waveLength,
            waveSpeed: rangeStart.scheduleElement.extensions?.waveSpeed,
            encounterPeriod:
              rangeStart.scheduleElement.extensions?.waveEncounterPeriod,
          });
        }
        return warnings;
      },
      {
        current: [],
        next48: [],
      } as NearFutureSafetyWarnings
    );

const closestScheduleElementToDateTime = (
  elements: CalculatedScheduleElement[],
  time: DateTime
): CalculatedScheduleElement => {
  let closestElement: CalculatedScheduleElement = elements[0];
  elements.forEach((element) => {
    const elementTime = element.eta ?? element.etd;
    if (elementTime) {
      const elementDateTime = DateTime.fromISO(elementTime);
      if (
        Math.abs(elementDateTime.diff(time).milliseconds) <
        Math.abs(
          DateTime.fromISO(closestElement.eta ?? closestElement.etd!).diff(time)
            .milliseconds
        )
      ) {
        closestElement = element;
      }
    }
  });
  return closestElement;
};

/**
 * Split up an array of schedule elements into chunks within time intervals,
 * adjusting the intervals to fit the schedule elements
 *
 * @param intervals
 * @param scheduleElements
 * @returns
 */
export const getScheduleElementsByTimeIntervals = (
  intervals: Interval[],
  scheduleElements: CalculatedScheduleElement[],
  routeUuid: string
): CalculatedScheduleElement[][] => {
  const adjustedIntervals: Interval[] = [];
  // filter out any schedule elements that don't have a time
  const filteredScheduleElements: CalculatedScheduleElement[] = scheduleElements.filter(
    (s) => {
      if (!s.eta && !s.etd) {
        consoleAndSentryError(
          `Schedule element with waypointId ${s.waypointId} in route ${routeUuid} has no time`
        );
        return false;
      }
      return true;
    }
  );

  let prevEnd: DateTime | undefined = undefined;
  intervals.forEach((interval) => {
    const startElement = closestScheduleElementToDateTime(
      filteredScheduleElements,
      interval.start!
    );
    let start = DateTime.fromISO(startElement.eta ?? startElement.etd!);
    // if we couldn't find an element *before* the interval end, leave the interval as is
    if (start >= interval.end! || (prevEnd && start <= prevEnd))
      start = interval.start!;
    adjustedIntervals.push(Interval.fromDateTimes(start, interval.end!));
    prevEnd = interval.end!;
  });

  return adjustedIntervals.map((interval) =>
    filteredScheduleElements.filter((s) =>
      interval.contains(DateTime.fromISO(s.eta ?? s.etd!))
    )
  );
};

/**
 * Get the time-weighted current factor, and total duration between 2 intervals
 * @param startTime
 * @param endTime
 * @param currentFactor
 * @returns
 */
const getTimeWeightedCurrentFactorDurationForInterval = (
  currentFactor: BaseOptionalNumericValue,
  interval: Interval
) => {
  // if current factor does not exist, set both to 0 to ignore them in average
  const duration =
    interval.start && interval.end && currentFactor
      ? interval.end.diff(interval.start).milliseconds
      : 0;

  const currentFactorTimeWeighted =
    currentFactor && duration ? currentFactor * duration : 0;

  return {
    currentFactorTimeWeighted,
    duration,
  };
};

/**
 * Calculated time-averaged current factor and accumulates
 * them across schedule items in interval
 * @param scheduleElementsForInterval
 * @param timeIntervalStart
 * @param timeIntervalEnd
 * @param nextIterationFirstCurrentFactor
 * @returns number
 */
const calculateCurrentFactorTimeWeightedAverageInInterval = (
  scheduleElementsForInterval: CalculatedScheduleElement[],
  timeInterval: Interval,
  nextIterationFirstCurrentFactor?: BaseOptionalNumericValue
) => {
  const currentFactorDurationTotals = scheduleElementsForInterval.length
    ? scheduleElementsForInterval.reduce(
        (acc, { eta, etd, extensions }, elementIndex) => {
          let intervalTotal;
          const previousElementInInterval =
            scheduleElementsForInterval[elementIndex - 1];

          // first waypoint or no current factor
          if (
            (!eta && !previousElementInInterval) ||
            !extensions?.currentFactor
          ) {
            return acc;
          }

          // first waypoint in interval, not first waypoint in route
          if (elementIndex === 0 && eta && timeInterval.start) {
            intervalTotal = getTimeWeightedCurrentFactorDurationForInterval(
              extensions.currentFactor,
              Interval.fromDateTimes(timeInterval.start, DateTime.fromISO(eta))
            );
            // middle or last waypoint
          } else if (previousElementInInterval.etd && eta) {
            intervalTotal = getTimeWeightedCurrentFactorDurationForInterval(
              extensions.currentFactor,
              Interval.fromISO(previousElementInInterval.etd + "/" + eta)
            );
          } else {
            intervalTotal = { currentFactorTimeWeighted: 0, duration: 0 };
          }

          // on the last waypoint, also add the remaining time weighted
          // against next interval's current factor
          if (
            elementIndex === scheduleElementsForInterval.length - 1 &&
            nextIterationFirstCurrentFactor &&
            timeInterval.end &&
            etd
          ) {
            const lastIntervalTotal = getTimeWeightedCurrentFactorDurationForInterval(
              nextIterationFirstCurrentFactor,
              Interval.fromDateTimes(DateTime.fromISO(etd), timeInterval.end)
            );

            intervalTotal.currentFactorTimeWeighted +=
              lastIntervalTotal.currentFactorTimeWeighted;
            intervalTotal.duration += lastIntervalTotal.duration;
          }

          return {
            currentFactorTimeWeighted:
              acc.currentFactorTimeWeighted +
              intervalTotal?.currentFactorTimeWeighted,
            duration: acc.duration + intervalTotal?.duration,
          };
        },
        {
          currentFactorTimeWeighted: 0,
          duration: 0,
        }
      )
    : undefined;

  // duration = 0 means there was no current information in the interval
  return currentFactorDurationTotals &&
    currentFactorDurationTotals.duration !== 0
    ? currentFactorDurationTotals.currentFactorTimeWeighted /
        currentFactorDurationTotals.duration
    : 0;
};

/**
 * Find the maximum values and their directions in the weather data associated with each time interval
 * @param scheduleElementsByIntervals
 * @param timeIntervals
 * @returns
 */
export const getWeatherStatisticsForTimeIntervals = (
  scheduleElementsByIntervals: CalculatedScheduleElement[][],
  timeIntervals: Interval[]
): WeatherStatistics[] =>
  scheduleElementsByIntervals.map(
    (scheduleElementsForInterval, intervalIndex) => {
      const interval: Interval | undefined = timeIntervals[intervalIndex];

      const maxWindElement:
        | CalculatedScheduleElement
        | undefined = scheduleElementsForInterval.length
        ? scheduleElementsForInterval?.reduce((acc, se) =>
            !acc ||
            !isNumber(acc?.windSpeed) ||
            (isNumber(se.windSpeed) && se.windSpeed > acc.windSpeed)
              ? se
              : acc
          )
        : undefined;

      const maxWaveElement = scheduleElementsForInterval.length
        ? scheduleElementsForInterval?.reduce((acc, se) =>
            isNumber(acc?.extensions?.significantWaveHeight)
              ? se.extensions &&
                acc?.extensions &&
                isNumber(se.extensions?.significantWaveHeight) &&
                se.extensions.significantWaveHeight >
                  acc.extensions.significantWaveHeight
                ? se
                : acc
              : se
          )
        : undefined;

      // record current iteration's leftover duration to pass into next interation
      const currentFactorAverage = calculateCurrentFactorTimeWeightedAverageInInterval(
        scheduleElementsForInterval,
        timeIntervals[intervalIndex],
        scheduleElementsByIntervals[intervalIndex + 1]?.[0]?.extensions
          ?.currentFactor
      );

      return {
        interval,
        windSpeedKts:
          maxWindElement?.windSpeed &&
          metersPerSecondToKnots(maxWindElement?.windSpeed),
        windDirection: maxWindElement?.windDirection,
        waveHeight: maxWaveElement?.extensions?.significantWaveHeight,
        waveDirection: maxWaveElement?.extensions?.meanDirection,
        currentFactorAverage,
      };
    }
  );

/**
 * Get the summary of guidance for each day in `scheduleElementsByDay`
 * Uses averages to even out potential slight differences between predicted and actual timezone
 * @param scheduleElementsByDay - the schedule elements that fall inside of each day
 * @returns
 */
export const getVoyageGuidance = (
  scheduleElementsByDay: CalculatedScheduleElement[][],
  simulatedRoute: Route,
  finalEta?: string | null
): (DailyVoyageGuidance | undefined)[] => {
  // drifts can overlap with days in any way, so we need
  // to collect the drift info for the whole drift and then
  // make it possible to show as ranges within separate days
  const drift = RouteToolbox.fromRtzJson(
    simulatedRoute as RtzJson
  ).describeDrift();
  const dailyVoyageGuidance: (DailyVoyageGuidance | undefined)[] = [];
  scheduleElementsByDay.forEach((scheduleElementsForDay, idx) => {
    if (!scheduleElementsForDay.length) {
      dailyVoyageGuidance.push(undefined);
      return;
    }

    const scheduledElementsByRangeStartTime: Record<
      string,
      CalculatedScheduleElement[]
    > = {};

    let currRpm: number | undefined = undefined;
    let rangeStartTime: string | undefined = undefined;
    scheduleElementsForDay.forEach((se, idx) => {
      if (idx === 0) return; // skip the first element
      // Since RTZ is represented as a "sail-to" route, if the RPM changes at a given element,
      // we start a new time window at the ETA/ETD of the prior element, which would have been
      // when the actual RPM change or drifting change took effect

      const prevElem = scheduleElementsForDay[idx - 1];
      const previousStartTime = prevElem.eta ?? prevElem.etd;

      // start a separate range for every change in rpm or drift
      const rpmIsNew = isNumber(se.rpm) && se.rpm !== currRpm;
      const { drifting: driftStateChanged } = prevElem?.extensions ?? {};
      if (rpmIsNew || driftStateChanged) {
        currRpm = se.rpm;
        rangeStartTime = previousStartTime;
      }
      if (rangeStartTime) {
        scheduledElementsByRangeStartTime[rangeStartTime] = [
          ...(scheduledElementsByRangeStartTime[rangeStartTime] ?? []),
          se,
        ];
      }
    });

    const withinDayVoyageGuidance: DailyVoyageGuidance = {};
    const guidanceRangeEntries = Object.entries(
      scheduledElementsByRangeStartTime
    );
    guidanceRangeEntries.forEach(([ts, scheduleElementsInRange], innerIdx) => {
      const pitches = scheduleElementsInRange
        .filter((se) => isNumber(se.pitch))
        .map((se) => se.pitch);
      const speeds = scheduleElementsInRange
        .filter((se) => isNumber(se.speed))
        .map((se) => se.speed);
      const fuelConsumptions = scheduleElementsInRange
        .filter((se) => isNumber(se.fuel) || isNumber(se.extensions?.ecaFuelKg))
        .map((se) => ({
          standardFuelKg: se.fuel ?? 0,
          ecaFuelKg: se.extensions?.ecaFuelKg ?? 0,
        }));
      const fuelMt = kgToMetricTonnes(
        sumBy(
          fuelConsumptions,
          ({ standardFuelKg, ecaFuelKg }) => standardFuelKg + ecaFuelKg
        )
      );
      const tsDateTime = DateTime.fromISO(ts);

      if (!speeds.length || !fuelConsumptions.length) {
        withinDayVoyageGuidance[ts] = undefined;
        return;
      }

      let nextTs: string | undefined = undefined;
      const nextGuidanceInDay = guidanceRangeEntries[innerIdx + 1];
      const nextDayFirstGuidance = scheduleElementsByDay[idx + 1]?.[0];
      if (nextGuidanceInDay) {
        nextTs = nextGuidanceInDay[0];
      } else if (nextDayFirstGuidance) {
        nextTs = nextDayFirstGuidance.eta ?? nextDayFirstGuidance.etd;
      } else if (finalEta) {
        // otherwise, we assume this is the final scheduled element in the route, so we compare
        // against the final ETA if available
        nextTs = finalEta;
      }
      const duration = !isNil(nextTs)
        ? DateTime.fromISO(nextTs).diff(DateTime.fromISO(ts))
        : undefined;
      const durationMs = duration?.as("milliseconds");
      const durationHrs = duration?.as("hours");
      const roundedDurationHrs = !isNil(durationHrs)
        ? Math.round(4 * durationHrs) / 4
        : undefined;

      const guidanceForDateTime: TimedVoyageGuidance = {
        // all RPMs should be the same for a given datetime
        rpm: Math.round(scheduleElementsInRange[0].rpm!),
        // final pitch, could be undefined
        pitch: pitches[pitches.length - 1],
        // average speed
        speedKts: roundToPointFive(
          sum(speeds) / scheduleElementsInRange.length
        ),
        fuelMt: roundToPointFive(fuelMt),
        durationMs,
        durationHrs: roundedDurationHrs,
      };

      const isDrifting =
        drift &&
        drift.startLocation.timestamp &&
        drift.endLocation.timestamp &&
        // check if the timestamp of the range is in the drift
        tsDateTime >= drift.startLocation.timestamp &&
        tsDateTime < drift.endLocation.timestamp;
      if (isDrifting) {
        guidanceForDateTime.isDrifting = isDrifting;
        guidanceForDateTime.driftStart = drift.startLocation.timestamp;
        guidanceForDateTime.driftEnd = drift.endLocation.timestamp;
        guidanceForDateTime.driftDuration = drift.duration;
      }

      withinDayVoyageGuidance[ts] = guidanceForDateTime;
    });
    dailyVoyageGuidance.push(withinDayVoyageGuidance);
  });
  return dailyVoyageGuidance;
};

export const getImmediateVoyageGuidance = (
  simulatedRoute: SimulatedRoute,
  nowWithVesselTimezone: DateTime
): VoyageGuidance => {
  // We want to give captains some time to react to our guidance, so even though we call this "immediate" it's not exactly now.
  // So, we round up to the nearest 2 hours, or 3 if we're past the current hour's 30 minute mark.
  const hoursToRoundUp = nowWithVesselTimezone.minute < 30 ? 2 : 3;
  const roundedToNextTwoHours = nowWithVesselTimezone
    .startOf("hour")
    .plus({ hours: hoursToRoundUp });
  const todayNoonLmt = nowWithVesselTimezone.startOf("day").plus({ hours: 12 });
  const upcomingNoonLmt =
    roundedToNextTwoHours.valueOf() > todayNoonLmt.valueOf()
      ? todayNoonLmt.plus({ day: 1 })
      : todayNoonLmt;
  const secondNoonLmt = upcomingNoonLmt.plus({ day: 1 });
  const thirdNoonLmt = secondNoonLmt.plus({ day: 1 });

  const intervals: Interval[] = [
    Interval.fromDateTimes(roundedToNextTwoHours, upcomingNoonLmt),
    Interval.fromDateTimes(upcomingNoonLmt, secondNoonLmt),
    Interval.fromDateTimes(secondNoonLmt, thirdNoonLmt),
  ];

  const immediateVoyageGuidance = getGuidanceWithinIntervals(
    simulatedRoute,
    intervals
  );

  return immediateVoyageGuidance;
};

const getGuidanceWithinIntervals = (
  simulatedRoute: SimulatedRoute,
  intervals: Interval[]
): VoyageGuidance => {
  const scheduleElements =
    simulatedRoute.schedules?.schedules?.[0]?.calculated?.scheduleElements;
  if (!scheduleElements)
    return {
      guidance: [],
      waypoints: [],
      scheduleElements: [],
      sendOutTime: intervals?.[0]?.start as DateTime,
    };

  const scheduleElementsByDay = getScheduleElementsByTimeIntervals(
    intervals,
    scheduleElements,
    simulatedRoute.extensions.uuid
  );

  const intervalEnd = last(intervals)?.end?.toUTC();
  const lastElement = last(scheduleElements);
  const finalElementTimeIso = lastElement?.eta ?? lastElement?.etd;
  const finalElementTime = !isNil(finalElementTimeIso)
    ? DateTime.fromISO(finalElementTimeIso)
    : null;
  const guidanceEndTime =
    !isNil(intervalEnd) && !isNil(finalElementTime)
      ? DateTime.min(intervalEnd, finalElementTime)
      : intervalEnd ?? finalElementTime;
  const dailyVoyageGuidance: (
    | DailyVoyageGuidance
    | undefined
  )[] = getVoyageGuidance(
    scheduleElementsByDay,
    simulatedRoute,
    guidanceEndTime?.toISO()
  );

  const guidanceStart = intervals[0].start as DateTime;
  const waypointsStartTime = guidanceStart;
  const waypointsEndTime = guidanceStart.plus(
    config.dailyRoutingEmailWaypointTimeWindowDurationMs
  );
  const guidanceScheduleElements =
    simulatedRoute.schedules?.schedules?.[0]?.manual?.scheduleElements;
  const waypointLookup = keyBy(
    simulatedRoute.waypoints.waypoints,
    (wp) => wp.id
  );
  const dailyRoutingWaypoints =
    guidanceScheduleElements &&
    getRoutingGuidanceScheduleElements(guidanceScheduleElements, {
      includeMultipleDriftPoints: false,
    })
      .filter((se) => {
        const timeString = se.eta ?? se.etd;
        const elementTime = timeString
          ? new Date(timeString).getTime()
          : undefined;
        return (
          elementTime &&
          waypointsStartTime.valueOf() <= elementTime &&
          waypointsEndTime.valueOf() >= elementTime
        );
      })
      .map((se) => waypointLookup[se.waypointId])
      .filter((w: Waypoint | undefined): w is Waypoint => Boolean(w));

  if (dailyVoyageGuidance.length !== scheduleElementsByDay.length) {
    consoleAndSentryError(
      `Daily voyage guidance is wrong length: ${dailyVoyageGuidance.length}`
    );
  }

  const scheduleElementLookup = keyBy(scheduleElements, (se) => se.waypointId);
  return {
    sendOutTime: guidanceStart,
    guidance: dailyVoyageGuidance,
    waypoints: dailyRoutingWaypoints,
    scheduleElements: dailyRoutingWaypoints?.map<ScheduleElement | undefined>(
      (w) => scheduleElementLookup[w.id]
    ),
  };
};

export const getDailyVoyageGuidance = (
  simulatedRoute: SimulatedRoute,
  now: DateTime,
  daysOfGuidance = 3
): VoyageGuidance => {
  const intervals: Interval[] = [];
  let prevDate = now.startOf("day").plus({ hours: 12 });
  for (let i = 0; i < daysOfGuidance; i++) {
    const nextDate = prevDate.plus({ day: 1 });
    intervals.push(Interval.fromDateTimes(prevDate, nextDate));
    prevDate = nextDate;
  }

  const dailyVoyageGuidance = getGuidanceWithinIntervals(
    simulatedRoute,
    intervals
  );

  return dailyVoyageGuidance;
};

export const getDailyWeatherStatistics = (
  simulatedRoute: SimulatedRoute,
  startingDay: DateTime
): WeatherStatistics[][] => {
  const dailyWeatherStartTimesAndIntervalHrs = [
    {
      startTime: startingDay,
      intervalHrs: 6,
    },
    {
      startTime: startingDay.plus({ day: 1 }),
      intervalHrs: 6,
    },
    {
      startTime: startingDay.plus({ day: 2 }),
      intervalHrs: 12,
    },
    {
      startTime: startingDay.plus({ day: 3 }),
      intervalHrs: 12,
    },
    {
      startTime: startingDay.plus({ day: 4 }),
      intervalHrs: 12,
    },
    {
      startTime: startingDay.plus({ day: 5 }),
      intervalHrs: 24,
    },
    {
      startTime: startingDay.plus({ day: 6 }),
      intervalHrs: 24,
    },
    {
      startTime: startingDay.plus({ day: 7 }),
      intervalHrs: 24,
    },
    {
      startTime: startingDay.plus({ day: 8 }),
      intervalHrs: 24,
    },
    {
      startTime: startingDay.plus({ day: 9 }),
      intervalHrs: 24,
    },
  ];

  const scheduleElements =
    simulatedRoute.schedules?.schedules?.[0]?.calculated?.scheduleElements;
  if (!scheduleElements) return [];

  const weatherStatistics: WeatherStatistics[][] = dailyWeatherStartTimesAndIntervalHrs.map(
    ({ startTime, intervalHrs }) => {
      const midnightLMTPlusSixHourIntervals: Interval[] = [
        Interval.fromDateTimes(startTime, startTime.plus({ hours: 6 })),
        Interval.fromDateTimes(
          startTime.plus({ hours: 6 }),
          startTime.plus({ hours: 12 })
        ),
        Interval.fromDateTimes(
          startTime.plus({ hours: 12 }),
          startTime.plus({ hours: 18 })
        ),
        Interval.fromDateTimes(
          startTime.plus({ hours: 18 }),
          startTime.plus({ hours: 24 })
        ),
      ];
      const midnightLMTPlusTwelveHourIntervals: Interval[] = [
        Interval.fromDateTimes(startTime, startTime.plus({ hours: 12 })),
        Interval.fromDateTimes(
          startTime.plus({ hours: 12 }),
          startTime.plus({ hours: 24 })
        ),
      ];
      const midnightLMTDailyIntervals: Interval[] = [
        Interval.fromDateTimes(startTime, startTime.plus({ hours: 24 })),
      ];
      const intervals: Interval[] =
        intervalHrs === 24
          ? midnightLMTDailyIntervals
          : intervalHrs === 12
          ? midnightLMTPlusTwelveHourIntervals
          : midnightLMTPlusSixHourIntervals;

      // inclusive?
      const scheduleElementsByIntervals = getScheduleElementsByTimeIntervals(
        intervals,
        scheduleElements,
        simulatedRoute.extensions.uuid
      );

      const weatherStatisticsForInterval = getWeatherStatisticsForTimeIntervals(
        scheduleElementsByIntervals,
        intervals
      );
      if (
        weatherStatisticsForInterval.length !==
        scheduleElementsByIntervals.length
      ) {
        console.error(
          `Weather maximums list starting at ${startTime.toISO()} is wrong length: ${
            weatherStatisticsForInterval.length
          }`
        );
      }
      return weatherStatisticsForInterval;
    }
  );

  if (weatherStatistics.length !== 10) {
    consoleAndSentryError(
      `Weather maximums day count is wrong length: ${weatherStatistics.length}`
    );
  }

  return weatherStatistics;
};

const getUpcomingSafetyWarnings = (
  simulatedRoute: SimulatedRoute,
  startTime: Date
): NearFutureSafetyWarnings | undefined => {
  const scheduleElements =
    simulatedRoute.schedules?.schedules?.[0]?.calculated?.scheduleElements;
  if (!scheduleElements) return;
  const safetyWarningTimeRanges = [
    "synchronousRollWarning" as const,
    "parametricRollWarning" as const,
    "highWaveWarning" as const,
    "broachingWarning" as const,
  ].flatMap((type) => [
    ...getSafetyWarningTimeRanges(scheduleElements, type, "low"),
    ...getSafetyWarningTimeRanges(scheduleElements, type, "high"),
  ]);
  // reduce the warning ranges to warnings that are current or in the next 48 hours
  const safetyWarnings = safetyWarningTimeRanges
    ? getNearFutureSafetyWarnings(safetyWarningTimeRanges, startTime)
    : undefined;

  return safetyWarnings;
};

const getFirstNoonLmtInRoute = (
  simulatedRoute: SimulatedRoute
): DateTime | undefined => {
  const scheduleElements =
    simulatedRoute.schedules?.schedules?.[0]?.calculated?.scheduleElements;
  if (!scheduleElements) return;

  const firstTimeNearNoonLmtInRoute = scheduleElements
    .map((se) => {
      const timeString = se.eta ?? se.etd;
      const waypoint = simulatedRoute.waypoints.waypoints.find(
        (wp) => wp.id === se.waypointId
      );
      if (waypoint && timeString) {
        const timezone = getLmtTimezone(waypoint.position.lon);
        return DateTime.fromISO(timeString).setZone(timezone);
      }
      return undefined;
    })
    .filter((time: DateTime | undefined): time is DateTime => Boolean(time))
    .reduce<DateTime | undefined>(
      (
        acc: DateTime | undefined,
        current: DateTime,
        index: number,
        array: DateTime[]
      ) => {
        const next = array[index + 1];
        if (!acc && next && current?.hour <= 12 && next.hour >= 12) {
          return current;
        }
        return acc;
      },
      undefined
    );
  const firstNoonLmtInRoute = firstTimeNearNoonLmtInRoute
    ?.startOf("day")
    .plus({ hours: 12 });

  return firstNoonLmtInRoute;
};

const getLastNoonLmtInRoute = (
  simulatedRoute: SimulatedRoute
): DateTime | undefined => {
  const scheduleElements =
    simulatedRoute.schedules?.schedules?.[0]?.calculated?.scheduleElements;
  if (!scheduleElements) return;

  const lastTimeNearNoonLmtInRoute = scheduleElements
    .map((se) => {
      const timeString = se.etd ?? se.eta;
      const waypoint = simulatedRoute.waypoints.waypoints.find(
        (wp) => wp.id === se.waypointId
      );
      if (waypoint && timeString) {
        const timezone = getLmtTimezone(waypoint.position.lon);
        return DateTime.fromISO(timeString).setZone(timezone);
      }
      return undefined;
    })
    .filter((time: DateTime | undefined): time is DateTime => Boolean(time))
    .reverse()
    .reduce<DateTime | undefined>(
      (
        acc: DateTime | undefined,
        current: DateTime,
        index: number,
        array: DateTime[]
      ) => {
        const next = array[index + 1];
        if (!acc && next && current?.hour <= 12 && next.hour >= 12) {
          return current;
        }
        return acc;
      },
      undefined
    );
  const lastNoonLmtInRoute = lastTimeNearNoonLmtInRoute
    ?.startOf("day")
    .plus({ hours: 12 });

  return lastNoonLmtInRoute;
};

/**
 * Compute the distance and cost metrics on the remining portion of the given route, starting from where the ship will be along the route at the given time.
 * @param simulatedRoute Route to use to calculate metrics. This simulatedRoute should have closely spaced simulated waypoints, so that the estimate is accurate.
 * @param time Time used to find waypoint, from which remaining simulatedRoute metrics are counted
 * @returns Metrics needed by summary component, or undefined if the start point is not in the route
 */
export function calculateRemainingMetricsFromTime(
  simulatedRoute: SimulatedRoute,
  waypointLookup: WaypointLookup["byId"],
  time: Date,
  forecastEndDates: Record<WeatherQuantities, Date> | undefined,
  routeScoreOptions: RouteScoreOptions,
  weatherIsComplete = true,
  timezone: string | undefined,
  simulatedTime?: Date
): SummaryMetrics | undefined {
  const startWaypoint = describeWaypointNearestTime(
    time,
    undefined,
    simulatedRoute,
    { clampTimeToRouteSchedule: true }
  );
  const startIndex = simulatedRoute.waypoints.waypoints.findIndex(
    (w) => startWaypoint && w.id === startWaypoint.waypointID
  );

  if (startIndex === -1) {
    console.error(`Warning: waypoint for time ${time.toISOString()} not found`);
    return undefined;
  } else {
    const result: SummaryMetrics = {
      nauticalMiles: 0,
      ecaNauticalMiles: 0,
      ecaDurationMs: 0,
      economicCost: 0,
      fuelEconomicCost: 0,
      ecaFuelEconomicCost: 0,
      emissionsEconomicCost: 0,
      emissionsCo2Mt: 0,
      durationMs: 0,
      opportunityEconomicCost: 0, // this is actually not used, because we round the time and compute based on unit cost from route.routeInfo.extensions
      fuelMetricTonnage: 0,
      ecaFuelMetricTonnage: 0,
      scores:
        weatherIsComplete && forecastEndDates
          ? {
              adverseCurrents: 0,
              favorableCurrents: 0,
              maxWaveHeights: 0,
              maxWindSpeeds: 0,
              favorableWaveHeights: 0,
              favorableWindSpeeds: 0,
            }
          : undefined,
      nearFuture: undefined,
    };

    const scheduleElements =
      simulatedRoute.schedules?.schedules?.[0]?.calculated?.scheduleElements;

    // if there are no schedule elements do not bother with computations
    if (!scheduleElements) return result;

    const arrivalScheduleElement =
      scheduleElements[scheduleElements.length - 1];
    const etaMs =
      arrivalScheduleElement.eta &&
      new Date(arrivalScheduleElement.eta).getTime();
    const durationMs = etaMs ? etaMs - time.getTime() : 0;
    result.durationMs = durationMs;
    if (!durationMs)
      consoleAndSentryError(
        Error(
          `Could not compute remaining time for route. uuid: ${simulatedRoute.extensions.uuid}`
        )
      );
    // dashboard and email
    if (timezone) {
      // the time used for these computations should be, at earliest, the first noon lmt in the route
      const firstNoonLMTInRoute = getFirstNoonLmtInRoute(simulatedRoute);
      const lastNoonLMTInRoute = getLastNoonLmtInRoute(simulatedRoute);
      const withinRouteTime = (time: Date) =>
        (!firstNoonLMTInRoute ||
          time.getTime() >= firstNoonLMTInRoute.valueOf()) &&
        (!lastNoonLMTInRoute || time.getTime() <= lastNoonLMTInRoute.valueOf());

      // this is the current time (as specified by the function param)
      // or the first noon lmt in the route, which ever is later
      const clampedTime =
        simulatedTime && withinRouteTime(simulatedTime)
          ? simulatedTime
          : withinRouteTime(time)
          ? time
          : firstNoonLMTInRoute?.toJSDate();

      if (!clampedTime) {
        console.error(
          `Could not compute clamped time for route. uuid: ${simulatedRoute.extensions.uuid}`
        );
        return result;
      }

      const clampedTimeInTimezone = DateTime.fromJSDate(clampedTime).setZone(
        timezone
      );
      const todayOrFirstDayInRouteNoonLMT = clampedTimeInTimezone
        .startOf("day")
        .plus({ hours: 12 });

      const todayOrFirstDayInRouteMidnightLMT = todayOrFirstDayInRouteNoonLMT.minus(
        { hours: 12 }
      );

      const voyageGuidance = getDailyVoyageGuidance(
        simulatedRoute,
        clampedTimeInTimezone
      );
      const weatherStatistics = getDailyWeatherStatistics(
        simulatedRoute,
        todayOrFirstDayInRouteMidnightLMT
      );
      const safetyWarnings = getUpcomingSafetyWarnings(simulatedRoute, time);

      result.nearFuture = {
        safetyWarnings,
        dailyVoyageGuidance: voyageGuidance?.guidance || [],
        weatherStatistics: weatherStatistics,
        waypoints: voyageGuidance?.waypoints,
        scheduleElements: voyageGuidance?.scheduleElements,
        timezone,
        weatherIsComplete,
      };
    }

    const { scores } = result;
    let scoreError: Error;
    // for each leg, take the average weather value of the start and end point, and accumulate the normalized route appeal scores
    scheduleElements.forEach(
      (
        scheduleElement: CalculatedScheduleElement,
        index: number,
        scheduleElements: CalculatedScheduleElement[]
      ) => {
        // do not include the first waypoint when computing cumulative cost and fuel metrics, because the metrics represent
        // the fuel and cost expended along the leg that leads to the waypoint, not away from it

        // do not include the first waypoint when computing weather scores because it is also based on the leg leading to the waypoint

        if (index <= startIndex) return;

        const waypoint = waypointLookup[scheduleElement.waypointId];
        const prevScheduleElement = scheduleElements[index - 1];
        const prevWaypoint = waypointLookup[prevScheduleElement.waypointId];
        const startTime = DateTime.fromISO(
          prevScheduleElement?.eta ?? prevScheduleElement?.etd!
        );
        const endTime = DateTime.fromISO(scheduleElement?.eta!);
        const legTimeElapsedMs =
          startTime && endTime
            ? endTime?.diff(startTime).as("milliseconds")
            : undefined;

        if (scores) {
          // route appeal depends on a route duration, an undefined value is not going to work
          if (isNil(legTimeElapsedMs)) {
            if (!scoreError) {
              for (const prop in scores) {
                scores[prop as keyof typeof scores] = undefined;
              }
              scoreError = Error(
                `Bad legTimeElapsedMs: ${legTimeElapsedMs} for schedule element referencing waypoint id ${scheduleElement.waypointId}`
              );
              console.error(scoreError);
            }
          } else {
            accumulateRouteAppealScores(
              scheduleElement,
              legTimeElapsedMs,
              routeScoreOptions,
              scores
            );
          }
        }

        // sum the distance to the end
        accumulateRouteDistance(prevWaypoint, waypoint, result);

        // sum eca time and distance
        accumulateEcaTimeAndDistance(scheduleElement, result);

        // sum the fuel and cost metrics
        accumulateRouteFuelAndCost(scheduleElement, result);
      }
    );

    // normalize scores by factoring out waypoint count
    if (scores && forecastEndDates) {
      normalizeRouteAppealScores(
        forecastEndDates,
        time,
        routeScoreOptions,
        scores
      );
    }

    return result;
  }
}

function accumulateRouteFuelAndCost(
  scheduleElement: CalculatedScheduleElement,
  result: SummaryMetrics
) {
  // undefined is not the same as 0 in the fuel metrics
  // undefined values will prevent the ui from showing the metric
  // 0 values will be shown as 0 fuel consumption
  // this is an important distinction when summarizing a route that
  // has not received fuel data from polaris!
  result.fuelMetricTonnage =
    typeof result.fuelMetricTonnage === "number" &&
    typeof scheduleElement?.fuel === "number"
      ? result.fuelMetricTonnage + kgToMetricTonnes(scheduleElement?.fuel)
      : undefined;
  result.fuelEconomicCost =
    typeof result.fuelEconomicCost === "number" &&
    typeof scheduleElement?.extensions?.fuelEconomicCost === "number"
      ? result.fuelEconomicCost + scheduleElement.extensions.fuelEconomicCost
      : undefined;
  result.opportunityEconomicCost =
    typeof result.opportunityEconomicCost === "number" &&
    typeof scheduleElement?.extensions?.opportunityEconomicCost === "number"
      ? result.opportunityEconomicCost +
        scheduleElement.extensions.opportunityEconomicCost
      : undefined;
  result.economicCost =
    typeof result.economicCost === "number" &&
    typeof scheduleElement?.extensions?.economicCost === "number"
      ? result.economicCost + scheduleElement.extensions.economicCost
      : undefined;
  // eca fuel metrics
  result.ecaFuelMetricTonnage =
    typeof result.ecaFuelMetricTonnage === "number" &&
    typeof scheduleElement?.extensions?.ecaFuelKg === "number"
      ? result.ecaFuelMetricTonnage +
        kgToMetricTonnes(scheduleElement.extensions.ecaFuelKg)
      : // this should be set to undefined if either:
        // - scheduleElement?.extensions?.ecaFuelKg is missing
        // - or a previous iteration has set result.ecaFuelMetricTonnage to
        //   undefined because scheduleElement?.extensions?.ecaFuelKg was missing then
        undefined;
  result.ecaFuelEconomicCost =
    typeof result.ecaFuelEconomicCost === "number" &&
    typeof scheduleElement?.extensions?.ecaFuelEconomicCost === "number"
      ? result.ecaFuelEconomicCost +
        scheduleElement.extensions.ecaFuelEconomicCost
      : // this should be set to undefined if either:
        // - scheduleElement?.extensions?.ecaFuelEconomicCost is missing
        // - or a previous iteration has set result.ecaFuelEconomicCost to
        //   undefined because scheduleElement?.extensions?.ecaFuelEconomicCost was missing then
        undefined;
  // emissions metrics
  // TODO(jordan): Confirm whether its expected/allowed for a segment to have `undefined` emissions
  result.emissionsCo2Mt = !isNil(result.emissionsCo2Mt)
    ? !isNil(scheduleElement?.extensions?.emissionsCo2Mt)
      ? result.emissionsCo2Mt + scheduleElement!.extensions!.emissionsCo2Mt
      : result.emissionsCo2Mt
    : undefined;
  result.emissionsEconomicCost = !isNil(result.emissionsEconomicCost)
    ? !isNil(scheduleElement?.extensions?.fuelEmissionsSurchargeEconomicCost)
      ? result.emissionsEconomicCost +
        scheduleElement!.extensions!.fuelEmissionsSurchargeEconomicCost
      : result.emissionsEconomicCost
    : undefined;
}

function accumulateEcaTimeAndDistance(
  scheduleElement: CalculatedScheduleElement,
  result: SummaryMetrics
) {
  result.ecaNauticalMiles =
    typeof result.ecaNauticalMiles === "number" &&
    typeof scheduleElement?.extensions?.distanceInEcaNm === "number"
      ? result.ecaNauticalMiles + scheduleElement.extensions.distanceInEcaNm
      : // this should be set to undefined if either:
        // - scheduleElement?.extensions?.distanceInEcaNm is missing
        // - or a previous iteration has set result.ecaNauticalMiles to
        //   undefined because scheduleElement?.extensions?.distanceInEcaNm was missing then
        undefined;

  result.ecaDurationMs =
    typeof result.ecaDurationMs === "number" &&
    typeof scheduleElement?.extensions?.timeInEcaMinutes === "number"
      ? result.ecaDurationMs + scheduleElement.extensions.timeInEcaMinutes
      : // this should be set to undefined if either:
        // - scheduleElement?.extensions?.timeInEcaMinutes is missing
        // - or a previous iteration has set result.ecaDurationMs to
        //   undefined because scheduleElement?.extensions?.timeInEcaMinutes was missing then
        undefined;
}

function accumulateRouteDistance(
  prevWaypoint: Waypoint | undefined,
  waypoint: Waypoint | undefined,
  result: SummaryMetrics
) {
  if (prevWaypoint && waypoint) {
    result.nauticalMiles += calculateNauticalMilesBetweenWaypoints(
      prevWaypoint,
      waypoint
    );
  } else {
    result.nauticalMiles = 0;
  }
}

export const computeAbsoluteRouteSummary = ({
  simulatedRouteMetrics,
  eta,
  etd,
  durationCalculationStartTime,
  route,
}: {
  simulatedRouteMetrics: SummaryMetrics;
  eta: Date;
  etd?: Date;
  durationCalculationStartTime: Date;
  route: SimulatedRoute;
}): AbsoluteSummary => {
  const {
    nauticalMiles,
    ecaNauticalMiles,
    ecaDurationMs: ecaDurationMinutes,
  } = simulatedRouteMetrics;
  const formattedDistance = nauticalMiles
    ? formatDistanceNM(nauticalMiles)
    : "--";
  const formattedEcaDistance = isNil(ecaNauticalMiles)
    ? "--"
    : formatDistanceNM(ecaNauticalMiles);
  const durationMs = eta.getTime() - durationCalculationStartTime.getTime();
  const roundedDurationMs = roundTimeDurationMinutes(durationMs);
  const roundedEcaDurationMs = isNil(ecaDurationMinutes)
    ? 0
    : roundTimeDurationMinutes(ecaDurationMinutes * 60000);
  const {
    fuelDollarsPerMt,
    ecaFuelDollarsPerMt,
    euEtsStatus,
    surchargeDollarsPerCo2Mt,
    timeCharterDollarsPerDay,
  } = route.routeInfo?.extensions ?? {};
  const msPerDay = 24 * 60 * 60 * 1000;
  const timeExpenseDollars = timeCharterDollarsPerDay
    ? // Don't use the rounded duration here because it can less accurate expense calculations down the line.
      (timeCharterDollarsPerDay * durationMs) / msPerDay
    : undefined;
  const voyageExpenses = // both time and fuel expenses are needed to calculate voyage expenses
    typeof timeExpenseDollars === "number" &&
    typeof simulatedRouteMetrics.fuelEconomicCost === "number"
      ? timeExpenseDollars +
        simulatedRouteMetrics.fuelEconomicCost +
        (simulatedRouteMetrics.ecaFuelEconomicCost ?? 0) + // there may be no eca fuel cost in a voyage
        (simulatedRouteMetrics.emissionsEconomicCost ?? 0) // there may be no emissions cost in a voyage
      : undefined;

  const avgSpeedRemainingKts = nauticalMiles / (durationMs / 1000 / 60 / 60);
  const formattedAvgRemainingSpeed = formatSpeed(
    avgSpeedRemainingKts,
    FormattedSpeedUnits.Knots
  );

  const schedules = route.schedules?.schedules?.[0];
  const firstScheduleElement =
    schedules?.calculated?.scheduleElements?.[0] ??
    schedules?.manual?.scheduleElements?.[0];
  const firstScheduleElementTime =
    firstScheduleElement?.etd ?? firstScheduleElement?.eta;
  const routeFirstWaypoint = route.waypoints.waypoints.find(
    (waypoint) => waypoint.id === firstScheduleElement?.waypointId
  );
  const routeStartTime = !isNil(firstScheduleElementTime)
    ? DateTime.fromISO(firstScheduleElementTime).toUTC()
    : undefined;

  if (simulatedRouteMetrics && roundedDurationMs) {
    let totalFuelMt;
    if (
      isNil(simulatedRouteMetrics.fuelMetricTonnage) &&
      isNil(simulatedRouteMetrics.ecaFuelMetricTonnage)
    ) {
      totalFuelMt = undefined;
    } else {
      totalFuelMt =
        (simulatedRouteMetrics.fuelMetricTonnage ?? 0) +
        (simulatedRouteMetrics.ecaFuelMetricTonnage ?? 0);
    }

    let totalFuelExpenseDollars;
    if (
      isNil(simulatedRouteMetrics.fuelEconomicCost) &&
      isNil(simulatedRouteMetrics.ecaFuelEconomicCost)
    ) {
      totalFuelExpenseDollars = undefined;
    } else {
      totalFuelExpenseDollars =
        (simulatedRouteMetrics.fuelEconomicCost ?? 0) +
        (simulatedRouteMetrics.ecaFuelEconomicCost ?? 0);
    }

    let isEcaFuelInUse = undefined;
    if (!isNil(simulatedRouteMetrics.ecaFuelMetricTonnage)) {
      if (simulatedRouteMetrics.ecaFuelMetricTonnage > 0) {
        isEcaFuelInUse = true;
      } else {
        isEcaFuelInUse = false;
      }
    }

    // this comes from the per-waypoint emissions data that we get from Polaris
    // emissions are only computed for EU ETS routes that have emissions cost per ton confugured
    const euaTotalEmissionsCo2Mt = isNil(simulatedRouteMetrics.emissionsCo2Mt)
      ? undefined
      : simulatedRouteMetrics.emissionsCo2Mt ?? 0;

    // so if they are missing, we must compute them
    // this matches the way polaris computes them today, but will need updates if that changes
    const totalEmissionsCo2Mt =
      totalFuelMt && Math.round(totalFuelMt * fuelCarbonMultiplier);

    const totalEmissionsExpenseDollars = isNil(
      simulatedRouteMetrics.emissionsEconomicCost
    )
      ? undefined
      : simulatedRouteMetrics.emissionsEconomicCost ?? 0;

    return {
      routeUuid: route.extensions.uuid,
      time: {
        eta,
        etd,
        durationMs: roundedDurationMs,
        timeExpenseDollars,
        formattedTime: formatTimeDuration(roundedDurationMs, true, true),
        formattedTimeExpense: formatCost(timeExpenseDollars),
        formattedTimeUnitCost: `${formatCost(timeCharterDollarsPerDay)}/day`,
        ecaDurationMs: roundedEcaDurationMs,
        formattedEcaTime: isNil(ecaDurationMinutes)
          ? "--"
          : formatTimeDuration(roundedEcaDurationMs, true, true),
      },
      fuel: {
        standard: {
          fuelMt: simulatedRouteMetrics.fuelMetricTonnage,
          fuelExpenseDollars: simulatedRouteMetrics.fuelEconomicCost,
          formattedFuel: formatFuelTonnageMT(
            simulatedRouteMetrics.fuelMetricTonnage
          ),
          formattedFuelExpense: formatCost(
            simulatedRouteMetrics.fuelEconomicCost
          ),
          formattedFuelUnitCost: `${formatCost(fuelDollarsPerMt)}/MT`,
        },
        eca: {
          fuelMt: simulatedRouteMetrics.ecaFuelMetricTonnage,
          fuelExpenseDollars: simulatedRouteMetrics.ecaFuelEconomicCost,
          formattedFuel: formatFuelTonnageMT(
            simulatedRouteMetrics.ecaFuelMetricTonnage
          ),
          formattedFuelExpense: formatCost(
            simulatedRouteMetrics.ecaFuelEconomicCost
          ),
          formattedFuelUnitCost: `${formatCost(ecaFuelDollarsPerMt)}/MT`,
        },
        total: {
          fuelMt: totalFuelMt,
          fuelExpenseDollars: totalFuelExpenseDollars,
          formattedFuel: formatFuelTonnageMT(totalFuelMt),
          formattedFuelExpense: formatCost(totalFuelExpenseDollars),
          formattedFuelUnitCost: `${formatCost(fuelDollarsPerMt)}/MT`, // equals to formattedFuelUnitCost for now
        },
        isEcaFuelInUse,
      },
      emissions: {
        eua: {
          emissionsCo2Mt: euaTotalEmissionsCo2Mt,
          // NOTE(jordan): We use the same /MT formatting for emissions even though it's technically Co2/MT
          formattedEmissions: formatFuelTonnageMT(euaTotalEmissionsCo2Mt),
          emissionsExpenseDollars: totalEmissionsExpenseDollars,
          formattedEmissionsExpense: formatCost(totalEmissionsExpenseDollars),
          formattedEmissionsUnitCost: `${formatCost(
            surchargeDollarsPerCo2Mt
          )}/MT CO\u2082`,
        },
        euEtsStatus,

        // the euaTotalEmissionsCo2Mt value can be missing, so we compute it and add it here as well
        totalEmissionsCo2Mt,
        formattedTotalEmissions: totalEmissionsCo2Mt
          ? Math.round(totalEmissionsCo2Mt) + " MT"
          : "--",
      },
      distance: {
        nauticalMiles,
        formattedDistance,
        ecaNauticalMiles,
        formattedEcaDistance,
      },
      avgSpeedRemaining: {
        knots: roundToPointFive(avgSpeedRemainingKts),
        formattedAvgSpeedRemaining: formattedAvgRemainingSpeed,
      },
      voyage: {
        formattedVoyageExpenses: formatCost(voyageExpenses),
        nearFuture: simulatedRouteMetrics.nearFuture,
      },
      scores: simulatedRouteMetrics.scores
        ? {
            ...simulatedRouteMetrics.scores,
          }
        : undefined,
      routeStartTime,
      routeFirstWaypoint,
    };
  }
  return {
    routeUuid: route.extensions.uuid,
    time: {
      // required because no route should have undefined eta
      eta,
      durationMs: roundedDurationMs,
      timeExpenseDollars: undefined, // could be undefined if route is missing expense data
      formattedTime: "--",
      formattedTimeExpense: formatCost(undefined),
      formattedTimeUnitCost: formatCost(undefined),
      ecaDurationMs: roundedEcaDurationMs,
      formattedEcaTime: "--",
    },
    fuel: {
      standard: {
        fuelMt: undefined,
        fuelExpenseDollars: undefined,
        formattedFuel: "--",
        formattedFuelExpense: formatCost(undefined),
        formattedFuelUnitCost: `${formatCost(undefined)}/MT`,
      },
      eca: {
        fuelMt: undefined,
        fuelExpenseDollars: undefined,
        formattedFuel: "--",
        formattedFuelExpense: formatCost(undefined),
        formattedFuelUnitCost: `${formatCost(undefined)}/MT`,
      },
      total: {
        fuelMt: undefined,
        fuelExpenseDollars: undefined,
        formattedFuel: "--",
        formattedFuelExpense: formatCost(undefined),
        formattedFuelUnitCost: `${formatCost(undefined)}/MT`,
      },
      isEcaFuelInUse: undefined,
    },
    emissions: {
      eua: {
        emissionsCo2Mt: undefined,
        formattedEmissions: "--",
        emissionsExpenseDollars: undefined,
        formattedEmissionsExpense: formatCost(undefined),
        formattedEmissionsUnitCost: `${formatCost(undefined)}/MT CO\u2082`,
      },
      euEtsStatus: "none",
      totalEmissionsCo2Mt: undefined,
      formattedTotalEmissions: "--",
    },
    distance: {
      // required because no route should have undefined distance
      nauticalMiles,
      formattedDistance,
      ecaNauticalMiles,
      formattedEcaDistance,
    },
    avgSpeedRemaining: {
      knots: roundToPointFive(avgSpeedRemainingKts),
      formattedAvgSpeedRemaining: formattedAvgRemainingSpeed,
    },
    voyage: {
      formattedVoyageExpenses: formatCost(undefined),
    },
    scores: simulatedRouteMetrics.scores
      ? {
          ...simulatedRouteMetrics.scores,
        }
      : undefined,
    routeStartTime,
    routeFirstWaypoint,
  };
};

export const NO_ROUTE_ADVANTAGE_PHRASE = "No significant route advantage.";

/*
 * Compute relative route summary metrics, given a route summary and a comparison basis summary
 */
export const computeRelativeRouteSummary = ({
  absoluteSummary,
  comparisonBasisAbsoluteSummary,
  options,
}: {
  absoluteSummary: AbsoluteSummary;
  comparisonBasisAbsoluteSummary: AbsoluteSummary;
  options: RouteScoreOptions;
}): RelativeSummary | undefined => {
  const {
    trivialComparisonBasisThreshold,
    shorterDistanceReportingMinimumNM,
    earlierArrivalReportingMinimumHr,
    lowerConsumptionReportingMinimumMT,
  } = options;

  // Note: for all cost values, when the comparison basis has a greater cost,
  // the savings should be positive and the losses should be negative.
  // This is why we subtract absoluteSummary from comparisonBasisAbsoluteSummary.

  // In some cases like fuel difference, this notion of savings and losses is reversed, because
  // the ui shows the actual fuel difference, not a notion of savings or losses.

  // relative fuel cost
  const fuelDifferenceMT =
    typeof comparisonBasisAbsoluteSummary.fuel.standard.fuelMt === "number" &&
    typeof absoluteSummary.fuel.standard.fuelMt === "number"
      ? comparisonBasisAbsoluteSummary.fuel.standard.fuelMt -
        absoluteSummary.fuel.standard.fuelMt
      : undefined;
  const formattedFuelDifference =
    typeof fuelDifferenceMT === "number"
      ? // the opposite sign for the amount makes more sense intuitively
        `${formatFuelTonnageMT(-fuelDifferenceMT, "exceptZero")}`
      : "--";
  const fuelExpenseSavingsDollars =
    typeof comparisonBasisAbsoluteSummary.fuel.standard.fuelExpenseDollars ===
      "number" &&
    typeof absoluteSummary.fuel.standard.fuelExpenseDollars === "number"
      ? comparisonBasisAbsoluteSummary.fuel.standard.fuelExpenseDollars -
        absoluteSummary.fuel.standard.fuelExpenseDollars
      : undefined;

  const ecaFuelDifferenceMT =
    typeof comparisonBasisAbsoluteSummary.fuel.eca.fuelMt === "number" &&
    typeof absoluteSummary.fuel.eca.fuelMt === "number"
      ? comparisonBasisAbsoluteSummary.fuel.eca.fuelMt -
        absoluteSummary.fuel.eca.fuelMt
      : undefined;
  const formattedEcaFuelDifference =
    typeof ecaFuelDifferenceMT === "number"
      ? // the opposite sign for the amount makes more sense intuitively
        `${formatFuelTonnageMT(-ecaFuelDifferenceMT, "exceptZero")}`
      : "--";

  const ecaFuelExpenseSavingsDollars =
    typeof comparisonBasisAbsoluteSummary.fuel.eca.fuelExpenseDollars ===
      "number" &&
    typeof absoluteSummary.fuel.eca.fuelExpenseDollars === "number"
      ? comparisonBasisAbsoluteSummary.fuel.eca.fuelExpenseDollars -
        absoluteSummary.fuel.eca.fuelExpenseDollars
      : undefined;

  const totalFuelDifferenceMT =
    typeof comparisonBasisAbsoluteSummary.fuel.total.fuelMt === "number" ||
    typeof absoluteSummary.fuel.total.fuelMt === "number"
      ? (comparisonBasisAbsoluteSummary.fuel.total.fuelMt ?? 0) -
        (absoluteSummary.fuel.total.fuelMt ?? 0)
      : undefined;

  const formattedTotalFuelDifference =
    typeof totalFuelDifferenceMT === "number"
      ? // the opposite sign for the amount makes more sense intuitively
        `${formatFuelTonnageMT(-totalFuelDifferenceMT, "exceptZero")}`
      : "--";

  const totalFuelExpenseSavingsDollars =
    typeof comparisonBasisAbsoluteSummary.fuel.total.fuelExpenseDollars ===
      "number" &&
    typeof absoluteSummary.fuel.total.fuelExpenseDollars === "number"
      ? comparisonBasisAbsoluteSummary.fuel.total.fuelExpenseDollars -
        absoluteSummary.fuel.total.fuelExpenseDollars
      : undefined;

  // relative emissions cost
  const euaEmissionsDifferenceCo2MT =
    !isNil(comparisonBasisAbsoluteSummary.emissions.eua.emissionsCo2Mt) &&
    !isNil(absoluteSummary.emissions.eua.emissionsCo2Mt)
      ? comparisonBasisAbsoluteSummary.emissions.eua.emissionsCo2Mt -
        absoluteSummary.emissions.eua.emissionsCo2Mt
      : undefined;

  const emissionsDifferenceCo2MT =
    !isNil(comparisonBasisAbsoluteSummary.emissions.totalEmissionsCo2Mt) &&
    !isNil(absoluteSummary.emissions.totalEmissionsCo2Mt)
      ? comparisonBasisAbsoluteSummary.emissions.totalEmissionsCo2Mt -
        absoluteSummary.emissions.totalEmissionsCo2Mt
      : undefined;

  const formattedEuaEmissionsDifference = !isNil(euaEmissionsDifferenceCo2MT)
    ? // the opposite sign for the amount makes more sense intuitively
      `${formatFuelTonnageMT(-euaEmissionsDifferenceCo2MT, "exceptZero")}`
    : "--";

  const formattedEmissionsDifferenceCo2MT = !isNil(emissionsDifferenceCo2MT)
    ? // the opposite sign for the amount makes more sense intuitively
      `${formatFuelTonnageMT(-emissionsDifferenceCo2MT, "exceptZero")}`
    : "--";

  const euaEmissionsExpenseSavingsDollars =
    !isNil(
      comparisonBasisAbsoluteSummary.emissions.eua.emissionsExpenseDollars
    ) && !isNil(absoluteSummary.emissions.eua.emissionsExpenseDollars)
      ? comparisonBasisAbsoluteSummary.emissions.eua.emissionsExpenseDollars -
        absoluteSummary.emissions.eua.emissionsExpenseDollars
      : undefined;

  // relative time expense
  const timeDifferenceMs =
    comparisonBasisAbsoluteSummary.time.eta.getTime() -
    absoluteSummary.time.eta.getTime();
  // the opposite sign shown for the amount makes more sense intuitively
  const formattedTimeDifference = formatTimeDuration(
    -timeDifferenceMs,
    false,
    true
  );

  const ecaTimeDifferenceMs =
    (comparisonBasisAbsoluteSummary.time.ecaDurationMs ?? 0) -
    (absoluteSummary.time.ecaDurationMs ?? 0);
  const formattedEcaTimeDifference = formatTimeDuration(
    -ecaTimeDifferenceMs,
    false,
    true
  );

  const timeExpenseSavingsDollars =
    typeof comparisonBasisAbsoluteSummary.time.timeExpenseDollars ===
      "number" && typeof absoluteSummary.time.timeExpenseDollars === "number"
      ? comparisonBasisAbsoluteSummary.time.timeExpenseDollars -
        absoluteSummary.time.timeExpenseDollars
      : undefined;

  // entire voyage
  const voyageExpenseSavings =
    typeof fuelExpenseSavingsDollars === "number" ||
    typeof ecaFuelExpenseSavingsDollars === "number" ||
    typeof timeExpenseSavingsDollars === "number" ||
    typeof euaEmissionsExpenseSavingsDollars === "number"
      ? (fuelExpenseSavingsDollars ?? 0) +
        (ecaFuelExpenseSavingsDollars ?? 0) +
        (timeExpenseSavingsDollars ?? 0) +
        (euaEmissionsExpenseSavingsDollars ?? 0)
      : undefined;

  const distanceDifferenceNM =
    comparisonBasisAbsoluteSummary.distance.nauticalMiles -
    absoluteSummary.distance.nauticalMiles;

  const ecaDistanceDifferenceNM =
    (comparisonBasisAbsoluteSummary.distance.ecaNauticalMiles ?? 0) -
    (absoluteSummary.distance.ecaNauticalMiles ?? 0);

  const formattedDistanceDifference =
    // the opposite sign for the amount makes more sense intuitively
    `${formatDistanceNM(-distanceDifferenceNM, "exceptZero")}`;
  const formattedEcaDistanceDifference =
    // the opposite sign for the amount makes more sense intuitively
    `${formatDistanceNM(-ecaDistanceDifferenceNM, "exceptZero")}`;

  const ratios = !absoluteSummary.scores
    ? undefined
    : (Object.fromEntries(
        Object.entries(absoluteSummary.scores).map(([key, absScore]) => {
          const comparisonBasisScore =
            comparisonBasisAbsoluteSummary?.scores?.[
              key as keyof AbsoluteRouteScoreMetrics
            ]; // cast is safe because it comes from entries
          if (
            comparisonBasisScore === 0 &&
            isNumber(absScore) &&
            absScore > ACCUMULTED_SCORE_REPORTING_THRESHOLD
          )
            return [key, Infinity];
          const ratio =
            isNumber(comparisonBasisScore) &&
            comparisonBasisScore > 0 &&
            isNumber(absScore)
              ? absScore / comparisonBasisScore
              : undefined;
          // if the comparison basis is already tiny, then there is no value in reporting that another is smaller than it.
          const comparisonIsTrivial =
            !isNumber(ratio) ||
            !isNumber(comparisonBasisScore) ||
            (comparisonBasisScore < trivialComparisonBasisThreshold &&
              ratio < 1);
          return [key, comparisonIsTrivial ? undefined : ratio];
        })
      ) as RouteScoreMetrics);

  const differences = !absoluteSummary.scores
    ? undefined
    : (Object.fromEntries(
        Object.entries(absoluteSummary.scores).map(([key, absSummaryValue]) => {
          const comparisonValue =
            comparisonBasisAbsoluteSummary?.scores?.[
              key as keyof typeof absoluteSummary.scores
            ]; // cast is safe because it comes from entries
          return [
            key,
            isNumber(absSummaryValue) && isNumber(comparisonValue)
              ? absSummaryValue - comparisonValue
              : undefined,
          ];
        })
      ) as RouteScoreMetrics);

  const significantScores:
    | Partial<RouteScoreMetrics>
    | undefined = !absoluteSummary.scores
    ? undefined
    : Object.fromEntries(
        Object.entries(absoluteSummary.scores)
          .map(([key, value]) => {
            const ratio = ratios?.[key as keyof RouteScoreMetrics];
            const difference = differences?.[key as keyof RouteScoreMetrics];
            const isCurrents =
              key === "adverseCurrents" || key === "favorableCurrents";
            const lesserWord =
              key === "adverseWeather" || key === "favorableWeather"
                ? "Less "
                : "Fewer ";
            const highlight =
              options.useAbsoluteLinearCurrentScores &&
              isNumber(difference) &&
              isCurrents
                ? Math.abs(difference) > options.absoluteCurrentScoreThreshold
                : isNumber(ratio) &&
                  (ratio > options.minRatioForReportingLargerRouteScore ||
                    ratio < options.maxRatioForReportingSmallerRouteScore);

            const quantifierWord =
              options.useAbsoluteLinearCurrentScores &&
              isNumber(difference) &&
              isCurrents
                ? difference < 0
                  ? "Fewer "
                  : difference > 0
                  ? "More "
                  : ""
                : isNumber(ratio)
                ? ratio < 1
                  ? lesserWord
                  : ratio > 1
                  ? "More "
                  : ""
                : "";

            return highlight
              ? [
                  key,
                  `${quantifierWord} ${LABELS[key as keyof RouteScoreMetrics]}`,
                ]
              : [key, undefined];
          })
          .filter((e) => e[1])
      );

  /**
   * https://www.notion.so/sofarocean/Route-Appeal-2212f54f97d741499b6c149f17a6270b
   *
   * Tier 1 (highly compelling)
   * - Shorter distance and lower consumption
   * - Shorter distance and earlier arrival
   * - Lower consumption and earlier arrival
   *
   * Tier 2 (moderately compelling)
   * - Shorter distance
   * - Lower consumption
   * - Earlier arrival
   */

  // TODO(jordan): Accomodate emissions differences here to reflect 'Better emissions' route state?
  const shorterDistance =
    distanceDifferenceNM > shorterDistanceReportingMinimumNM;
  const earlierArrival =
    timeDifferenceMs > 1000 * 60 * 60 * earlierArrivalReportingMinimumHr;

  const lowerConsumption = totalFuelDifferenceMT
    ? totalFuelDifferenceMT > lowerConsumptionReportingMinimumMT
    : undefined;

  let routeRelatedPhrase = null;
  if (shorterDistance && lowerConsumption) {
    routeRelatedPhrase = "Shorter distance and lower consumption";
  } else if (shorterDistance && earlierArrival) {
    routeRelatedPhrase = "Shorter distance and earlier arrival";
  } else if (lowerConsumption && earlierArrival) {
    routeRelatedPhrase = "Lower consumption and earlier arrival";
  } else if (shorterDistance) {
    routeRelatedPhrase = "Shorter distance";
  } else if (lowerConsumption) {
    routeRelatedPhrase = "Lower consumption";
  } else if (earlierArrival) {
    routeRelatedPhrase = "Earlier arrival";
  }
  /**
   * Tier 1 (highly compelling)
   *  - Avoids adverse weather in the forecast
   *  - Avoids adverse currents in the forecast
   *  - Favorable weather expected in the forecast
   *  - Favorable currents expected in the forecast
   */
  let weatherRelatedPhrase = null;
  const reportAdverseWinds =
    differences &&
    differences.maxWindSpeeds &&
    differences.maxWindSpeeds < 0 &&
    significantScores?.maxWindSpeeds;
  const reportAdverseWaves =
    differences &&
    differences.maxWaveHeights &&
    differences.maxWaveHeights < 0 &&
    significantScores?.maxWaveHeights;
  const reportFavorableWinds =
    differences &&
    differences.favorableWindSpeeds &&
    differences.favorableWindSpeeds > 0 &&
    significantScores?.maxWindSpeeds;
  const reportFavorableWaves =
    differences &&
    differences.favorableWaveHeights &&
    differences.favorableWaveHeights > 0 &&
    significantScores?.favorableWaveHeights;
  if (reportAdverseWaves && reportAdverseWinds) {
    weatherRelatedPhrase = "Avoids adverse winds and waves in the forecast";
  } else if (reportAdverseWinds) {
    weatherRelatedPhrase = "Avoids adverse winds in the forecast";
  } else if (reportAdverseWaves) {
    weatherRelatedPhrase = "Avoids adverse waves in the forecast";
  } else if (
    differences &&
    differences.adverseCurrents &&
    differences.adverseCurrents < 0 &&
    significantScores?.adverseCurrents
  ) {
    weatherRelatedPhrase = "Avoids adverse currents in the forecast";
  } else if (reportFavorableWaves && reportFavorableWinds) {
    weatherRelatedPhrase = "Favorable winds and waves expected";
  } else if (reportFavorableWinds) {
    weatherRelatedPhrase = "Favorable winds expected";
  } else if (reportFavorableWaves) {
    weatherRelatedPhrase = "Favorable waves expected";
  } else if (
    differences &&
    differences.favorableCurrents &&
    differences.favorableCurrents > 0 &&
    significantScores?.favorableCurrents
  ) {
    weatherRelatedPhrase = "Favorable currents expected in the forecast";
  } else if (
    significantScores &&
    Object.keys(significantScores).length === 0 &&
    routeRelatedPhrase
  ) {
    weatherRelatedPhrase = "Comparable weather forecasted";
  }

  const routeAppealPhrase =
    routeRelatedPhrase && weatherRelatedPhrase
      ? `${routeRelatedPhrase}; ${weatherRelatedPhrase.toLowerCase()}.`
      : !routeRelatedPhrase && weatherRelatedPhrase
      ? `${weatherRelatedPhrase}.`
      : !weatherRelatedPhrase && routeRelatedPhrase
      ? `${routeRelatedPhrase}.`
      : NO_ROUTE_ADVANTAGE_PHRASE;

  return {
    routeUuid: absoluteSummary.routeUuid,
    comparisonBasisRouteUuid: comparisonBasisAbsoluteSummary.routeUuid,
    time: {
      timeDifferenceMs,
      timeExpenseSavingsDollars,
      formattedTimeDifference,
      ecaTimeDifferenceMs,
      formattedEcaTimeDifference,
      formattedTimeExpenseSavings: formatCost(timeExpenseSavingsDollars),
      formattedTimeExpenseLosses: formatCost(
        timeExpenseSavingsDollars && -timeExpenseSavingsDollars,
        "exceptZero"
      ),
      // unit costs should be the same between the two routes
      formattedTimeUnitCost: absoluteSummary.time.formattedTimeUnitCost,
    },
    distance: {
      distanceDifferenceNM,
      formattedDistanceDifference,
      ecaDistanceDifferenceNM,
      formattedEcaDistanceDifference,
    },
    fuel: {
      standard: {
        fuelDifferenceMT,
        fuelExpenseSavingsDollars,
        formattedFuelDifference,
        formattedFuelExpenseSavings: formatCost(
          fuelExpenseSavingsDollars,
          "exceptZero"
        ),
        formattedFuelExpenseLosses: formatCost(
          fuelExpenseSavingsDollars && -fuelExpenseSavingsDollars,
          "exceptZero"
        ),
        formattedFuelUnitCost:
          absoluteSummary.fuel.standard.formattedFuelUnitCost,
      },
      eca: {
        fuelDifferenceMT: ecaFuelDifferenceMT,
        fuelExpenseSavingsDollars: ecaFuelExpenseSavingsDollars,
        formattedFuelDifference: formattedEcaFuelDifference,
        formattedFuelExpenseSavings: formatCost(
          ecaFuelExpenseSavingsDollars,
          "exceptZero"
        ),
        formattedFuelExpenseLosses: formatCost(
          ecaFuelExpenseSavingsDollars && -ecaFuelExpenseSavingsDollars,
          "exceptZero"
        ),
        formattedFuelUnitCost: absoluteSummary.fuel.eca.formattedFuelUnitCost,
      },
      total: {
        fuelDifferenceMT: totalFuelDifferenceMT,
        fuelExpenseSavingsDollars: totalFuelExpenseSavingsDollars,
        formattedFuelDifference: formattedTotalFuelDifference,
        formattedFuelExpenseSavings: formatCost(
          totalFuelExpenseSavingsDollars,
          "exceptZero"
        ),
        formattedFuelExpenseLosses: formatCost(
          totalFuelExpenseSavingsDollars && -totalFuelExpenseSavingsDollars,
          "exceptZero"
        ),
        formattedFuelUnitCost: absoluteSummary.fuel.total.formattedFuelUnitCost,
      },
    },
    emissions: {
      eua: {
        emissionsDifferenceCo2MT: euaEmissionsDifferenceCo2MT,
        emissionsExpenseSavingsDollars: euaEmissionsExpenseSavingsDollars,
        // the eua numbers are accumulated from polaris expense figures that could be missing
        // if the route is not in the eu or the voyage is not configured for eua
        formattedEmissionsDifference: formattedEuaEmissionsDifference,
        formattedEmissionsExpenseSavings: formatCost(
          euaEmissionsExpenseSavingsDollars,
          "exceptZero"
        ),
        formattedEmissionsExpenseLosses: formatCost(
          euaEmissionsExpenseSavingsDollars &&
            -euaEmissionsExpenseSavingsDollars,
          "exceptZero"
        ),
        formattedEmissionsUnitCost:
          absoluteSummary.emissions.eua.formattedEmissionsUnitCost,
      },
      // the non-eua emissions numbers are always derived locally based on accumulated total consumption
      emissionsDifferenceCo2MT,
      formattedEmissionsDifferenceCo2MT,
    },
    voyage: {
      voyageExpenseSavings,
      formattedVoyageExpenseSavings: formatCost(
        voyageExpenseSavings,
        "exceptZero"
      ),
      formattedVoyageExpenseLosses: formatCost(
        voyageExpenseSavings && -voyageExpenseSavings,
        "exceptZero"
      ),
    },
    scores: {
      ratios,
      differences,
      significantScores,
      routeAppealPhrase,
    },
    routeStartTimeDifference:
      !isNil(absoluteSummary.routeStartTime) &&
      !isNil(comparisonBasisAbsoluteSummary.routeStartTime)
        ? absoluteSummary.routeStartTime.diff(
            comparisonBasisAbsoluteSummary.routeStartTime
          )
        : undefined,
    routeStartWaypointDifferenceNm:
      !isNil(absoluteSummary.routeFirstWaypoint) &&
      !isNil(comparisonBasisAbsoluteSummary.routeFirstWaypoint)
        ? Math.abs(
            calculateNauticalMilesBetweenWaypoints(
              absoluteSummary.routeFirstWaypoint,
              comparisonBasisAbsoluteSummary.routeFirstWaypoint
            )
          )
        : undefined,
  };
};

export const shouldShowEcaDistanceAndTime = (
  routeSummaryData: RouteSummaryData
): boolean => {
  const {
    absoluteSummary: {
      distance: { ecaNauticalMiles },
      time: { ecaDurationMs },
    },
  } = routeSummaryData;
  const shouldShowEcaNauticalMiles =
    !isNil(ecaNauticalMiles) && ecaNauticalMiles > 0;
  const shouldShowEcaDurationMs = !isNil(ecaDurationMs) && ecaDurationMs > 0;
  return shouldShowEcaDurationMs || shouldShowEcaNauticalMiles;
};
