import {
  Box,
  InputBaseComponentProps,
  InputLabel,
  InputProps,
  SxProps,
  TextField,
  TextFieldProps,
  Theme,
} from "@mui/material";
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { extendedPalette, typographyStyles } from "styles/theme";

export type WayfinderTextFieldProps = Omit<TextFieldProps, "variant"> & {
  variant: "primary" | "secondary" | "tertiary";
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;

  /**
   * Adds delay to calling onChange. Uses local state to keep track
   * of displayed text.
   */
  onChangeDebounce?: number;

  textAlign?: "left" | "center" | "right";

  containerStyles?: SxProps<Theme>;
};

const variantMap = new Map<
  "primary" | "secondary" | "tertiary",
  "standard" | "filled" | "outlined"
>([
  ["primary", "standard"],
  ["secondary", "outlined"],
  ["tertiary", "filled"],
]);

const INPUT_LABEL_PROPS = { shrink: true };

const styles = {
  startIconContainer: {
    paddingRight: 10,
  },
  formLabel: {
    ...typographyStyles.formLabel,
    color: extendedPalette.neutralAccess,
    marginBottom: 12,
  },
  noPaddingTop: {
    paddingTop: 0,
  },
  fontStyle: {
    ...typographyStyles.title,
  },
  filledInputField: {
    border: "none",
    paddingTop: "5px",
    paddingBottom: "5px",
    paddingLeft: "2px",
    paddingRight: "px",
    textAlign: "center",
    ...typographyStyles.buttonSmall,
    backgroundColor: extendedPalette.neutralLight,
    height: 24,

    "&:focus-visible": {
      outline: "none",
    },

    "&.disabled": {
      backgroundColor: "transparent",
      color: "black",
      textAlign: "right",
      marginRight: 2,
    },
  },
};

export const WayfinderTextField: React.FC<WayfinderTextFieldProps> = ({
  value,
  label,
  variant,
  startIcon,
  endIcon,
  InputProps,
  onChange,
  onChangeDebounce,
  textAlign = "center",
  containerStyles,
  ...props
}) => {
  const inputBaseProps: InputBaseComponentProps = useMemo(() => {
    if (textAlign === "left") {
      return { style: { textAlign: "left", paddingLeft: "12px" } };
    }
    if (textAlign === "right") {
      return { style: { textAlign: "right" } };
    }
    return { style: { textAlign: "center" } };
  }, [textAlign]);

  const inputProps: InputProps = useMemo(() => {
    const props: InputProps = {
      ...InputProps,
      sx: {
        "& input": {
          ...styles.fontStyle,
          ...(variant === "tertiary" ? styles.filledInputField : {}),
        },
        ...InputProps?.sx,
      },
    };

    // Only set the endAdornment if it's explicitly passed in, otherwise just let it propogate
    // through the InputProps. This allows our custom text field to work like a TextField for
    // other parts of the MUI component ecosystem, for example, the DateTimePicker, which passes
    // InputProps through to our custom field.
    if (endIcon) {
      props.endAdornment = endIcon;
    }

    return props;
  }, [InputProps, endIcon, variant]);

  const [localValue, setLocalValue] = useState(value);

  const timeoutRef = useRef<NodeJS.Timeout | undefined>();

  useEffect(() => {
    if (value !== localValue) {
      setLocalValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const delayOnChange = useCallback(
    (valueChange: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        if (onChange) {
          onChange(valueChange);
        }
      }, onChangeDebounce);

      setLocalValue(valueChange.target.value);
    },
    [onChange, onChangeDebounce]
  );

  return (
    <Box display="flex" alignItems="flex-end" sx={containerStyles}>
      {startIcon && <Box sx={styles.startIconContainer}>{startIcon}</Box>}
      {variant === "tertiary" && label && (
        <InputLabel sx={styles.formLabel} {...props.InputLabelProps}>
          {label}
        </InputLabel>
      )}
      <TextField
        variant={variantMap.get(variant)}
        label={variant === "tertiary" ? null : label}
        InputProps={inputProps}
        InputLabelProps={variant === "tertiary" ? undefined : INPUT_LABEL_PROPS}
        value={(onChangeDebounce ? localValue : value) || ""}
        onChange={onChangeDebounce ? delayOnChange : onChange}
        inputProps={inputBaseProps}
        {...props}
      />
    </Box>
  );
};
